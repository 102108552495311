import React from 'react'
import { Link } from 'react-router-dom'

const ServiceSidebar = () => {
    return (
        <div className="col-xxl-4 col-xl-4 col-lg-4">
            <div className="product__details-sidebar ml-30 sticky-sidebar">
                <div className="product__proprietor white-bg mb-30">
                    <div className="product__proprietor-head mb-25">
                        <div className="product__proprietor-text">
                            <p><b>Best Tech, Best Future</b> <br />- Odidor </p>
                        </div>
                    </div>
                    <div className="product__proprietor-body fix mb-30">
                        <ul className="mb-10 fix">
                            <li>
                                <Link to="/services/app-development">
                                    <span>App Dev:</span>
                                    <h6>Native dev</h6>
                                    <h6>React Native</h6>
                                    <h6>Flutter</h6>
                                </Link>
                            </li>
                            <li>
                                <Link to="/services/website-development">
                                    <span>Web Dev:</span>
                                    <h6>Web Apps</h6>
                                    <h6>CMS </h6>
                                    <h6>Open Source</h6>
                                </Link>
                            </li>
                            <li>
                                <Link to="/services/software-development">
                                    <span>Software Dev:</span>
                                    <h6>POS System</h6>
                                    <h6>HRMS</h6>
                                </Link>
                            </li>
                            <li>
                                <Link to="/services/digital-marketing">
                                    <span>Digital Marketing:</span>
                                    <h6>SEO Services</h6>
                                    <h6>PPC</h6>
                                    <h6>SMM</h6>
                                </Link>
                            </li>
                        </ul>
                        <Link to="/Contact" className="m-btn m-btn-2 w-100 mb-20"> <span></span> Get A Quote</Link>
                        <a href="mailto:ryan@odidor.co" target="_blank" rel="noreferrer" className="m-btn m-btn-border w-100"> <span></span> Email Us</a>
                    </div>
                    <div className="sidebar__widget mb-20">
                        <div className="sidebar__widget-title">
                            <h3>Categories</h3>
                        </div>
                        <div className="sidebar__widget-content">
                            <div className="sidebar__catagory">
                                <ul>
                                    <li><Link to="/services/app-development">App Design </Link></li>
                                    <li>&emsp;<Link to="/services/app-development/custom-app-development"> Custom App Dev </Link></li>
                                    <li>&emsp;<Link to="/services/app-development/mobile-app-development"> Mobile App Dev </Link></li>
                                    <li>&emsp;<Link to="/services/app-development/android-app-development"> Android App Dev </Link></li>
                                    <li>&emsp;<Link to="/services/app-development/ios-app-development"> iOS App Dev </Link></li>
                                    <li><Link to="/services/website-development"> Web Development </Link></li>
                                    <li>&emsp;<Link to="/services/website-development/ecommerce-web-development"> E-Commerce Dev </Link></li>
                                    <li>&emsp;<Link to="/services/website-development/web-app-development"> Web App Dev </Link></li>
                                    <li>&emsp;<Link to="/services/website-development/custom-cms-development"> Custom CMS Dev </Link></li>
                                    <li>&emsp;<Link to="/services/website-development/open-source-development"> Open Source Dev </Link></li>
                                    <li>&emsp;<Link to="/services/website-development/ui/ux-design"> UI/UX Design </Link></li>

                                    <li><Link to="/services/software-development">Software Development </Link></li>
                                    <li>&emsp;<Link to="/services/software-development/enterprise-software-development">Enterprise Software Dev</Link></li>
                                    <li>&emsp;<Link to="/services/software-development/custom-software-development">Custom Software Dev</Link></li>
                                    <li>&emsp;<Link to="/services/software-development/cloud-software-solutions">cloud Software Solutions</Link></li>
                                    <li>&emsp;<Link to="/services/software-development/embedded-software-development">Embedded Software Dev</Link></li>
                                    <li>&emsp;<Link to="/services/software-development/gaming-multimedia-software">Gaming & Multimedia Software</Link></li>
                                    <li>&emsp;<Link to="/services/software-development/cybersecurity-software-development">Cybersecurity Software Dev</Link></li>
                                    <li>&emsp;<Link to="/services/software-development/educational-and-e-learning-software">Educational & E-Learning Software</Link></li>
                                    
                                    <li><Link to="/services/digital-marketing">Digital Marketing </Link></li>
                                    <li>&emsp;<Link to="/services/digital-marketing/seo-services">SEO Services</Link></li>
                                    <li>&emsp;<Link to="/services/digital-marketing/social-media-marketing">Social Media Marketing</Link></li>
                                    <li>&emsp;<Link to="/services/digital-marketing/ppc-advertising">PPC Advertising</Link></li>
                                    <li>&emsp;<Link to="/services/digital-marketing/content-marketing">Content Marketing</Link></li>
                                    <li>&emsp;<Link to="/services/digital-marketing/performance-optimization">Performance Optimization</Link></li>
                                    <li><Link to="/services/enterprise-solutions">Enterprise Solutions</Link></li>
                                    <li>&emsp;<Link to="/services/enterprise-solutions/erp-development">ERP Development</Link></li>
                                    <li>&emsp;<Link to="/services/enterprise-solutions/crm-development">CRM Solutions</Link></li>
                                    <li>&emsp;<Link to="/services/enterprise-solutions/microsoft-integration">Microsoft Integration</Link></li>
                                    <li>&emsp;<Link to="/services/enterprise-solutions/iot-solutions">Iot Solutions</Link></li>
                                    <li>&emsp;<Link to="/services/enterprise-solutions/big-data-analytics">Big Data Analytics</Link></li>
                                    <li>&emsp;<Link to="/services/enterprise-solutions/cloud-solutions">Cloud Solutions</Link></li>  
                                    <li>&emsp;<Link to="/services/enterprise-solutions/business-intelligence">Business Intelligence</Link></li>  
                                    <li>&emsp;<Link to="/services/enterprise-solutions/custom-software-development">Custom Software Dev</Link></li>  
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSidebar
