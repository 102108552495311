import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Jcrepairs = () => {
  const services = [
    { name: "Web Development", path: "/services/website-development" },
    {
      name: "UI/UX Design",
      path: "/services/website-development/web-app-development",
    },
    {
      name: "SEO Optimization",
      path: "/services/digital-marketing/seo-services",
    },
    {
      name: "Performance Optimization",
      path: "/services/digital-marketing/content-marketing",
    },
    {
      name: "Mobile-Responsive Development",
      path: "/services/app-development/mobile-app-development",
    },
    {
      name: "Custom CMS Development",
      path: "/services/website-development/custom-cms-development",
    },
    {
      name: "E-commerce Solutions",
      path: "/services/website-development/ecommerce-web-development",
    },
    {
      name: "API Integrations",
      path: "/services/software-development/custom-software-development",
    },
    {
      name: "Technical Support & Maintenance",
      path: "/services/software-development",
    },
  ];
  return (
    <>
      <Header />
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[50vh] sm:h-[70vh] flex items-center justify-center px-4"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738477825/banner-1_rpkpjb.jpg')",
        }}
      >
        {/* Gradient Overlay */}
        <div className="text-center">
          <h1 className="text-3xl sm:text-5xl font-extrabold mb-4 leading-tight text-white">
            JC Truck & Trailer Repair Ltd
          </h1>
          <h2 className="text-sm sm:text-lg font-medium mb-6 text-white max-w-[90%] mx-auto">
            At JC Truck & Trailer Repair Ltd, we pride ourselves on delivering
            quality workmanship, reliable performance, and exceptional customer
            service.
          </h2>
        </div>

        {/* Expertise Section */}
        <div className="absolute bottom-6 sm:bottom-10 left-0 right-0 text-center text-white px-4">
          <p className="text-xs sm:text-base font-medium">
            Expertise:{" "}
            <Link
              to="/services/website-development"
              className="font-semibold text-indigo-600 underline hover:text-indigo-400 transition"
            >
              <span>Web Development</span>
            </Link>
            {" & "}
            <Link
              to="/services/digital-marketing/seo-services"
              className="font-semibold text-indigo-600 underline hover:text-indigo-400 transition"
            >
              <span>SEO Optimization</span>
            </Link>
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-12 p-8 bg-indigo-50 lg:px-28">
        {/* Services Provided Section */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h3 className="text-3xl font-bold text-indigo-800 mb-6">
            Our Contribution to JC Repairs
          </h3>
          <p className="text-indigo-600 text-lg mb-4">
            Odidor played a crucial role in building a high-performance,
            SEO-optimized, and user-friendly website for{" "}
            <span className="font-semibold text-indigo-800">
              <a
                href="https://jcrepairs.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-orange-700 transition"
              >
                JC Repairs
              </a>
            </span>
            . Our focus was on seamless navigation, mobile responsiveness, and a
            strong digital presence.
          </p>

          <h4 className="text-2xl font-semibold text-indigo-800 mb-4">
            Services Provided
          </h4>

          <div className="flex flex-wrap gap-4">
            {services.map((service, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.1, backgroundColor: "#0274BD" }}
                whileTap={{ scale: 0.95 }}
                className="px-5 py-3 bg-indigo-500 border border-indigo-300 rounded-full text-sm font-medium cursor-pointer shadow-md hover:shadow-lg transition-all"
                style={{ transition: "color 0.3s, background-color 0.3s" }}
              >
                <Link to={service.path} className="text-white">
                  {service.name}
                </Link>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Description Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h2 className="text-3xl font-extrabold text-indigo-800 leading-tight">
            Transforming JC Repairs' Online Presence with Odidor's Expertise
          </h2>
          <p className="mt-6 text-indigo-600 text-lg">
            <span className="font-extrabold text-indigo-500">
              {" "}
              <Link to="/">Odidor</Link>
            </span>{" "}
            provided a cutting-edge website solution for{" "}
            <span className="font-extrabold text-orange-500">
              <a
                href="https://jcrepairs.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-orange-700 transition"
              >
                JC Repairs
              </a>
            </span>
            , ensuring a seamless user experience, intuitive design, and
            enhanced digital reach. Our goal was to craft a robust and scalable
            website to meet their business needs.
          </p>

          {/* Details Table */}
          <div className="mt-8 bg-white rounded-lg shadow-lg p-6 border-l-4 border-indigo-500">
            <table className="w-full text-left text-indigo-700">
              <tbody>
                {[
                  {
                    label: "Frontend Technology",
                    value: "React Js / Bootstrap Js",
                  },
                  {
                    label: "SEO Optimization",
                    value: "Advanced Keyword Strategy",
                  },
                  { label: "Performance Score", value: "95+ (Lighthouse)" },
                  { label: "Timescale", value: "12 Weeks" },
                  { label: "Launch Date", value: "January 2022" },
                ].map((item, index) => (
                  <tr key={index} className="border-b last:border-none">
                    <td className="py-3 font-medium">{item.label}</td>
                    <td className="py-3 text-indigo-500">{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-6 text-center">
              <a
                href="https://jcrepairs.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-indigo-500 text-white px-6 py-3 rounded-lg hover:bg-indigo-600 transition duration-300">
                  Visit JC Repairs Website
                </button>
              </a>
            </div>
          </div>
        </motion.div>
      </div>
      {/* image  */}
      <div
        className="relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-indigo-600 to-indigo-900 text-white"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738477826/slider2_fozayv.jpg')",
        }}
      ></div>
      <section className="container max-w-full py-16 px-8 lg:px-24 bg-gradient-to-b from-indigo-50 to-indigo-100">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
        >
          <motion.img
            src="https://res.cloudinary.com/doefzwlxm/image/upload/v1738175348/sky_line_1_iqqv2l.jpg"
            alt="JC Repairs"
            className="rounded-xl shadow-lg transform hover:scale-105 transition duration-500 ease-in-out"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1 }}
          />

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 text-indigo-800 leading-tight">
              Reliable & Expert Repair Services for Your Needs
            </h2>
            <p className="text-lg text-indigo-600 mb-6">
              At{" "}
              <span className="font-semibold text-indigo-800">
                {" "}
                <a
                  href="https://jcrepairs.ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-extrabold hover:text-orange-700 transition"
                >
                  JC Repairs
                </a>
              </span>
              , we specialize in providing top-notch repair services with a
              commitment to excellence. Whether it’s home appliances,
              electronics, or industrial equipment, our team ensures quick,
              reliable, and high-quality solutions.
            </p>
            <ul className="list-disc list-inside text-indigo-700 mb-6">
              <li>Comprehensive repair solutions for all major brands</li>
              <li>Certified and experienced technicians</li>
              <li>Fast turnaround times to get you back on track</li>
              <li>Affordable pricing with no hidden charges</li>
            </ul>
            <a
              href="https://jc-repairs.com"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-6 rounded-lg shadow-md transition duration-300"
            >
              Learn More
            </a>
          </motion.div>
        </motion.div>
      </section>

      {/* image  */}
      <div
        className="relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-indigo-600 to-indigo-900 text-white"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738477826/truck-2_qiuor2.jpg')",
        }}
      ></div>
      {/* JC Repairs - Conclusion Section */}
      <section className="bg-gradient-to-r from-indigo-100 to-indigo-200 py-16 px-6 md:px-16">
        <h3 className="text-3xl md:text-4xl font-bold text-center text-indigo-800 mb-8">
          Conclusion: Transforming Repair Services with{" "}
          <span className="font-extrabold text-indigo-900">JC Repairs</span>
        </h3>
        <div className="max-w-5xl mx-auto text-lg text-indigo-700 space-y-6">
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            At{" "}
            <span className="font-extrabold text-indigo-900">
              {" "}
              <Link to="/">Odidor</Link>
            </span>
            , we take pride in developing innovative digital solutions, and our
            collaboration with{" "}
            <span className="font-extrabold text-indigo-900">
              <a
                href="https://jcrepairs.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-orange-700 transition"
              >
                JC Repairs
              </a>
            </span>{" "}
            has resulted in a platform that enhances their repair services,
            making them more accessible and efficient for customers.
          </motion.p>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            This project was designed to simplify the process of booking repair
            services, ensuring a seamless experience for customers in need of
            expert solutions.
          </motion.p>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <span className="font-extrabold text-indigo-900">
              <a
                href="https://jcrepairs.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-orange-700 transition"
              >
                JC Repairs
              </a>
            </span>{" "}
            now has a powerful digital presence, reflecting their dedication to
            quality, reliability, and customer satisfaction. Our development
            efforts have helped them streamline operations and enhance customer
            engagement.
          </motion.p>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            Together, we continue to push the boundaries of technology and
            service, ensuring that every repair solution is just a click away
            for customers.
          </motion.p>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="bg-gradient-to-br from-indigo-900 to-[#9013FE] text-white py-12 text-center">
        <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 leading-tight">
          Let’s Collaborate & Drive Success Together
        </h2>
        <p className="text-lg font-light mb-8">
          Ready to elevate your business with a cutting-edge e-commerce solution
          tailored to your needs?
        </p>
        <Link
          to="/contact"
          title="contact page"
          className="bg-gradient-to-r from-indigo-500 to-[#825bf0] hover:from-indigo-800 hover:to-indigo-700 px-8 py-4 rounded-lg text-xl font-semibold shadow-lg transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center justify-center"
        >
          <span className="mr-4">✉️</span> Contact Us Now
        </Link>
      </section>
      <Footer />
    </>
  );
};

export default Jcrepairs;
