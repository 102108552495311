import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Hitechblind = () => {
  const images = [
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1738476780/s_1_fu0tjz.jpg",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1738478373/2-1_jxdmjk.png",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1739214539/z_qmlhkc.jpg",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1739214538/r_jxcana.jpg",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1739214535/h_ygzr13.jpg",
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };
  return (
    <>
      <Header />
      {/* Hero Section */}
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center min-h-screen flex flex-col items-center justify-center text-white px-4 sm:px-6"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738478374/2-2_dy1sdh.png')",
        }}
      >
        {/* Dark Overlay */}
        <div className="absolute inset-0 bg-black/40"></div>

        {/* Content Container */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="relative text-center w-full max-w-3xl px-4 sm:px-6"
        >
          <div
            className="bg-white/10 backdrop-blur-lg p-8 sm:p-12 rounded-3xl border border-white/30 shadow-xl 
                  transition-all transform hover:scale-105 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]"
          >
            <h1
              className="text-4xl sm:text-6xl font-extrabold uppercase tracking-wider drop-shadow-md 
                   text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-500"
            >
              HiTech Blinds
            </h1>

            <p className="mt-4 sm:mt-6 text-base sm:text-lg font-medium tracking-wide text-gray-200 leading-relaxed">
              Elevate your space with{" "}
              <span className="font-semibold text-blue-400">
                modern, stylish, and smart
              </span>{" "}
              window blinds. Discover seamless design and cutting-edge
              innovation with{" "}
              <span className="font-semibold text-cyan-400">HiTech Blinds</span>
              .
            </p>
          </div>
        </motion.div>

        {/* Expertise Section (Position Adjusted) */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
          className="relative mt-10 sm:mt-16 text-center text-white w-full px-4"
        >
          <p className="text-xs sm:text-lg font-medium">
            <span className="uppercase tracking-wide text-gray-300">
              Expertise in
            </span>
          </p>

          <div className="flex flex-wrap justify-center gap-3 sm:gap-6 mt-3 w-full max-w-[90%] mx-auto">
            <Link
              to="/services/website-development"
              className="flex items-center gap-2 bg-white/10 px-3 sm:px-4 py-2 rounded-lg shadow-md hover:bg-white/20 transition w-full sm:w-auto justify-center"
            >
              <span className="text-yellow-400 text-lg sm:text-xl">
                <i className="fas fa-laptop-code"></i>
              </span>
              <span className="text-white font-semibold text-sm sm:text-base">
                Web Development
              </span>
            </Link>

            <Link
              to="/services/responsive-design"
              className="flex items-center gap-2 bg-white/10 px-3 sm:px-4 py-2 rounded-lg shadow-md hover:bg-white/20 transition w-full sm:w-auto justify-center"
            >
              <span className="text-yellow-400 text-lg sm:text-xl">
                <i className="fas fa-mobile-alt"></i>
              </span>
              <span className="text-white font-semibold text-sm sm:text-base">
                Responsive Design
              </span>
            </Link>

            <Link
              to="/services/digital-marketing/seo-services"
              className="flex items-center gap-2 bg-white/10 px-3 sm:px-4 py-2 rounded-lg shadow-md hover:bg-white/20 transition w-full sm:w-auto justify-center"
            >
              <span className="text-yellow-400 text-lg sm:text-xl">
                <i className="fas fa-chart-line"></i>
              </span>
              <span className="text-white font-semibold text-sm sm:text-base">
                SEO Optimization
              </span>
            </Link>
          </div>
        </motion.div>
      </div>

      {/* Project Details Section */}
      <div className="flex flex-col md:flex-row gap-12 p-8 bg-blue-50 lg:px-28">
        {/* Services Provided Section */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h3 className="text-3xl font-bold text-blue-600 mb-6">
            Our Contribution to HiTech Blinds
          </h3>
          <p className="text-gray-800 text-lg mb-4">
            Odidor, a leading software company, developed a high-performance,
            SEO-optimized, and visually stunning website for{" "}
            <span className="font-semibold text-blue-700">
              <a
                href="https://www.hitechblinds.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-blue-800 transition"
              >
                HiTech Blinds
              </a>
            </span>
            . Our goal was to create a seamless UI/UX experience, improve mobile
            responsiveness, and enhance overall site performance.
          </p>

          <h4 className="text-2xl font-semibold text-blue-700 mb-4">
            Services Provided
          </h4>

          <div className="flex flex-wrap gap-4">
            {[
              {
                name: "React.js Development",
                path: "/services/website-development",
              },
              {
                name: "Tailwind CSS UI/UX Design",
                path: "/services/app-development/custom-app-development",
              },
              {
                name: "Framer Motion Animations",
                path: "/services/app-development/custom-app-development",
              },
              {
                name: "SEO Optimization",
                path: "/services/digital-marketing/seo-services",
              },
              {
                name: "Mobile Responsive Design",
                path: "/services/website-development",
              },
              {
                name: "Performance Optimization",
                path: "/services/app-development/custom-app-development",
              },
            ].map((service, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.1, backgroundColor: "#1E40AF" }}
                whileTap={{ scale: 0.95 }}
                className="px-5 py-3 bg-blue-500 border border-blue-600 rounded-full text-sm font-medium cursor-pointer shadow-md hover:shadow-lg transition-all"
                style={{ transition: "color 0.3s, background-color 0.3s" }}
              >
                <Link to={service.path} className="text-white font-semibold">
                  {service.name}
                </Link>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Description Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h2 className="text-3xl font-extrabold text-blue-700 leading-tight">
            Elevating HiTech Blinds' Digital Presence
          </h2>
          <p className="mt-6 text-gray-800 text-lg">
            <span className="font-extrabold text-blue-500">
              <Link to="/">Odidor</Link>
            </span>{" "}
            crafted a sleek, modern, and high-performance website for{" "}
            <span className="font-extrabold text-blue-600">
              <a
                href="https://www.hitechblinds.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-blue-700 transition"
              >
                HiTech Blinds
              </a>
            </span>
            , ensuring seamless navigation, engaging animations, and an
            optimized frontend experience.
          </p>

          {/* Details Table */}
          <div className="mt-8 bg-white rounded-lg shadow-lg p-6 border-l-4 border-blue-500">
            <table className="w-full text-left text-gray-900">
              <tbody>
                {[
                  {
                    label: "Frontend Technology",
                    value: "React.js, Tailwind CSS",
                  },
                  { label: "Animations & UI Effects", value: "Framer Motion" },
                  {
                    label: "SEO Optimization",
                    value: "High-speed, SEO-friendly architecture",
                  },
                  { label: "Performance Score", value: "98+ (Lighthouse)" },
                  { label: "Timescale", value: "5 Weeks" },
                  { label: "Launch Date", value: "February 2024" },
                ].map((item, index) => (
                  <tr key={index} className="border-b last:border-none">
                    <td className="py-3 font-medium">{item.label}</td>
                    <td className="py-3 text-blue-600">{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-6 text-center">
              <a
                href="https://www.hitechblinds.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-blue-500 text-white font-bold px-6 py-3 rounded-lg hover:bg-blue-600 transition duration-300">
                  Visit HiTech Blinds Website
                </button>
              </a>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Gallery Section */}
      <div className="bg-gray-50 py-10 px-5">
        <div className="max-w-7xl mx-auto">
          <motion.div
            className="grid gap-4"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            {/* Top Row: 2 Images */}
            <div className="grid grid-cols-2 gap-4">
              {images.slice(0, 2).map((image, index) => (
                <motion.div
                  key={index}
                  className="overflow-hidden rounded-lg shadow-lg"
                  variants={itemVariants}
                >
                  <img
                    src={image}
                    alt={`Blind ${index + 1}`}
                    className="w-full h-96 object-cover"
                  />
                </motion.div>
              ))}
            </div>

            {/* Second Row: 3 Images */}
            <div className="grid grid-cols-3 gap-2 sm:gap-4">
              {images.slice(2).map((image, index) => (
                <motion.div
                  key={index + 2}
                  className="overflow-hidden rounded-lg shadow-lg"
                  variants={itemVariants}
                >
                  <img
                    src={image}
                    alt={`Blind ${index + 3}`}
                    className="w-full h-auto object-cover"
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
      {/* HiTech Blinds Feature Section */}
      <section className="w-full bg-gradient-to-b from-blue-50 to-blue-100 py-20">
        <div className="container mx-auto px-8 lg:px-24 text-center">
          <h2 className="text-5xl font-extrabold text-gray-800 leading-tight">
            Transforming Window Coverings with
            <span className="text-blue-600"> HiTech Blinds</span>
          </h2>
          <p className="text-lg text-gray-700 mt-4 max-w-3xl mx-auto">
            A next-generation window blinds provider redefining home and office
            decor with smart automation and modern aesthetics. Developed by
            Odidor, this project integrates seamless technology with a
            user-friendly experience.
          </p>
        </div>

        {/* Feature Section */}
        <div className="container mx-auto mt-16 grid grid-cols-1 lg:grid-cols-2 gap-12 items-center px-8 lg:px-24">
          <motion.img
            src="https://res.cloudinary.com/doefzwlxm/image/upload/v1739213145/_Blinds_pgv8uh.jpg"
            alt="HiTech Blinds"
            className="rounded-xl shadow-xl w-full transform hover:scale-105 transition duration-500 ease-in-out"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          />

          <div>
            <ul className="space-y-6 text-lg text-gray-700">
              {[
                "Smart Motorized Blinds for Home & Office",
                "Seamless Online Ordering & Customization",
                "Energy-Efficient & High-Quality Materials",
                "Advanced Automation with Mobile Control",
                "Tailored Solutions for Residential & Commercial Spaces",
                "Enhanced Mobile Experience for Easy Access",
                "Optimized for SEO & High Performance",
              ].map((item, index) => (
                <motion.li
                  key={index}
                  whileHover={{ scale: 1.05, x: 10 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center text-gray-800"
                >
                  <span className="mr-3 text-blue-600 text-2xl">✔️</span>
                  {item}
                </motion.li>
              ))}
            </ul>

            <div className="mt-8">
              <a
                href="https://www.hitechblinds.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-600 text-white py-3 px-8 rounded-lg shadow-md hover:bg-blue-700 transform hover:scale-105 transition duration-300"
              >
                Visit Site
              </a>
            </div>
          </div>
        </div>

        {/* Why Choose HiTech Blinds? Section */}
        <div className="container mx-auto mt-24 text-center px-8 lg:px-24">
          <h3 className="text-4xl font-bold text-gray-800">
            Why Choose HiTech Blinds?
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-10">
            {[
              "Smart & Automated Blinds",
              "Premium-Quality & Durable Materials",
              "Customizable Solutions for Every Space",
            ].map((item, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-xl p-6 hover:shadow-xl transition duration-300"
              >
                <h4 className="text-xl font-bold text-blue-600">{item}</h4>
                <p className="text-gray-700 mt-2">
                  Designed with precision to enhance modern living.
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Developed by Odidor */}
        <div className="mt-24 text-center bg-blue-600 text-white py-16 px-8 lg:px-24">
          <h3 className="text-4xl font-bold">Developed by Odidor</h3>
          <p className="text-lg max-w-3xl mx-auto mt-4">
            HiTech Blinds is a testament to Odidor’s expertise in software
            development, UX/UI design, and digital solutions. We ensure seamless
            performance, intuitive user experiences, and top-tier SEO
            optimization for enhanced online visibility.
          </p>
        </div>
      </section>

      {/* image  */}
      <div
        className="relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-blue-600 to-blue-900 text-white"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738476780/installation_vm6omu.jpg')",
        }}
      ></div>
      {/* Odidor Project - HiTech Blinds Conclusion Section */}
      <section className="py-16 px-6 md:px-16 bg-blue-50">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Left Section - Text Content */}
          <div>
            <h3 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Conclusion: Elevating Window Covering Solutions with{" "}
              <span className="text-blue-500">Odidor</span> &amp;{" "}
              <span className="text-blue-500">
                <a
                  href="https://www.hitechblinds.ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  HiTech Blinds
                </a>
              </span>
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              <span className="font-bold text-blue-500">
                <Link to="/" className="hover:underline">
                  Odidor
                </Link>
              </span>{" "}
              is a pioneering software company delivering digital
              transformation. Our collaboration with{" "}
              <span className="font-bold text-blue-500">
                <a
                  href="https://www.hitechblinds.ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  HiTech Blinds
                </a>
              </span>{" "}
              has redefined the online experience for premium window covering
              solutions.
            </p>
            <p className="text-lg text-gray-700 mb-4">
              This project showcases a seamless, high-performance digital
              platform tailored for effortless browsing, customized orders, and
              smooth customer interactions.
            </p>
            <p className="text-lg text-gray-700 mb-4">
              <span className="font-bold text-blue-500">
                <a
                  href="https://www.hitechblinds.ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  HiTech Blinds
                </a>
              </span>{" "}
              leverages innovative technologies to provide a refined user
              experience, reinforcing their commitment to quality and modern
              home solutions.
            </p>
            <p className="text-lg text-gray-700">
              Together, Odidor and HiTech Blinds are setting new benchmarks in
              the window covering industry, blending digital innovation with
              exceptional craftsmanship.
            </p>
          </div>

          {/* Right Section - Image */}
          <div className="relative">
            <img
              src="https://res.cloudinary.com/doefzwlxm/image/upload/v1738478372/2-4_ptgzjh.png"
              alt="HiTech Blinds Website Preview"
              className="w-full h-auto rounded-lg "
            />
          </div>
        </div>
      </section>
      {/* image  */}
      <div
        className="relative h-[60vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-blue-600 to-blue-900 text-white"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738478247/hero_bg_2_1_pbdocv.png')",
        }}
      ></div>
      {/* Call-to-Action Section */}
      <section className="bg-gradient-to-br from-indigo-900 to-[#9013FE] text-white py-12 text-center">
        <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 leading-tight">
          Let’s Collaborate & Drive Success Together
        </h2>
        <p className="text-lg font-light mb-8">
          Ready to elevate your business with a cutting-edge e-commerce solution
          tailored to your needs?
        </p>
        <Link
          title="contact page"
          to="/contact"
          className="bg-gradient-to-r from-indigo-500 to-[#825bf0] hover:from-indigo-800 hover:to-indigo-700 px-8 py-4 rounded-lg text-xl font-semibold shadow-lg transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center justify-center"
        >
          <span className="mr-4">✉️</span> Contact Us Now
        </Link>
      </section>
      <Footer />
    </>
  );
};

export default Hitechblind;
