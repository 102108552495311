import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Tda = () => {
  const images = [
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1739209798/hero_slider_bg_1_aeli8u.png",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1739209838/hero_slider_bg_2_ld6jfn.png",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1739211631/window-tiniting_csy2x8.jpg",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1739211631/interior-detailing_xs9zng.jpg",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1739211631/window-tiniting_csy2x8.jpg",
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <>
      <Header />
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center min-h-screen flex flex-col items-center justify-center text-white px-4 sm:px-6"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1739209838/hero_slider_bg_2_ld6jfn.png')",
        }}
      >
        {/* Dark Overlay */}
        <div className="absolute inset-0 bg-black/50"></div>

        {/* Content Container */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="relative text-center w-full max-w-3xl px-4 sm:px-6"
        >
          {/* Glassmorphism Effect */}
          <div className="bg-white/10 backdrop-blur-lg p-6 sm:p-10 rounded-2xl border border-white/20 shadow-lg">
            <h1 className="text-4xl sm:text-7xl font-extrabold uppercase tracking-wider drop-shadow-lg">
              The Detailing Artists
            </h1>
            <p className="mt-4 text-lg sm:text-2xl font-light tracking-wide">
              Elevate your vehicle with precision auto detailing and ceramic
              coating. Experience the ultimate shine and protection.
            </p>
          </div>
        </motion.div>

        {/* Expertise Section (Fixed Positioning) */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
          className="relative mt-8 sm:mt-12 text-center text-white w-full max-w-[90%] mx-auto"
        >
          <p className="text-xs sm:text-lg font-medium">
            <span className="uppercase tracking-wide text-gray-300">
              Expertise in
            </span>
          </p>

          <div className="flex flex-wrap justify-center gap-3 sm:gap-6 mt-3">
            <Link
              to="/services/website-development"
              className="flex items-center gap-2 bg-white/10 px-3 sm:px-4 py-2 rounded-lg shadow-md hover:bg-white/20 transition w-full sm:w-auto justify-center"
            >
              <span className="text-indigo-400 text-lg sm:text-xl">
                <i className="fas fa-laptop-code"></i>
              </span>
              <span className="text-white font-semibold text-sm sm:text-base">
                Web Development
              </span>
            </Link>

            <Link
              to="/services/website-development"
              className="flex items-center gap-2 bg-white/10 px-3 sm:px-4 py-2 rounded-lg shadow-md hover:bg-white/20 transition w-full sm:w-auto justify-center"
            >
              <span className="text-indigo-400 text-lg sm:text-xl">
                <i className="fas fa-mobile-alt"></i>
              </span>
              <span className="text-white font-semibold text-sm sm:text-base">
                Responsive Design
              </span>
            </Link>

            <Link
              to="/services/digital-marketing/seo-services"
              className="flex items-center gap-2 bg-white/10 px-3 sm:px-4 py-2 rounded-lg shadow-md hover:bg-white/20 transition w-full sm:w-auto justify-center"
            >
              <span className="text-indigo-400 text-lg sm:text-xl">
                <i className="fas fa-chart-line"></i>
              </span>
              <span className="text-white font-semibold text-sm sm:text-base">
                SEO Optimization
              </span>
            </Link>
          </div>
        </motion.div>
      </div>

      {/* Project Details Section */}
      <div className="flex flex-col md:flex-row gap-12 p-8 bg-yellow-50 lg:px-28">
        {/* Services Provided Section */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h3 className="text-3xl font-bold text-yellow-600 mb-6">
            Our Contribution to The Detailing Artists
          </h3>
          <p className="text-gray-800 text-lg mb-4">
            Odidor played a crucial role in building a high-performance,
            SEO-optimized, and visually stunning website for{" "}
            <span className="font-semibold text-yellow-700">
              <a
                href="https://thedetailingartists.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-yellow-800 transition"
              >
                The Detailing Artists (TDA)
              </a>
            </span>
            . Our focus was on delivering an immersive UI/UX experience, mobile
            responsiveness, and seamless performance.
          </p>

          <h4 className="text-2xl font-semibold text-yellow-700 mb-4">
            Services Provided
          </h4>

          <div className="flex flex-wrap gap-4">
            {[
              {
                name: "React.js Development",
                path: "/services/website-development",
              },
              {
                name: "Bootstrap UI/UX Design",
                path: "/services/app-development/custom-app-development",
              },
              {
                name: "Framer Motion Animations",
                path: "/services/app-development/custom-app-development",
              },
              {
                name: "SEO Optimization",
                path: "/services/digital-marketing/seo-services",
              },
              {
                name: "Responsive Web Design",
                path: "/services/website-development",
              },
              {
                name: "Performance Optimization",
                path: "/services/app-development/custom-app-development",
              },
            ].map((service, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.1, backgroundColor: "#000000" }}
                whileTap={{ scale: 0.95 }}
                className="px-5 py-3 bg-yellow-500 border border-yellow-600 rounded-full text-sm font-medium cursor-pointer shadow-md hover:shadow-lg transition-all"
                style={{ transition: "color 0.3s, background-color 0.3s" }}
              >
                <Link to={service.path} className="text-white font-semibold">
                  {service.name}
                </Link>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Description Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h2 className="text-3xl font-extrabold text-yellow-700 leading-tight">
            Enhancing The Detailing Artists' Digital Presence
          </h2>
          <p className="mt-6 text-gray-800 text-lg">
            <span className="font-extrabold text-yellow-500">
              <Link to="/">Odidor</Link>
            </span>{" "}
            crafted a sleek, modern, and high-performance website for{" "}
            <span className="font-extrabold text-yellow-600">
              <a
                href="https://thedetailingartists.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-yellow-700 transition"
              >
                The Detailing Artists
              </a>
            </span>
            , ensuring seamless navigation, engaging animations, and an
            optimized frontend experience.
          </p>

          {/* Details Table */}
          <div className="mt-8 bg-white rounded-lg shadow-lg p-6 border-l-4 border-yellow-500">
            <table className="w-full text-left text-gray-900">
              <tbody>
                {[
                  {
                    label: "Frontend Technology",
                    value: "React.js, Bootstrap",
                  },
                  { label: "Animations & UI Effects", value: "Framer Motion" },
                  {
                    label: "SEO Optimization",
                    value: "High-speed, SEO-friendly architecture",
                  },
                  { label: "Performance Score", value: "98+ (Lighthouse)" },
                  { label: "Timescale", value: "4 Weeks" },
                  { label: "Launch Date", value: "January 2024" },
                ].map((item, index) => (
                  <tr key={index} className="border-b last:border-none">
                    <td className="py-3 font-medium">{item.label}</td>
                    <td className="py-3 text-yellow-600">{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-6 text-center">
              <a
                href="https://thedetailingartists.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-yellow-500 text-black font-bold px-6 py-3 rounded-lg hover:bg-yellow-600 transition duration-300">
                  Visit TDA Website
                </button>
              </a>
            </div>
          </div>
        </motion.div>
      </div>
      {/* image  */}
      <div
        className="relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-indigo-600 to-indigo-900 text-white"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1739209798/hero_slider_bg_1_aeli8u.png')",
        }}
      ></div>
      {/*TDA Feature Section */}
      <section className="w-full bg-gradient-to-b from-yellow-50 to-yellow-100 py-20">
        <div className="container mx-auto px-8 lg:px-24 text-center">
          <h2 className="text-5xl font-extrabold text-gray-800 leading-tight">
            Elevating Auto Detailing with
            <span className="text-yellow-600"> The Detailing Artists</span>
          </h2>
          <p className="text-lg text-gray-700 mt-4 max-w-3xl mx-auto">
            A premium auto detailing brand revolutionizing the industry with
            cutting-edge techniques and customer-first solutions. Developed by
            Odidor, this project blends innovation with seamless functionality.
          </p>
        </div>

        {/* Feature Section */}
        <div className="container mx-auto mt-16 grid grid-cols-1 lg:grid-cols-2 gap-12 items-center px-8 lg:px-24">
          <motion.img
            src="https://res.cloudinary.com/doefzwlxm/image/upload/v1739205834/tda_qlwpio.jpg"
            alt="The Detailing Artists"
            className="rounded-xl shadow-xl w-full transform hover:scale-105 transition duration-500 ease-in-out"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          />

          <div>
            <ul className="space-y-6 text-lg text-gray-700">
              {[
                "Premium Auto Detailing Services with Expert Precision",
                "Seamless Online Booking & Customer Management",
                "High-Quality Paint Protection & Ceramic Coatings",
                "Eco-Friendly & Advanced Cleaning Techniques",
                "Tailored Solutions for Luxury & Exotic Vehicles",
                "Enhanced Mobile Experience for Easy Access",
                "Optimized for SEO & High Performance",
              ].map((item, index) => (
                <motion.li
                  key={index}
                  whileHover={{ scale: 1.05, x: 10 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center text-gray-800"
                >
                  <span className="mr-3 text-yellow-600 text-2xl">✔️</span>
                  {item}
                </motion.li>
              ))}
            </ul>

            <div className="mt-8">
              <a
                href="https://thedetailingartists.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-yellow-600 text-white py-3 px-8 rounded-lg shadow-md hover:bg-yellow-700 transform hover:scale-105 transition duration-300"
              >
                Visit Site
              </a>
            </div>
          </div>
        </div>

        {/* Why Choose TDA? Section */}
        <div className="container mx-auto mt-24 text-center px-8 lg:px-24">
          <h3 className="text-4xl font-bold text-gray-800">Why Choose TDA?</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-10">
            {[
              "Eco-Friendly Cleaning Products",
              "Experienced Auto Detailing Professionals",
              "100% Customer Satisfaction Guarantee",
            ].map((item, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-xl p-6 hover:shadow-xl transition duration-300"
              >
                <h4 className="text-xl font-bold text-yellow-600">{item}</h4>
                <p className="text-gray-700 mt-2">
                  High-quality service designed for excellence.
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Developed by Odidor */}
        <div className="mt-24 text-center bg-yellow-600 text-white py-16 px-8 lg:px-24">
          <h3 className="text-4xl font-bold">Developed by Odidor</h3>
          <p className="text-lg max-w-3xl mx-auto mt-4">
            Odidor, a leading software company, built The Detailing Artists
            platform to enhance user experience and streamline business
            operations. Our expertise in web development, UX/UI design, and SEO
            optimization ensures top-notch performance.
          </p>
        </div>
      </section>

      {/* Gallery Section */}
      <div className="bg-gray-50 py-10 px-5">
        <div className="max-w-7xl mx-auto">
          <motion.div
            className="grid gap-4"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            {/* Top Row: 2 Images */}
            <div className="grid grid-cols-2 gap-4">
              {images.slice(0, 2).map((image, index) => (
                <motion.div
                  key={index}
                  className="overflow-hidden rounded-lg shadow-lg"
                  variants={itemVariants}
                >
                  <img
                    src={image}
                    alt={`Blind ${index + 1}`}
                    className="w-full h-auto object-cover"
                  />
                </motion.div>
              ))}
            </div>

            {/* Second Row: 3 Images */}
            <div className="grid grid-cols-3 gap-2 sm:gap-4">
              {images.slice(2).map((image, index) => (
                <motion.div
                  key={index + 2}
                  className="overflow-hidden rounded-lg shadow-lg"
                  variants={itemVariants}
                >
                  <img
                    src={image}
                    alt={`Blind ${index + 3}`}
                    className="w-full h-auto object-cover"
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
      {/* Odidor Project - The Detailing Artists Conclusion Section */}
      <section className="py-16 px-6 md:px-16 bg-yellow-50">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Left Section - Text Content */}
          <div>
            <h3 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Conclusion: Transforming Auto Care with{" "}
              <span className="text-yellow-500">Odidor</span> &amp;{" "}
              <span className="text-yellow-500">
                <a
                  href="https://thedetailingartists.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  The Detailing Artists
                </a>
              </span>
            </h3>
            <p className="text-lg text-gray-700 mb-4">
              <span className="font-bold text-yellow-500">
                <Link to="/" className="hover:underline">
                  Odidor
                </Link>
              </span>{" "}
              is a leading software company providing digital solutions. Our
              collaboration with{" "}
              <span className="font-bold text-yellow-500">
                <a
                  href="https://thedetailingartists.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  The Detailing Artists
                </a>
              </span>{" "}
              has redefined the automotive detailing experience with an advanced
              digital platform.
            </p>
            <p className="text-lg text-gray-700 mb-4">
              This partnership has introduced a seamless, user-friendly booking
              system that allows car enthusiasts to schedule premium auto
              detailing services effortlessly.
            </p>
            <p className="text-lg text-gray-700 mb-4">
              <span className="font-bold text-yellow-500">
                <a
                  href="https://thedetailingartists.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  The Detailing Artists
                </a>
              </span>{" "}
              has integrated cutting-edge technology to deliver a premium
              experience, reflecting their commitment to precision and luxury.
            </p>
            <p className="text-lg text-gray-700">
              Together, Odidor and TDA are setting a new standard in auto
              detailing, blending software innovation with expert craftsmanship.
            </p>
          </div>

          {/* Right Section - Image */}
          <div className="relative">
            <img
              src="https://res.cloudinary.com/doefzwlxm/image/upload/v1739211631/choose-us_k2dfkg.png"
              alt="The Detailing Artists Car Detailing"
              className="w-full h-auto rounded-lg "
            />
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="bg-gradient-to-br from-indigo-900 to-[#9013FE] text-white py-12 text-center">
        <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 leading-tight">
          Let’s Collaborate & Drive Success Together
        </h2>
        <p className="text-lg font-light mb-8">
          Ready to elevate your business with a cutting-edge e-commerce solution
          tailored to your needs?
        </p>
        <Link
          title="contact page"
          to="/contact"
          className="bg-gradient-to-r from-indigo-500 to-[#825bf0] hover:from-indigo-800 hover:to-indigo-700 px-8 py-4 rounded-lg text-xl font-semibold shadow-lg transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center justify-center"
        >
          <span className="mr-4">✉️</span> Contact Us Now
        </Link>
      </section>
      <Footer />
    </>
  );
};

export default Tda;
