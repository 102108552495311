const Partners = () => {
    return (
        <div className="container pb-20">
            <div className="row text-center">
                <div className="col">
                    <h2 className="section__title">Our Partners</h2>
                    <p >At Odidor we are proud to collaborate with a diverse group of industry leaders and innovators who share our commitment to excellence, creativity, and technological advancement. Our partners are integral to our success, enabling us to deliver top-tier solutions to our clients across various domains. We are proud to partner with these amazing organizations.</p>
                </div>
            </div>
            <div className="row text-center mt-4">
                <div className="col-md-4 col-6 mb-4">
                    <a href='https://www.zoho.com/workplace/' target="_blank" title='Odidor partnering with Zoho Workplace & Zoho Products'>
                        <img src="https://res.cloudinary.com/doefzwlxm/image/upload/v1741739474/odidor/partners/ybmkuqckcmnld9qxah08.png"
                            alt="Odidor partnering with Zoho Workplace & Zoho Products"
                            className="partner-logo img-fluid" />
                    </a>
                </div>
                <div className="col-md-4 col-6 mb-4">
                    <a href='https://www.goodfirms.co/' target="_blank" title='Odidor partnering with Good Firms'>
                        <img src="./assets/img/partners/good-firms.png"
                            alt="Odidor partnering with Good Firms"
                            className="partner-logo img-fluid" />
                    </a>
                </div>
                <div className="col-md-4 col-6 mb-4">
                    <a href='https://www.designrush.com/agency/web-development-companies/ca/vancouver' target="_blank" title='Odidor partnering with Design Rush'>
                        <img src="https://res.cloudinary.com/doefzwlxm/image/upload/v1741740210/odidor/partners/woz4hiuhbmxslkp6srvw.png"
                            alt="Odidor partnering with Design Rush"
                            className="partner-logo img-fluid" />
                    </a>
                </div>

                {/* <div className="col-md-3 col-6 mb-4">
                <img src="partner-logo2.png" alt="Partner 2" className="partner-logo img-fluid" />
            </div> */}
            </div>
        </div>
    )
}

export default Partners