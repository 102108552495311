import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Updatemedicaldental = () => {
  const images = [
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1738833060/2147879111_jzb4yi.jpg",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1738832940/about-us_wdb4ci.jpg",
  ];
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };
  return (
    <>
      <Header />
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[70vh] sm:h-[80vh] flex items-center justify-center px-6 sm:px-12"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738832085/slider1_1_l00jhz.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-blue-900/50"></div>

        <div className="relative z-10 max-w-3xl text-center text-white">
          <h1 className="text-4xl sm:text-6xl font-extrabold mb-4 leading-tight drop-shadow-xl">
            Update Medical Dental Polyclinic
          </h1>
          <p className="text-lg sm:text-2xl font-medium mb-6 text-gray-200 drop-shadow-md">
            Providing comprehensive medical and dental care with advanced
            technology and expert healthcare professionals.
          </p>

          {/* Expertise Section */}
          <div className="mt-6 flex flex-wrap justify-center gap-4">
            <Link
              to="/services/website-development"
              className="bg-blue-500 hover:bg-blue-400 transition text-white font-semibold py-2 px-6 rounded-lg shadow-lg"
            >
              Web Development
            </Link>
            <Link
              to="/services/website-development/custom-cms-development"
              className="bg-blue-500 hover:bg-blue-400 transition text-white font-semibold py-2 px-6 rounded-lg shadow-lg"
            >
              Custom CMS
            </Link>
            <Link
              to="/services/software-development"
              className="bg-blue-500 hover:bg-blue-400 transition text-white font-semibold py-2 px-6 rounded-lg shadow-lg"
            >
              POS System
            </Link>
            <Link
              to="/services/digital-marketing/seo-services"
              className="bg-blue-500 hover:bg-blue-400 transition text-white font-semibold py-2 px-6 rounded-lg shadow-lg"
            >
              SEO Optimization
            </Link>
          </div>
        </div>
      </div>

      {/* Project Details Section */}
      <div className="flex flex-col md:flex-row gap-12 p-8 bg-blue-50 lg:px-28">
        {/* Services Provided Section */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h3 className="text-3xl font-bold text-blue-900 mb-6">
            Transforming Digital Presence for Update Medical Dental Polyclinic
          </h3>
          <p className="text-blue-700 text-lg mb-4">
            Odidor played a crucial role in developing a modern,
            high-performance, SEO-optimized, and patient-friendly website for{" "}
            <span className="font-semibold text-blue-900">
              <a
                href="https://www.updateclinic.ae/index.php"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-indigo-700 transition"
              >
                Update Medical Dental Polyclinic
              </a>
            </span>
            . Our focus was on creating a seamless user experience, mobile
            responsiveness, and boosting their online visibility.
          </p>

          <h4 className="text-2xl font-semibold text-blue-900 mb-4">
            Services Provided
          </h4>

          <div className="flex flex-wrap gap-4">
            {[
              {
                name: "Custom Website Development",
                path: "/services/website-development/custom-cms-development",
              },
              {
                name: "Appointment Booking System",
                path: "/services/software-development",
              },
              {
                name: "SEO & Digital Marketing",
                path: "/services/digital-marketing/seo-services",
              },
              {
                name: "Responsive Web Design",
                path: "/services/website-development",
              },
              {
                name: "Performance Optimization",
                path: "/services/website-development/ecommerce-web-development",
              },
              {
                name: "CMS Solutions with Laravel",
                path: "/services/website-development/custom-cms-development",
              },
              {
                name: "Database Management (MySQL)",
                path: "/services/software-development/custom-software-development",
              },
            ].map((service, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.1, backgroundColor: "#1E3A8A" }}
                whileTap={{ scale: 0.95 }}
                className="px-5 py-3 bg-blue-700 border border-blue-300 rounded-full text-sm font-medium cursor-pointer shadow-md hover:shadow-lg transition-all"
                style={{ transition: "color 0.3s, background-color 0.3s" }}
              >
                <Link to={service.path} className="text-white">
                  {service.name}
                </Link>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Description Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h2 className="text-3xl font-extrabold text-blue-900 leading-tight">
            Elevating Update Medical Dental Polyclinic's Digital Experience
          </h2>
          <p className="mt-6 text-blue-700 text-lg">
            <span className="font-extrabold text-blue-600">
              <Link to="/">Odidor</Link>
            </span>{" "}
            delivered a cutting-edge website for{" "}
            <span className="font-extrabold text-indigo-500">
              <a
                href="https://www.updateclinic.ae/index.php"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-indigo-700 transition"
              >
                Update Medical Dental Polyclinic
              </a>
            </span>
            , ensuring seamless patient interaction, intuitive navigation, and
            an optimized digital presence. Our mission was to develop a secure
            and scalable website tailored to their healthcare needs.
          </p>

          {/* Details Table */}
          <div className="mt-8 bg-white rounded-lg shadow-lg p-6 border-l-4 border-blue-600">
            <table className="w-full text-left text-blue-800">
              <tbody>
                {[
                  { label: "Frontend Technology", value: "Laravel" },
                  { label: "Database", value: "MySQL" },
                  {
                    label: "SEO Optimization",
                    value: "Advanced Healthcare Keyword Strategy",
                  },
                  { label: "Performance Score", value: "98+ (Lighthouse)" },
                  { label: "Timescale", value: "4-6 Weeks" },
                  { label: "Launch Date", value: "December 2023" },
                ].map((item, index) => (
                  <tr key={index} className="border-b last:border-none">
                    <td className="py-3 font-medium">{item.label}</td>
                    <td className="py-3 text-blue-600">{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-6 text-center">
              <a
                href="https://www.updateclinic.ae/index.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-blue-700 text-white px-6 py-3 rounded-lg hover:bg-blue-800 transition duration-300">
                  Visit Update Medical Dental Polyclinic
                </button>
              </a>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Gallery Section */}
      <div className="bg-gray-50 py-10 px-5">
        <div className="max-w-7xl mx-auto">
          <motion.div
            className="grid gap-4"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            {/* Top Row: 2 Images */}
            <div className="grid grid-cols-2 gap-4">
              {images.slice(0, 2).map((image, index) => (
                <motion.div
                  key={index}
                  className="overflow-hidden rounded-lg shadow-lg"
                  variants={itemVariants}
                >
                  <img
                    src={image}
                    alt={`Blind ${index + 1}`}
                    className="w-full h-96 object-cover"
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      <section className="container max-w-full py-16 px-8 lg:px-24 bg-gradient-to-b from-blue-50 to-blue-100">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
        >
          {/* Image Section */}
          <motion.img
            src="https://res.cloudinary.com/doefzwlxm/image/upload/v1738831208/Update_Medical_Dental_ej9uyl.jpg"
            alt="Update Medical Dental Polyclinic"
            className="rounded-xl shadow-lg transform hover:scale-105 transition duration-500 ease-in-out"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1 }}
          />

          {/* Content Section */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 text-blue-900 leading-tight">
              Elevating Digital Presence for Healthcare Excellence
            </h2>
            <p className="text-lg text-blue-700 mb-6">
              Odidor proudly developed a high-performance, secure, and
              user-friendly website for{" "}
              <span className="font-semibold text-blue-900">
                <a
                  href="https://www.updateclinic.ae/index.php"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-extrabold hover:text-blue-600 transition"
                >
                  Update Medical Dental Polyclinic
                </a>
              </span>
              . Our solution ensures seamless patient engagement, mobile
              responsiveness, and optimized digital visibility.
            </p>

            <ul className="list-disc list-inside text-blue-800 mb-6">
              <li>
                Custom-built with PHP Laravel & MySQL for secure performance
              </li>
              <li>SEO-optimized to enhance online visibility</li>
              <li>Responsive & mobile-friendly design for all devices</li>
              <li>
                Integrated appointment booking system for patient convenience
              </li>
            </ul>

            <a
              href="https://www.updateclinic.ae/index.php"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-blue-700 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-lg shadow-md transition duration-300"
            >
              Visit Website
            </a>
          </motion.div>
        </motion.div>
      </section>
      <div className="py-20 bg-gradient-to-b from-blue-50 via-white to-blue-100">
      {/* Project Overview */}
      <div className="text-center mb-16">
        <h3 className="text-4xl font-extrabold text-blue-900 mb-6">Project Overview</h3>
        <p className="text-lg lg:text-xl text-blue-700 max-w-3xl mx-auto leading-relaxed">
          The <strong>Medical Dental Polyclinic</strong> website was designed and developed by
          <Link to="/" className="font-semibold text-blue-900 underline ml-1">Odidor</Link>,
          a leading software company specializing in innovative digital solutions. This project showcases our
          expertise in building scalable and user-friendly healthcare platforms.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12 max-w-3xl mx-auto">
          <div className="p-6 bg-white shadow-lg rounded-xl">
            <h4 className="text-xl font-semibold text-blue-800 mb-2">Frontend Technology</h4>
            <p className="text-blue-700 text-lg">PHP Laravel</p>
          </div>
          <div className="p-6 bg-white shadow-lg rounded-xl">
            <h4 className="text-xl font-semibold text-blue-800 mb-2">Database</h4>
            <p className="text-blue-700 text-lg">MySQL</p>
          </div>
        </div>
      </div>

      {/* Why Choose Odidor Section */}
      <div className="text-center mb-16">
        <h3 className="text-4xl font-extrabold text-blue-900 mb-8">Why Choose Odidor?</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Healthcare Software */}
          <motion.div
            className="p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-105 transition duration-300"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Link to="/services/software-development/custom-software-development" className="block text-center">
              <motion.div
                className="text-blue-800 mb-6"
                initial={{ rotate: -15 }}
                animate={{ rotate: 0 }}
                transition={{ duration: 1.2 }}
              >
                <i className="fas fa-hospital text-5xl"></i>
              </motion.div>
              <h4 className="text-2xl font-bold text-blue-900 mb-4">Healthcare Software</h4>
              <p className="text-lg text-blue-700 mb-4">
                We develop customized healthcare management systems that streamline patient records,
                appointments, and billing for medical facilities.
              </p>
              <span className="text-blue-700 font-bold underline">Learn More</span>
            </Link>
          </motion.div>

          {/* Clinic Management System */}
          <motion.div
            className="p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-105 transition duration-300"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Link to="/services/software-development/custom-software-development" className="block text-center">
              <motion.div
                className="text-blue-800 mb-6"
                initial={{ rotate: -15 }}
                animate={{ rotate: 0 }}
                transition={{ duration: 1.2 }}
              >
                <i className="fas fa-stethoscope text-5xl"></i>
              </motion.div>
              <h4 className="text-2xl font-bold text-blue-900 mb-4">Clinic Management System</h4>
              <p className="text-lg text-blue-700 mb-4">
                Our comprehensive clinic management system helps doctors and clinics efficiently handle patient
                information, prescriptions, and reports.
              </p>
              <span className="text-blue-700 font-bold underline">Learn More</span>
            </Link>
          </motion.div>
        </div>
      </div>

      {/* This Project Developed by Odidor */}
      <div className="text-center">
        <h3 className="text-4xl font-extrabold text-blue-900 mb-6">This Project Developed by Odidor</h3>
        <p className="text-lg lg:text-xl text-blue-700 max-w-3xl mx-auto leading-relaxed">
          At <Link to="/" className="font-semibold text-blue-900 underline">Odidor</Link>, we specialize in
          building healthcare solutions that enhance patient management and clinic operations. Our expertise in
          PHP Laravel, MySQL, and custom software ensures secure and efficient platforms for healthcare
          providers.
        </p>
      </div>
    </div>
      {/* image  */}
      <div
        className="relative h-[80vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-indigo-600 to-indigo-900 text-white"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738832805/home_1_w1w1y2.jpg')",
        }}
      ></div>

      {/* Update Medical Dental Polyclinic - Conclusion Section */}
      <section className="bg-gradient-to-r from-blue-100 to-blue-200 py-16 px-6 md:px-16">
        <h3 className="text-3xl md:text-4xl font-bold text-center text-blue-900 mb-8">
          Empowering Healthcare with{" "}
          <span className="font-extrabold text-blue-800">
            Update Medical Dental Polyclinic
          </span>
        </h3>
        <div className="max-w-5xl mx-auto text-lg text-blue-800 space-y-6">
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            At{" "}
            <span className="font-extrabold text-blue-900">
              <Link to="/">Odidor</Link>
            </span>
            , we specialize in crafting high-performance digital solutions. Our
            partnership with{" "}
            <span className="font-extrabold text-blue-900">
              <a
                href="https://www.updateclinic.ae/index.php"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-blue-600 transition"
              >
                Update Medical Dental Polyclinic
              </a>
            </span>{" "}
            has resulted in a seamless, modern, and secure website designed to
            enhance patient experience and operational efficiency.
          </motion.p>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Built with{" "}
            <span className="font-semibold">PHP Laravel & MySQL</span>, this
            website offers a fast, intuitive, and user-friendly experience for
            both patients and medical professionals. From appointment booking to
            service inquiries, every aspect of the platform is designed to
            streamline healthcare services.
          </motion.p>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            With a mobile-responsive layout and advanced security integrations,{" "}
            <span className="font-extrabold text-blue-900">
              <a
                href="https://www.updateclinic.ae/index.php"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-blue-600 transition"
              >
                Update Medical Dental Polyclinic
              </a>
            </span>{" "}
            now has a strong digital footprint, reinforcing their commitment to
            top-quality healthcare services.
          </motion.p>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            At Odidor, we believe in transforming industries through technology,
            and our collaboration with Update Medical Dental Polyclinic is a
            testament to our ability to create impactful digital solutions for
            the healthcare sector.
          </motion.p>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="bg-gradient-to-br from-indigo-900 to-[#9013FE] text-white py-12 text-center">
        <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 leading-tight">
          Let’s Collaborate & Drive Success Together
        </h2>
        <p className="text-lg font-light mb-8">
          Ready to elevate your business with a cutting-edge e-commerce solution
          tailored to your needs?
        </p>
        <Link
          to="/contact"
          title="contact page"
          className="bg-gradient-to-r from-indigo-500 to-[#825bf0] hover:from-indigo-800 hover:to-indigo-700 px-8 py-4 rounded-lg text-xl font-semibold shadow-lg transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center justify-center"
        >
          <span className="mr-4">✉️</span> Contact Us Now
        </Link>
      </section>
      <Footer />
    </>
  );
};

export default Updatemedicaldental;
