import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const SpecialtyWindowBlinds = () => {
  return (
    <>
      <Header />
      <div className="bg-indigo-100">
        {/* Hero Section */}
        <div
          className="relative h-[90vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-[#9013FE] to-blue-900 text-white"
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738476781/00_pbvwgn.jpg')",
          }}
        >
          <motion.div
            className="text-center"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="text-5xl lg:text-7xl font-bold mb-6 leading-tight">
              Specialty Window Blinds
            </h1>
            <div className="flex justify-center space-x-6 mb-6">
              <span className="bg-blue-800 text-white px-6 py-3 rounded-full text-sm font-semibold tracking-wide shadow-lg cursor-pointer">
                <Link
                  to="/services/website-development/ecommerce-web-development"
                  title="Explore our E-commerce solutions"
                >
                  E-commerce
                </Link>
              </span>

              <span className="bg-green-700 text-white px-6 py-3 rounded-full text-sm font-semibold tracking-wide shadow-lg cursor-pointer">
                <Link
                  to="/services/website-development"
                  title="Learn more about our Web Development services"
                >
                  {" "}
                  Web Development
                </Link>
              </span>
            </div>
            <p className="text-xl lg:text-2xl font-light leading-relaxed max-w-3xl mx-auto">
              Discover premium, modern window blinds with seamless e-commerce
              experiences crafted with Odidor. Elevate your home improvement
              journey today.
            </p>
          </motion.div>
        </div>

        {/* Overview Section */}
        <section className="container mx-auto py-16 px-8 lg:px-24 bg-gradient-to-b from-indigo-50 to-indigo-100">
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
          >
            <motion.img
              src="https://res.cloudinary.com/dhhsupcai/image/upload/v1737527692/White_and_Black_Modern_Laptop_Unboxing_Youtube_Thumbnail_yt2bit.jpg"
              alt="Specialty Window Blinds"
              className="rounded-xl shadow-lg transform hover:scale-105 transition duration-500 ease-in-out"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1 }}
            />

            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
            >
              <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 text-indigo-600 leading-tight">
                Transform Your Spaces with Bespoke Window Solutions
              </h2>
              <p className="text-gray-700 text-lg mb-8 leading-relaxed">
                At{" "}
                <span className="font-bold text-[#9013FE]">
                  <a
                    href="https://specialtywindowblinds.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-bold text-[#9013FE] hover:underline"
                    title="Visit Specialty Window Blinds"
                  >
                    Specialty Window Blinds
                  </a>
                </span>
                , we craft exquisite window treatments that blend aesthetics
                with functionality. Our innovative solutions ensure every space
                reflects your style, offering the perfect balance of elegance
                and practicality.
              </p>

              <ul className="space-y-4 text-lg text-gray-700 mb-10">
                <motion.li
                  whileHover={{ scale: 1.05, x: 10 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <span className="mr-3 text-blue-500">✔️</span>
                  Cutting-edge UI/UX Design for Seamless User Experience
                </motion.li>
                <motion.li
                  whileHover={{ scale: 1.05, x: 10 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <span className="mr-3 text-blue-500">✔️</span>
                  Advanced E-commerce Features with Maximum Usability
                </motion.li>
                <motion.li
                  whileHover={{ scale: 1.05, x: 10 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <span className="mr-3 text-blue-500">✔️</span>
                  SEO Optimization to Boost Online Visibility
                </motion.li>
                <motion.li
                  whileHover={{ scale: 1.05, x: 10 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center"
                >
                  <span className="mr-3 text-blue-500">✔️</span>
                  Tailored Solutions for Every Home and Office
                </motion.li>
              </ul>

              <a
                href="https://specialtywindowblinds.com/"
                target="_blank"
                  rel="noopener noreferrer"
                className="bg-gradient-to-r from-[#9013FE] to-blue-500 text-white py-3 px-8 rounded-lg shadow-md hover:bg-[#825bf0] transform hover:scale-105 transition duration-300"
              >
                Discover More
              </a>
            </motion.div>
          </motion.div>

          <div className="mt-20 grid grid-cols-1 lg:grid-cols-2 gap-12">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
              className="bg-gradient-to-r from-indigo-50 to-indigo-100 p-8 rounded-xl shadow-lg"
            >
              <h3 className="text-3xl font-bold mb-4 text-indigo-700">
                Our Vision: Artistic Excellence for Every Window
              </h3>
              <p className="text-gray-700 text-lg">
                We envision a world where every window tells a unique story. By
                combining timeless design, modern innovation, and impeccable
                quality, we aim to redefine how spaces are transformed.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 1 }}
              className="bg-gradient-to-r from-teal-50 to-teal-100 p-8 rounded-xl shadow-lg"
            >
              <h3 className="text-3xl font-bold mb-4 text-teal-700">
                Why Choose Us? Expertise You Can Trust
              </h3>
              <p className="text-gray-700 text-lg">
                With unparalleled customer service and top-tier products, we
                deliver solutions that meet your exact needs. From stylish
                designs to energy-efficient innovations, we’ve got you covered.
              </p>
            </motion.div>
          </div>

          <div className="mt-20">
            <h3 className="text-3xl font-bold mb-6 text-[#825bf0]">
              Innovative Technology Stack & Key Highlights
            </h3>
            <ul className="space-y-6 text-lg text-gray-700">
              <motion.li
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-green-500">✔️</span>
                Powered by React for Dynamic and Responsive User Interfaces
              </motion.li>

              <motion.li
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-green-500">✔️</span>
                Built with Modern Web Technologies for Stability and Performance
              </motion.li>

              <motion.li
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-green-500">✔️</span>
                Fully Optimized for Speed, Scalability, and SEO
              </motion.li>
              <motion.li
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-green-500">✔️</span>
                Estimated Development Timeline: 10 Weeks
              </motion.li>
              <motion.li
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-green-500">✔️</span>
                Official Launch Date: January 2023
              </motion.li>
            </ul>
          </div>
        </section>

        {/* Features Section */}
        <section className="bg-white py-12 px-6 lg:px-20">
          <h2 className="text-center text-3xl font-bold mb-12 text-[#825bf0]">
            Key Features of the Platform
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                title: "Responsive Design",
                description:
                  "Seamlessly adapts across all devices, ensuring a consistent experience.",
                icon: "💻",
              },
              {
                title: "User-Friendly Interface",
                description:
                  "Intuitive navigation with simple steps for a hassle-free shopping experience.",
                icon: "🎨",
              },
              {
                title: "SEO Optimized",
                description:
                  "Enhanced visibility on search engines, driving more traffic and conversions.",
                icon: "🚀",
              },
              {
                title: "Secure Payment Gateway",
                description:
                  "Offers a safe and secure payment experience with various options.",
                icon: "🔐",
              },
            ].map((feature, index) => (
              <motion.div
                key={index}
                className="p-8 bg-gradient-to-br from-indigo-100 to-blue-100 rounded-lg shadow-lg hover:shadow-xl transition-transform duration-300 hover:scale-105 hover:bg-gradient-to-br hover:from-indigo-200 hover:to-blue-200"
                whileHover={{ scale: 1.05 }}
                transition={{ ease: "easeInOut", duration: 0.4 }}
              >
                <div className="flex items-center justify-center mb-4">
                  <motion.div
                    className="text-4xl text-indigo-700"
                    animate={{ y: [0, -10, 0] }}
                    transition={{ duration: 1, repeat: Infinity }}
                  >
                    {feature.icon}
                  </motion.div>
                </div>
                <h3 className="text-xl font-semibold mb-2 text-[#825bf0]">
                  {feature.title}
                </h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </section>

        <section className="bg-blue-50 py-16 px-6 lg:px-20">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 items-center">
            {/* Left Notification Mock */}
            <div className="relative w-72 h-96 mx-auto bg-gray-200 rounded-3xl shadow-xl overflow-hidden">
              <div className="absolute inset-0">
                <img
                  src="https://res.cloudinary.com/doefzwlxm/image/upload/v1738476780/s_1_fu0tjz.jpg"
                  alt="Blinds"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            {/* Middle Device Mock */}
            <div className="relative w-72 h-96 mx-auto bg-gray-200 rounded-3xl shadow-2xl overflow-hidden">
              <div className="absolute inset-0">
                <img
                  src="https://res.cloudinary.com/doefzwlxm/image/upload/v1738476780/srollershades_xyisiu.jpg"
                  alt="Roller Shades"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            {/* Right Display with Button */}
            <div className="flex flex-col items-center space-y-6">
              {/* Device Mock */}
              <div className="relative w-72 h-96 bg-gray-200 rounded-3xl shadow-xl overflow-hidden">
                <div className="absolute inset-0">
                  <img
                    src="https://res.cloudinary.com/doefzwlxm/image/upload/v1738476779/shoneycomb2_aj9ip8.jpg"
                    alt="Honeycomb Shades"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full bg-indigo-50 py-16">
          <div className="container mx-auto px-4 sm:px-6 lg:px-12">
            {/* Title Section */}
            <div className="text-center mb-16">
              <motion.h2
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="text-4xl md:text-5xl font-extrabold text-[#825bf0]"
              >
                Transforming Modern Living with Specialty Window Blinds
              </motion.h2>
              <motion.p
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="text-lg md:text-xl text-gray-600 mt-4 max-w-3xl mx-auto"
              >
                A glimpse into our innovative approach to redefining home décor
                through seamless digital solutions.
              </motion.p>
            </div>

            {/* Stats Section */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  value: "20K",
                  description: "Daily inquiries through our website",
                  color: "indigo",
                },
                {
                  value: "45%",
                  description: "Increase in customer engagement online",
                  color: "indigo",
                },
                {
                  value: "70%",
                  description:
                    "Customer retention aided by our online platform",
                  color: "indigo",
                  
                },
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.8, delay: index * 0.2 }}
                  className={`p-8 bg-gradient-to-r from-${stat.color}-50 to-${stat.color}-100 rounded-xl shadow-lg text-center`}
                >
                  <h3 className={`text-6xl font-bold text-${stat.color}-600`}>
                    {stat.value}
                  </h3>
                  <p className="text-lg text-gray-600 mt-4">
                    {stat.description}
                  </p>
                </motion.div>
              ))}
            </div>

            {/* Objectives Section */}
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="mt-20"
            >
              <h3 className="text-3xl md:text-4xl font-bold mb-8 text-[#825bf0] text-center">
                Objectives
              </h3>
              <ul className="max-w-4xl mx-auto space-y-8">
                {[
                  {
                    icon: "🛒",
                    title: "Streamlined Online Shopping",
                    description:
                      "Providing an easy-to-use platform for online shopping and offline availability, ensuring every customer can shop conveniently.",
                    color: "blue",
                  },
                  {
                    icon: "🔗",
                    title: "Efficient System Integration",
                    description:
                      "Simplify daily operations with seamless integration of POS systems, offering a streamlined and cost-effective solution.",
                    color: "green",
                  },
                ].map((objective, index) => (
                  <motion.li
                    key={index}
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                    className="flex items-start"
                  >
                    {/* Icon Section */}
                    <div
                      className={`flex items-center justify-center text-3xl text-${objective.color}-500 mr-6`}
                    >
                      {objective.icon}
                    </div>

                    {/* Content Section */}
                    <div>
                      <h4 className="text-xl font-semibold text-[#825bf0] mb-2">
                        {objective.title}
                      </h4>
                      <p className="text-lg text-gray-600">
                        {objective.description}
                      </p>
                    </div>
                  </motion.li>
                ))}
              </ul>
            </motion.div>

            {/* Closing Section */}
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.9 }}
              className="mt-20"
            >
              <h3 className="text-3xl md:text-4xl font-bold text-[#825bf0] mb-6 text-center">
                Our Digital Revolution for Specialty Window Blinds
              </h3>
              <p className="text-lg md:text-xl text-gray-600 leading-relaxed text-center max-w-4xl mx-auto">
                With a user-centric approach, Specialty Window Blinds has
                successfully elevated customer experiences through advanced
                digital platforms. Our solutions integrate seamless e-commerce,
                POS systems, and loyalty programs to create a harmonious blend
                of online and offline shopping experiences.
              </p>
            </motion.div>
          </div>
        </section>

        <div
          className="relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-[#9013FE] to-blue-900 text-white"
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738476780/energy_id2zdn.jpg')",
          }}
        ></div>

        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="my-20"
        >
          {/* CHALLENGES SECTION */}
          <section className="bg-indigo-100 py-16 px-6 md:px-16">
            <h3 className="text-3xl md:text-4xl font-bold text-center text-[#825bf0] mb-8">
              Challenges
            </h3>
            <ul className="max-w-5xl mx-auto space-y-8">
              {[
                {
                  title: "Lack of Personalised Window Solutions",
                  description:
                    "Customers previously lacked a streamlined way to find window treatments that suited their specific needs, such as style, material, and functionality.",
                  icon: "🪟",
                },
                {
                  title: "Inconsistent Delivery of Quality Products",
                  description:
                    "Relying on external suppliers and delivery services led to inconsistencies in the quality and timely arrival of products, affecting customer satisfaction.",
                  icon: "🚚",
                },
                {
                  title: "Complex Product Selection Process",
                  description:
                    "The absence of a clear and user-friendly platform made it challenging for customers to choose from a variety of window treatment options, leading to confusion.",
                  icon: "⚙️",
                },
              ].map((challenge, index) => (
                <li
                  key={index}
                  className="flex items-start bg-white shadow-md p-6 rounded-lg"
                >
                  <div className="text-3xl text-blue-500 mr-6">
                    {challenge.icon}
                  </div>
                  <div>
                    <h4 className="text-xl font-semibold text-[#825bf0]">
                      {challenge.title}
                    </h4>
                    <p className="text-gray-600 mt-2">
                      {challenge.description}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </section>

          <div
            className="relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-[#9013FE] to-blue-900 text-white"
            style={{
              backgroundImage:
                "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738476780/prd_ppew6p.jpg')",
            }}
          ></div>

          {/* SUPERIOR UX DESIGN SECTION */}
          <section className="bg-blue-50 py-16 px-6 md:px-16">
            <h3 className="text-3xl md:text-4xl font-bold text-center text-[#825bf0] mb-8">
              Superior UX Design
            </h3>
            <div className="max-w-5xl mx-auto text-lg text-gray-700 space-y-6">
              <p>
                Specialty Window Blinds has focused on delivering a top-tier
                customer experience by providing detailed information about each
                product, easy access to material choices, and personalized
                recommendations based on customer needs.
              </p>
              <p>
                The website is designed with an intuitive interface that allows
                users to easily browse, compare, and explore different window
                treatments available, ensuring that every interaction is as
                engaging as possible.
              </p>
            </div>
          </section>

          <div
            className="relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-[#9013FE] to-blue-900 text-white"
            style={{
              backgroundImage:
                "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738476780/lightcontrol_uidgnq.jpg')",
            }}
          ></div>

          {/* SEAMLESS PRODUCT SELECTION EXPERIENCE SECTION */}
          <section className="bg-indigo-50 py-16 px-6 md:px-16">
            <h3 className="text-3xl md:text-4xl font-bold text-center text-[#825bf0] mb-8">
              Seamless Product Selection Experience
            </h3>
            <div className="max-w-5xl mx-auto text-lg text-gray-700 space-y-6">
              <p>
                We have redefined the product selection process by providing
                filters based on material type, color, and design preferences.
                This way, every customer can easily find their ideal window
                treatment without feeling overwhelmed by the variety.
              </p>
              <p>
                Additionally, each product is accompanied by high-quality
                images, detailed descriptions, and relevant specifications to
                assist customers in making informed decisions.
              </p>
            </div>
          </section>

          <div
            className="relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-[#9013FE] to-blue-900 text-white"
            style={{
              backgroundImage:
                "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738476780/installation_vm6omu.jpg')",
            }}
          ></div>

          {/* CONCLUSION SECTION */}
          <section className="bg-blue-100 py-16 px-6 md:px-16">
            <h3 className="text-3xl md:text-4xl font-bold text-center text-[#825bf0] mb-8">
              Conclusion
            </h3>
            <div className="max-w-5xl mx-auto text-lg text-gray-700 space-y-6">
              <p>
                By focusing on enhancing user experience and simplifying the
                product selection process, Specialty Window Blinds has
                successfully created a more accessible and customer-friendly
                platform.
              </p>
              <p>
                The outcome has been a significant increase in customer
                satisfaction, with improved engagement and a streamlined process
                for choosing window treatments that meet specific needs.
              </p>
            </div>
          </section>
        </motion.div>

        {/* Call-to-Action Section */}
        <section className="bg-gradient-to-br from-indigo-900 to-[#9013FE] text-white py-12 text-center">
          <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 leading-tight">
            Let’s Collaborate & Drive Success Together
          </h2>
          <p className="text-lg font-light mb-8">
            Ready to elevate your business with a cutting-edge e-commerce
            solution tailored to your needs?
          </p>
          <Link
            title="contact page"
            to="/contact"
            className="bg-gradient-to-r from-indigo-500 to-[#825bf0] hover:from-indigo-800 hover:to-indigo-700 px-8 py-4 rounded-lg text-xl font-semibold shadow-lg transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center justify-center"
          >
            <span className="mr-4">✉️</span> Contact Us Now
          </Link>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default SpecialtyWindowBlinds;
