import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Masonry from "react-masonry-css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { industries, expertise, projects } from "../../constants/constants";
import Typewriter from "typewriter-effect";

const Projects = () => {
  const [industryFilter, setIndustryFilter] = useState("");
  const [expertiseFilter, setExpertiseFilter] = useState("");
  const navigate = useNavigate();

  const filteredProjects = projects.filter((project) => {
    const matchesIndustry = industryFilter
      ? project.industries.includes(industryFilter)
      : true;
    const matchesExpertise = expertiseFilter
      ? project.expertise.includes(expertiseFilter)
      : true;
    return matchesIndustry && matchesExpertise;
  });

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/500";
  };

  const breakpointColumns = {
    default: 3,
    1100: 2,
    700: 1,
  };

  return (
    <>
      <Header />
      <div className="container-fluid p-0">
        {/* Hero Section */}
        <motion.div
          className="position-relative"
          style={{ height: "100vh", overflow: "hidden" }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {/* Background Video */}
          <video
            autoPlay
            loop
            muted
            className="w-100 h-100 object-fit-cover"
            style={{ objectFit: "cover", filter: "brightness(0.7)" }}
          >
            <source
              src="https://res.cloudinary.com/dhhsupcai/video/upload/v1737382814/7021937_Connection_Cyberspace_3840x2160_dwyvud.mp4"
              type="video/mp4"
            />
          </video>

          {/* Content Overlay */}
          <motion.div
            className="position-absolute top-50 start-50 translate-middle text-center text-white"
            style={{
              backgroundColor: "rgba(20, 20, 20, 0.7)",
              padding: "3rem 4rem",
              borderRadius: "16px",
              boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.6)",
              maxWidth: "1000px",
            }}
            initial={{ scale: 0.85, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            {/* Headline */}
            <h1 className="fw-bold display-3 mb-4 text-purple-200">
              <Typewriter
                options={{
                  strings: [
                    "Our Projects",
                    "Innovation in Action",
                    "Success Stories",
                  ],
                  autoStart: true,
                  loop: true,
                  delay: 50,
                }}
              />
            </h1>

            {/* Description */}
            <p
              className="fs-5 mb-4 text-gray-300"
              style={{ fontSize: "1.25rem", letterSpacing: "1px" }}
            >
              Discover how we are driving innovation and shaping the future.
            </p>

            {/* Tagline Animation */}
            <div className="mt-3">
              <Typewriter
                options={{
                  strings: [
                    "Innovating Solutions",
                    "Creating Impact",
                    "Building the Future",
                  ],
                  autoStart: true,
                  loop: true,
                  delay: 60,
                }}
              />
            </div>
          </motion.div>
        </motion.div>

        <motion.div
          className="container py-3"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <h1
              className="form-label fw-bold fs-3"
              style={{ color: "#825BF0" }}
            >
              Projects
            </h1>

            {/* Filters & Clear Button - Mobile Friendly */}
            <div className="d-flex flex-column flex-md-row align-items-start gap-2 w-100 w-md-auto mt-3 mt-md-0">
              {/* Industry Filter */}
              <div className="d-flex flex-column w-100 w-md-auto">
                <label
                  className="form-label fw-bold mb-1"
                  style={{ color: "#825BF0" }}
                >
                  Industry
                </label>
                <select
                  className="form-select rounded-3 shadow-sm p-2"
                  onChange={(e) => setIndustryFilter(e.target.value)}
                  value={industryFilter}
                  style={{
                    backgroundColor: "white",
                    color: "#8A2BE2",
                    borderColor: "#8A2BE2",
                    height: "40px",
                  }}
                >
                  <option value="" style={{ color: "#825BF0" }}>
                    All Industries
                  </option>
                  {industries.map((industry, index) => (
                    <option
                      key={index}
                      value={industry}
                      style={{ color: "#8A2BE2" }}
                    >
                      {industry}
                    </option>
                  ))}
                </select>
              </div>

              {/* Mobile View: Expertise & Clear Filters in One Line */}
              <div className="d-flex d-md-none w-100 flex-column">
                <div className="d-flex justify-content-between align-items-center gap-2">
                  {/* Expertise Filter */}
                  <div className="w-50 me-2">
                    <label className="form-label fw-bold mb-1">Expertise</label>
                    <select
                      className="form-select rounded-3 shadow-sm p-2"
                      onChange={(e) => setExpertiseFilter(e.target.value)}
                      value={expertiseFilter}
                      style={{
                        color: "#8A2BE2",
                        borderColor: "#8A2BE2",
                        height: "40px",
                      }}
                    >
                      <option value="">All Expertise</option>
                      {expertise.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Clear Filters Button */}
                  <div className="w-50 d-flex flex-column align-items-end">
                    <label
                      className="form-label fw-bold mb-1"
                      style={{ visibility: "hidden" }}
                    >
                      Clear Filters
                    </label>
                    <button
                      className="btn fw-bold px-3 w-100"
                      onClick={() => {
                        setIndustryFilter("");
                        setExpertiseFilter("");
                      }}
                      style={{
                        backgroundColor: "white",
                        color: "#24446d",
                        borderColor: "#24446d",
                        height: "40px",
                        borderRight: "3px solid #24446d2",
                      }}
                    >
                      Clear Filters
                    </button>
                  </div>
                </div>
              </div>

              {/* Desktop View: Expertise & Clear Filters Separate */}
              <div className="d-none d-md-flex flex-column w-100 w-md-auto">
                <label
                  className="form-label fw-bold mb-1"
                  style={{ color: "#825BF0" }}
                >
                  Expertise
                </label>
                <select
                  className="form-select rounded-3 shadow-sm p-2"
                  onChange={(e) => setExpertiseFilter(e.target.value)}
                  value={expertiseFilter}
                  style={{
                    backgroundColor: "white",
                    color: "#8A2BE2",
                    borderColor: "#8A2BE2",
                    height: "40px",
                  }}
                >
                  <option value="" style={{ color: "#825BF0" }}>
                    All Expertise
                  </option>
                  {expertise.map((item, index) => (
                    <option
                      key={index}
                      value={item}
                      style={{ color: "#8A2BE2" }}
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              <div className="d-none d-md-flex flex-column w-100 w-md-auto">
                <label
                  className="form-label fw-bold mb-1"
                  style={{ color: "#825BF0", visibility: "hidden" }}
                >
                  Clear Filters
                </label>
                <button
                  className="btn fw-bold px-3 w-100 w-md-auto"
                  onClick={() => {
                    setIndustryFilter("");
                    setExpertiseFilter("");
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "#24446d",
                    borderColor: "#24446d",
                    height: "40px",
                    borderRight: "3px solid #24446d",
                  }}
                >
                  Clear Filters
                </button>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Projects Section */}
        <motion.div
          className="container "
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: { staggerChildren: 0.2 },
            },
          }}
        >
          <Masonry
            breakpointCols={breakpointColumns}
            className="flex gap-4"
            columnClassName="masonry-column"
          >
            {[...filteredProjects].reverse().map((project) => (
              <motion.div
                key={project.id}
                onClick={() => navigate(project.route)}
                className="shadow-2xl border-0 rounded-xl overflow-hidden mb-4 group transition-transform duration-300 hover:scale-105"
                variants={{
                  hidden: { scale: 0.8, opacity: 0 },
                  visible: { scale: 1, opacity: 1 },
                }}
                transition={{ duration: 0.5 }}
                style={{ cursor: "pointer" }}
              >
                {/* Image Section with Expertise on the Side */}
                <div className="relative w-full h-full overflow-hidden">
                  <img
                    src={project.image}
                    alt={project.projectName}
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                    onError={handleImageError}
                  />
                  {/* Expertise Tag Positioned on the Side */}
                  <div className="absolute top-0 left-0 h-full bg-purple-700 bg-opacity-80 text-white p-2 flex flex-col justify-center items-center transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300">
                    <span className="text-sm font-semibold leading-tight">
                      {project.expertise.join(", ")}
                    </span>
                  </div>
                </div>

                {/* Content Section */}
                <div className="bg-gradient-to-br from-purple-50 to-white p-6 space-y-2">
                  <h3 className="font-extrabold text-xl text-purple-900 group-hover:text-purple-700">
                    {project.projectName}
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    {project.shortDescription}
                  </p>
                </div>
              </motion.div>
            ))}
          </Masonry>
        </motion.div>
      </div>
      <Footer />
    </>
  );
};

export default Projects;
