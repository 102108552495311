import { motion } from "framer-motion";
import React from "react";
import { AuroraBackground } from "../ui/aurora-background";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

export function AuroraBackgroundDemo() {
  return (
    <AuroraBackground>
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.8, ease: "easeInOut" }}
        className="relative flex flex-col gap-4 sm:gap-6 items-center justify-center text-center px-4 sm:px-6"
      >
        <motion.h6
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.8, ease: "easeInOut" }}
          className="text-lg sm:text-3xl font-bold text-white drop-shadow-[0_0_8px_rgba(0,255,255,0.6)] animate-pulse"
        >
          Welcome to{" "}
          <span className="text-[#825bf0] drop-shadow-[0_0_10px_#825bf0]">
            Odidor
          </span>
        </motion.h6>

        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.8, ease: "easeInOut" }}
          className="text-4xl sm:text-7xl font-extrabold text-white drop-shadow-[0_0_15px_rgba(0,132,255,0.9)] tracking-wide animate-pulse"
        >
          BEST <span className="text-blue-400">TECH</span> <br />
          <span className="text-indigo-400">BEST</span> FUTURE
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7, duration: 0.8, ease: "easeInOut" }}
          className="text-sm sm:text-2xl text-neutral-300 max-w-xs sm:max-w-2xl leading-relaxed tracking-wider bg-gradient-to-r from-blue-300 via-indigo-300 to-blue-400 bg-clip-text text-transparent"
        >
          We combine cutting edge technology with immaculate design to create
          intuitive, user-friendly mobile app solutions that will surpass all
          expectations.
          <br />
          <span className="text-[#9013FE] font-semibold mt-2 sm:mt-4 block">
            <TypeAnimation
              sequence={[
                "AI-Powered Innovations",
                1500,
                "Next-Gen Mobile Apps",
                1500,
                "Hyper-Responsive UI/UX",
                1500,
                "Seamless User Experiences",
                1500,
                "The Future of Technology",
                1500,
              ]}
              speed={50}
              repeat={Infinity}
              cursor={true}
            />
          </span>
        </motion.p>

        <div className="w-full flex justify-center">
          {/* 🌟 Desktop View (Visible on screens ≥ 640px) */}
          <div className="hidden sm:flex flex-wrap justify-center gap-4 sm:gap-6 w-full">
            {[
              { name: "App Development", path: "/services/app-development" },
              {
                name: "Web Development",
                path: "/services/website-development",
              },
              {
                name: "Software Development",
                path: "/services/software-development",
              },
              {
                name: "Digital Marketing",
                path: "/services/digital-marketing",
              },
              {
                name: "Enterprise Solutions",
                path: "/services/enterprise-solutions",
              },
            ].map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className="inline-flex h-12 animate-shimmer items-center justify-center rounded-md border border-slate-800 
        bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] 
        px-6 font-medium text-slate-400 transition-all duration-300 
        focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50
        hover:border-slate-600 hover:text-white hover:shadow-[0_0_15px_rgba(30,38,49,0.8)]"
              >
                {item.name}
              </Link>
            ))}
          </div>

          {/* 📱 Mobile View (Visible only on screens < 640px) */}
          <div className="sm:hidden flex justify-center w-full">
            <Link
              to="/services"
              className="inline-flex h-12 animate-shimmer items-center justify-center rounded-md border border-slate-800 
      bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] bg-[length:200%_100%] 
      px-6 font-medium text-slate-400 transition-all duration-300 
      focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50
      hover:border-slate-600 hover:text-white hover:shadow-[0_0_15px_rgba(30,38,49,0.8)]"
            >
              Explore Services →
            </Link>
          </div>
        </div>
      </motion.div>
    </AuroraBackground>
  );
}
