import React from 'react'
import { Link } from 'react-router-dom'

const BlogSingle = () => {
    return (
        <article class="postbox__item format-image fix mb-50 wow fadeInUp" data-wow-delay=".2s">
            <div class="postbox__thumb">
                <Link to="blog-details.html" class="w-img">
                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/DSucvgf2X4Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </Link>
            </div>
            <div class="postbox__content">
                <div class="postbox__meta d-flex mb-10">
                    <div class="postbox__tag mr-20">
                        <Link to="https://youtu.be/DSucvgf2X4Q" >Game Development </Link>
                    </div>
                    <div class="postbox__date">
                        <span><i class="fal fa-clock"></i> October 15, 2021</span>
                    </div>
                </div>
                <h1 class="postbox__title mb-15"><Link to="blog-details.html">Gradle build failed. See the Console for details, Unity Build System, Unity Execute Error solved</Link></h1>
                <div class="postbox__text mb-20">
                    <p> If you are seeing gradle build failed when building your game in unity showing following message.<br />
                        There is a simple way to resolve this error if you are using older version than unity 2019
                        Just go to build setting setting and change Build system from gradle to internal. it will resolve your error.<br />
                        But it is ok for just testing your game if you are building for google play store then should build your game with gradle build.
                        <br />
                        To build with gradle you should follow some step
                        <br />
                        1. go to build setting {"->"} player setting {"->"} publishing setting
                        <br />
                        In publishing setting you should setup your keystore here you can create a new key or use existing key.
                        <br />
                        2. Sometimes gradle build failed also occur because of minimum API level so go in build setting {"->"} player setting {"->"} other setiing in other setting you will find minimum API level try to change this API level to Android 4.4 or 5.0, if you are using newer version of unity and API level is not present in other setting than leave it.
                        <br />
                        3.  third and main reason of gradle build failed is gradle is not installed correctly so just go to file explorer and click on window drive {"->"} click users {"->"} click local user name (this is whatever is your name in computer)
                        <br />
                        delete this folder and now now build your project and make sure your pc is connected to internet because unity will download the correct gradle version according to unity version so don't need to worry about anything just remember you are connected to network.
                        After following these step you can build your gradle project.
                    </p>
                </div>
                <div class="postbox__author d-flex align-items-center">
                    <div class="postbox__author-thumb mr-15">
                        <i class="fas fa-user"></i>
                    </div>
                    <h5>Post by <Link to="#">Programmer</Link> </h5>
                </div>
            </div>
        </article>
    )
}

export default BlogSingle