import React from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Skylineshades = () => {
  const services = [
    {
      name: "Website Development",
      path: "/services/website-development",
    },
    {
      name: "Mobile App Development",
      path: "/services/app-development",
    },
    {
      name: "SEO Services",
      path: "/services/digital-marketing/seo-services",
    },
    {
      name: "Social Media Marketing",
      path: "/services/digital-marketing/social-media-marketing",
    },
    {
      name: "Content Marketing",
      path: "/services/digital-marketing/content-marketing",
    },
    {
      name: "PPC Advertising",
      path: "/services/digital-marketing/ppc-advertising",
    },
    {
      name: "Enterprise Solutions",
      path: "/services/enterprise-solutions",
    },
    {
      name: "Software Development",
      path: "/services/software-development",
    },
  ];

  return (
    <>
      <Header />
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[70vh] sm:h-[80vh] flex items-center justify-center"
        style={{
          backgroundImage: "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738477929/home_zmn81i.jpg')",
        }}
      >
        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/70"></div>

        {/* Content Section */}
        <div className="relative text-center px-6 text-white">
          <h1 className="text-4xl sm:text-6xl font-bold mb-4 leading-tight">
            Skyline Shades
          </h1>
          <h2 className="text-lg sm:text-2xl font-medium mb-6">
            Elevate Your Space with Elegant Window Treatments
          </h2>
        </div>

        {/* Expertise Section */}
        <div className="absolute bottom-10 left-0 right-0 text-center text-white px-4">
          <p className="text-sm sm:text-base font-medium">
            Expertise:{" "}
            <Link
              to="/services/website-development"
              className="font-semibold text-indigo-600 underline hover:text-indigo-400 transition"
            >
              <span>Web Development</span>
            </Link>
            {" , "}
            <Link
              to="/services/digital-marketing"
              className="font-semibold text-indigo-600 underline hover:text-indigo-400 transition"
            >
              <span>Digital Marketing</span>
            </Link>
            {" & "}
            <Link
              to="/services/digital-marketing/seo-services"
              className="font-semibold text-indigo-600 underline hover:text-indigo-400 transition"
            >
              <span>SEO Optimization</span>
            </Link>
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-12 p-8 bg-indigo-50 lg:px-28">
        {/* Services Provided Section */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h3 className="text-3xl font-bold text-indigo-800 mb-6">
            Our Contribution to Skyline Shades
          </h3>
          <p className="text-indigo-600 text-lg mb-4">
            Odidor played a crucial role in building a high-performance,
            SEO-optimized, and user-friendly website for{" "}
            <span className="font-semibold text-indigo-800">
              <a
                href="https://skylineshades.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-orange-700 transition"
              >
                Skyline Shades
              </a>
            </span>
            . Our focus was on seamless navigation, mobile responsiveness, and a
            strong digital presence.
          </p>

          <h4 className="text-2xl font-semibold text-indigo-800 mb-4">
            Services Provided
          </h4>

          <div className="flex flex-wrap gap-4">
            {services.map((service, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.1, backgroundColor: "#0274BD" }}
                whileTap={{ scale: 0.95 }}
                className="px-5 py-3 bg-indigo-500 border border-indigo-300 rounded-full text-sm font-medium cursor-pointer shadow-md hover:shadow-lg transition-all"
                style={{ transition: "color 0.3s, background-color 0.3s" }}
              >
                <Link to={service.path} className="text-white">
                  {service.name}
                </Link>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Description Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h2 className="text-3xl font-extrabold text-indigo-800 leading-tight">
            Transforming Skyline Shades' Online Presence with Odidor's Expertise
          </h2>
          <p className="mt-6 text-indigo-600 text-lg">
            <span className="font-extrabold text-indigo-500">
              <Link to="/">Odidor</Link>
            </span>{" "}
            provided a cutting-edge website solution for{" "}
            <span className="font-extrabold text-orange-500">
              <a
                href="https://skylineshades.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-orange-700 transition"
              >
                Skyline Shades
              </a>
            </span>
            , ensuring a seamless user experience, intuitive design, and
            enhanced digital reach. Our goal was to craft a robust and scalable
            website to meet their business needs.
          </p>

          {/* Details Table */}
          <div className="mt-8 bg-white rounded-lg shadow-lg p-6 border-l-4 border-indigo-500">
            <table className="w-full text-left text-indigo-700">
              <tbody>
                {[
                  {
                    label: "Frontend Technology",
                    value: "React Js / Bootstrap Js",
                  },
                  {
                    label: "SEO Optimization",
                    value: "Advanced Keyword Strategy",
                  },
                  {
                    label: "Performance Score",
                    value: "95+ (Lighthouse)",
                  },
                  {
                    label: "Timescale",
                    value: "12 Weeks",
                  },
                  {
                    label: "Launch Date",
                    value: "January 2025",
                  },
                ].map((item, index) => (
                  <tr key={index} className="border-b last:border-none">
                    <td className="py-3 font-medium">{item.label}</td>
                    <td className="py-3 text-indigo-500">{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-6 text-center">
              <a
                href="https://skylineshades.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-indigo-500 text-white px-6 py-3 rounded-lg hover:bg-indigo-600 transition duration-300">
                  Visit Skyline Shades Website
                </button>
              </a>
            </div>
          </div>
        </motion.div>
      </div>

      {/* image  */}
      <div
        className="relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-blue-600 to-blue-900 text-white"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738476780/installation_vm6omu.jpg')",
        }}
      ></div>

      {/* Skyline Shade Features */}
      <section className="w-full py-16 px-8 lg:px-24 bg-gradient-to-b from-indigo-50 to-indigo-100">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
        >
          <motion.img
            src="https://res.cloudinary.com/dhhsupcai/image/upload/v1737718266/sky_line_mmkcoz.jpg"
            alt="Skyline Shade"
            className="rounded-xl shadow-lg transform hover:scale-105 transition duration-500 ease-in-out"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1 }}
          />

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 text-indigo-700 leading-tight">
              Elevate Your Spaces with{" "}
              <span className="font-bold">Skyline Shades</span>
            </h2>
            <p className="text-gray-700 text-lg mb-8 leading-relaxed">
              At{" "}
              <span className="font-semibold text-indigo-800">
                <a
                  href="https://skylineshades.ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-extrabold hover:text-orange-700 transition"
                >
                  Skyline Shades
                </a>
              </span>
              , we transform window treatments into a statement of luxury and
              functionality. Our shades are designed to blend cutting-edge
              technology with modern aesthetics, ensuring a seamless fit for
              your unique space.
            </p>

            <ul className="space-y-4 text-lg text-gray-700 mb-10">
              <motion.li
                whileHover={{ scale: 1.05, x: 10 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-blue-500">✔️</span>
                Contemporary designs tailored to your needs
              </motion.li>
              <motion.li
                whileHover={{ scale: 1.05, x: 10 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-blue-500">✔️</span>
                Smart shading solutions for energy efficiency
              </motion.li>
              <motion.li
                whileHover={{ scale: 1.05, x: 10 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-blue-500">✔️</span>
                Premium materials for unmatched durability
              </motion.li>
              <motion.li
                whileHover={{ scale: 1.05, x: 10 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-blue-500">✔️</span>
                Innovative motorized options for modern living
              </motion.li>
            </ul>

            <a
              href="https://skylineshades.ca/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gradient-to-r from-blue-600 to-blue-500 text-white py-3 px-8 rounded-lg shadow-md hover:bg-blue-700 transform hover:scale-105 transition duration-300"
            >
              Explore Skyline Shades
            </a>
          </motion.div>
        </motion.div>

        <div className="mt-20 grid grid-cols-1 lg:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="bg-gradient-to-r from-indigo-50 to-indigo-100 p-8 rounded-xl shadow-lg"
          >
            <h3 className="text-3xl font-bold mb-4 text-indigo-700">
              Our Vision: Modern Shades, Timeless Appeal
            </h3>
            <p className="text-gray-700 text-lg">
              At{" "}
              <span className="font-semibold text-indigo-800">
                <a
                  href="https://skylineshades.ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-extrabold hover:text-orange-700 transition"
                >
                  Skyline Shades
                </a>
              </span>
              , we envision redefining modern living with shading solutions that
              combine advanced technology and timeless elegance, creating
              environments that inspire comfort and sophistication.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="bg-gradient-to-r from-teal-50 to-teal-100 p-8 rounded-xl shadow-lg"
          >
            <h3 className="text-3xl font-bold mb-4 text-teal-700">
              Why Choose Skyline Shade?
            </h3>
            <p className="text-gray-700 text-lg">
              Our shades are crafted with precision, offering a balance of style
              and utility. With a focus on customer satisfaction, we ensure that
              every project is executed with excellence, providing you with
              solutions that stand the test of time.
            </p>
          </motion.div>
        </div>

        <div className="mt-20">
          <h3 className="text-3xl font-bold mb-6 text-[#825bf0]">
            Key Highlights of Skyline Shades
          </h3>
          <ul className="space-y-6 text-lg text-gray-700">
            <motion.li
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="flex items-center"
            >
              <span className="mr-3 text-green-500">✔️</span>
              Sleek, minimalistic designs with a modern touch
            </motion.li>
            <motion.li
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="flex items-center"
            >
              <span className="mr-3 text-green-500">✔️</span>
              Energy-efficient solutions for sustainable living
            </motion.li>
            <motion.li
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="flex items-center"
            >
              <span className="mr-3 text-green-500">✔️</span>
              Motorized and smart control options available
            </motion.li>
            <motion.li
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="flex items-center"
            >
              <span className="mr-3 text-green-500">✔️</span>
              Designed and delivered within 1-8 Days
            </motion.li>
            <motion.li
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="flex items-center"
            >
              <span className="mr-3 text-green-500">✔️</span>
              Official Launch Date: January 2025
            </motion.li>
          </ul>
        </div>
      </section>
      {/* image  */}
      <div
        className="relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-blue-600 to-blue-900 text-white"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738478080/slide-3_xvs4sa.jpg')",
        }}
      ></div>

      {/* Call-to-Action Section */}
      <section className="bg-gradient-to-br from-indigo-900 to-[#9013FE] text-white py-12 text-center">
        <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 leading-tight">
          Let’s Collaborate & Drive Success Together
        </h2>
        <p className="text-lg font-light mb-8">
          Ready to elevate your business with a cutting-edge e-commerce solution
          tailored to your needs?
        </p>
        <Link
          title="contact page"
          to="/contact"
          className="bg-gradient-to-r from-indigo-500 to-[#825bf0] hover:from-indigo-800 hover:to-indigo-700 px-8 py-4 rounded-lg text-xl font-semibold shadow-lg transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center justify-center"
        >
          <span className="mr-4">✉️</span> Contact Us Now
        </Link>
      </section>

      <Footer />
    </>
  );
};

export default Skylineshades;
