import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Paulospizza = () => {
  const images = [
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1738482720/sec1_ftf8gb.jpg",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1738482719/sec2_hxxqiz.jpg",
  ];
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };
  return (
    <>
      <Header />
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[60vh] sm:h-[70vh] flex items-center justify-start px-8 sm:px-16 bg-black/50"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738479298/sec3_lnp01p.jpg')",
        }}
      >
        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-black opacity-50"></div>

        {/* Content Section */}
        <div className="relative z-10 max-w-2xl text-left">
          <h1 className="text-4xl sm:text-5xl font-extrabold mb-4 leading-tight text-red-500 drop-shadow-lg">
            Paulo's Pizza
          </h1>
          <h2 className="text-lg sm:text-xl font-medium mb-6 text-gray-300 drop-shadow-md">
            Serving delicious, handcrafted pizzas made with the freshest
            ingredients. Experience the perfect blend of taste and tradition
            with Paulo's Pizza.
          </h2>

          {/* Expertise Section */}
          <div className="mt-4">
            <p className="text-sm sm:text-base font-medium text-gray-300">
              Expertise:{" "}
              <Link
                to="/services/software-development"
                className="font-semibold text-red-400 underline hover:text-red-300 transition"
              >
                <span>POS System</span>
              </Link>
              {", "}
              <Link
                to="/services/website-development/custom-cms-development"
                className="font-semibold text-red-400 underline hover:text-red-300 transition"
              >
                <span>CMS Development</span>
              </Link>
              {", and "}
              <Link
                to="/services/digital-marketing/seo-services"
                className="font-semibold text-red-400 underline hover:text-red-300 transition"
              >
                <span>SEO Optimization</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      {/* Project Details Section */}
      <div className="flex flex-col md:flex-row gap-12 p-8 bg-red-50 lg:px-28">
        {/* Services Provided Section */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h3 className="text-3xl font-bold text-red-800 mb-6">
            Our Contribution to Paulo's Pizza
          </h3>
          <p className="text-red-600 text-lg mb-4">
            Odidor played a crucial role in building a dynamic, full-stack,
            SEO-optimized, and user-friendly website for{" "}
            <span className="font-semibold text-red-800">
              <a
                href="https://paulospizza.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-indigo-700 transition"
              >
                Paulo's Pizza
              </a>
            </span>
            . Our focus was on seamless navigation, mobile responsiveness, and
            enhancing their online presence.
          </p>

          <h4 className="text-2xl font-semibold text-red-800 mb-4">
            Services Provided
          </h4>

          <div className="flex flex-wrap gap-4">
            {[
              {
                name: "Full-Stack Development",
                path: "/services/website-development/web-app-development",
              },
              {
                name: "CMS Development",
                path: "/services/website-development/custom-cms-development",
              },
              { name: "POS System", path: "/services/software-development" },
              {
                name: "SEO Optimization",
                path: "/services/digital-marketing/seo-services",
              },
              {
                name: "Responsive Web Design",
                path: "/services/website-development",
              },
              {
                name: "E-Commerce Integration",
                path: "/services/website-development/ecommerce-web-development",
              },
              {
                name: "Performance Optimization",
                path: "/services/website-development/ecommerce-web-development",
              },
              {
                name: "Custom Laravel Solutions",
                path: "/services/website-development/custom-cms-development",
              },
              {
                name: "Database Management (MySQL)",
                path: "/services/software-development/custom-software-development",
              },
            ].map((service, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.1, backgroundColor: "#b91c1c" }}
                whileTap={{ scale: 0.95 }}
                className="px-5 py-3 bg-red-500 border border-red-300 rounded-full text-sm font-medium cursor-pointer shadow-md hover:shadow-lg transition-all"
                style={{ transition: "color 0.3s, background-color 0.3s" }}
              >
                <Link to={service.path} className="text-white">
                  {service.name}
                </Link>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Description Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h2 className="text-3xl font-extrabold text-red-800 leading-tight">
            Elevating Paulo's Pizza's Online Presence with Odidor's Expertise
          </h2>
          <p className="mt-6 text-red-600 text-lg">
            <span className="font-extrabold text-red-500">
              <Link to="/">Odidor</Link>
            </span>{" "}
            delivered a robust website solution for{" "}
            <span className="font-extrabold text-indigo-500">
              <a
                href="https://paulospizza.ca/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-indigo-700 transition"
              >
                Paulo's Pizza
              </a>
            </span>
            , ensuring seamless user interaction, intuitive design, and enhanced
            digital visibility. Our mission was to create a scalable and
            high-performance website tailored to their business goals.
          </p>

          {/* Details Table */}
          <div className="mt-8 bg-white rounded-lg shadow-lg p-6 border-l-4 border-red-500">
            <table className="w-full text-left text-red-700">
              <tbody>
                {[
                  { label: "Frontend Technology", value: "Laravel" },
                  { label: "Database", value: "MySQL" },
                  {
                    label: "SEO Optimization",
                    value: "Advanced Keyword Strategy",
                  },
                  { label: "Performance Score", value: "98+ (Lighthouse)" },
                  { label: "Timescale", value: "3-4 Weeks" },
                  { label: "Launch Date", value: "March 2023" },
                ].map((item, index) => (
                  <tr key={index} className="border-b last:border-none">
                    <td className="py-3 font-medium">{item.label}</td>
                    <td className="py-3 text-red-500">{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-6 text-center">
              <a
                href="https://paulospizza.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-red-500 text-white px-6 py-3 rounded-lg hover:bg-red-600 transition duration-300">
                  Visit Paulo's Pizza Website
                </button>
              </a>
            </div>
          </div>
        </motion.div>
      </div>
      {/* Gallery Section */}
      <div className="bg-gray-50 py-10 px-5">
        <div className="max-w-7xl mx-auto">
          <motion.div
            className="grid gap-4"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            {/* Top Row: 2 Images */}
            <div className="grid grid-cols-2 gap-4">
              {images.slice(0, 2).map((image, index) => (
                <motion.div
                  key={index}
                  className="overflow-hidden rounded-lg shadow-lg"
                  variants={itemVariants}
                >
                  <img
                    src={image}
                    alt={`Blind ${index + 1}`}
                    className="w-full h-auto object-cover"
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>

      {/* Paulo's Pizza features  */}
      <section className="container max-w-full py-16 px-8 lg:px-24 bg-gradient-to-b from-indigo-50 to-indigo-100">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.2 }}
          className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
        >
          <motion.img
            src="https://res.cloudinary.com/doefzwlxm/image/upload/v1738479300/pizza_gi6q3i.jpg"
            alt="Paulo's Pizza"
            className="rounded-xl shadow-lg transform hover:scale-105 transition duration-500 ease-in-out"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1 }}
          />

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.2 }}
          >
            <h2 className="text-5xl lg:text-6xl font-extrabold mb-6 text-indigo-900 leading-tight">
              Savor the Flavor with Every Slice of{" "}
              <span className="text-red-500">
                <a
                  href="https://paulospizza.ca/index.php"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-extrabold hover:text-red-700 transition"
                >
                  Paulo's Pizza
                </a>
              </span>
            </h2>
            <p className="text-lg lg:text-xl text-indigo-700 mb-6">
              At{" "}
              <span className="font-semibold text-indigo-800">
                <a
                  href="https://paulospizza.ca/index.php"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-extrabold hover:text-red-700 transition"
                >
                  Paulo's Pizza
                </a>
              </span>
              , we bring you a delicious selection of handcrafted pizzas made
              with the finest ingredients. Whether you're craving a classic or
              an adventurous twist, we’ve got the perfect pizza for every taste.
            </p>
            <ul className="list-disc list-inside text-indigo-700 mb-6">
              <li>Handcrafted pizzas made with the freshest ingredients</li>
              <li>Fast and reliable delivery to your doorstep</li>
              <li>Customizable pizza options to suit your cravings</li>
              <li>Affordable and transparent pricing with no hidden fees</li>
            </ul>
          </motion.div>
        </motion.div>

        <div className="mt-12 text-center">
          <h3 className="text-3xl font-semibold text-indigo-800 mb-6">
            Project Overview
          </h3>
          <p className="text-lg lg:text-xl text-indigo-600 mb-6 max-w-4xl mx-auto">
            The Paulo's Pizza website was developed by{" "}
            <Link to="/" className="font-semibold text-indigo-800">
              Odidor
            </Link>
            , a leading software company that specializes in building innovative
            and user-friendly digital solutions. This project highlights our
            expertise in developing fast, responsive websites tailored to meet
            the needs of businesses in the food industry.
          </p>
          <div className="flex justify-center space-x-12 mt-8">
            <div className="text-center">
              <h4 className="text-xl font-semibold text-indigo-700">
                Frontend Technology
              </h4>
              <p className="text-indigo-600 text-lg">Laravel</p>
            </div>
            <div className="text-center">
              <h4 className="text-xl font-semibold text-indigo-700">
                Database
              </h4>
              <p className="text-indigo-600 text-lg">MySQL</p>
            </div>
          </div>
        </div>

        {/* Why Choose Odidor Section */}
        <div className="mt-16 text-center px-4">
          <h3 className="text-3xl font-semibold text-indigo-800 mb-6">
            Why Choose Odidor?
          </h3>
          <div className="flex flex-col md:flex-row justify-center md:space-x-16 space-y-6 md:space-y-0">
            {/* POS System Service */}
            <motion.div
              className="p-6 md:p-8 bg-white rounded-xl shadow-lg transform hover:scale-105 transition duration-300 w-full sm:w-[22rem]"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <Link
                to="/services/software-development"
                className="text-indigo-600 font-bold hover:text-indigo-800"
              >
                <div className="text-indigo-700 mb-4">
                  <motion.i
                    className="fas fa-cash-register text-4xl"
                    initial={{ rotate: -20 }}
                    animate={{ rotate: 0 }}
                    transition={{ duration: 1.2 }}
                  />
                </div>
                <h4 className="text-xl font-semibold text-indigo-800 mb-2">
                  POS System
                </h4>
                <p className="text-lg text-indigo-600">
                  Our Point of Sale (POS) systems streamline business operations
                  with fast, efficient, and reliable solutions, integrating
                  seamlessly for smooth transactions and accurate data
                  management.
                </p>
                <span className="text-indigo-600 font-bold hover:text-indigo-800 block mt-3">
                  Learn More
                </span>
              </Link>
            </motion.div>

            {/* CMS Development Service */}
            <motion.div
              className="p-6 md:p-8 bg-white rounded-xl shadow-lg transform hover:scale-105 transition duration-300 w-full sm:w-[22rem]"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <Link
                to="/services/website-development/custom-cms-development"
                className="text-indigo-600 font-bold hover:text-indigo-800"
              >
                <div className="text-indigo-700 mb-4">
                  <motion.i
                    className="fas fa-cogs text-4xl"
                    initial={{ rotate: -20 }}
                    animate={{ rotate: 0 }}
                    transition={{ duration: 1.2 }}
                  />
                </div>
                <h4 className="text-xl font-semibold text-indigo-800 mb-2">
                  CMS Development
                </h4>
                <p className="text-lg text-indigo-600">
                  Custom content management solutions that empower businesses to
                  efficiently manage their online presence, tailored for both
                  startups and enterprises.
                </p>
                <span className="text-indigo-600 font-bold hover:text-indigo-800 block mt-3">
                  Learn More
                </span>
              </Link>
            </motion.div>
          </div>
        </div>

        {/* This Project Developed by Odidor */}
        <div className="mt-12 text-center">
          <h3 className="text-3xl font-semibold text-indigo-800 mb-6">
            This Project Developed by Odidor
          </h3>
          <p className="text-lg lg:text-xl text-indigo-600 mb-6 max-w-4xl mx-auto">
            At{" "}
            <Link to="/" className="font-semibold text-indigo-800">
              Odidor
            </Link>
            , we specialize in delivering innovative software solutions tailored
            to meet the unique needs of each client. Our expertise in custom CMS
            and POS systems empowers businesses to thrive in the digital age.
          </p>
        </div>
      </section>

      {/* image  */}
      <div
        className="relative h-[60vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-indigo-600 to-indigo-900 text-white"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738479298/sec3_lnp01p.jpg')",
        }}
      ></div>
      {/* Paulo's Pizza Project - Conclusion Section */}
      <section className="bg-gradient-to-r from-indigo-100 to-indigo-200 py-16 px-6 md:px-16">
        <h3 className="text-4xl md:text-5xl font-extrabold text-center text-indigo-800 mb-8">
          Conclusion: Enhancing{" "}
          <span className="font-extrabold text-indigo-900">
            <Link
              to="/"
              className="font-extrabold text-indigo-900 hover:underline"
            >
              Odidor
            </Link>
          </span>
          's Project for{" "}
          <span className="font-extrabold text-indigo-900">
            <a
              href="https://paulospizza.ca/index.php"
              target="_blank"
              rel="noopener noreferrer"
              className="font-extrabold text-indigo-900 hover:underline"
            >
              Paulo's Pizza
            </a>
          </span>
        </h3>
        <div className="max-w-6xl mx-auto text-lg md:text-xl text-gray-700 space-y-6 md:space-y-8">
          <motion.p
            className="mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            The collaboration between{" "}
            <span className="font-extrabold text-indigo-900">
              <Link
                to="/"
                className="font-extrabold text-indigo-900 hover:underline"
              >
                Odidor
              </Link>
            </span>{" "}
            and{" "}
            <span className="font-extrabold text-indigo-900">
              <a
                href="https://paulospizza.ca/index.php"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold text-indigo-900 hover:underline"
              >
                Paulo's Pizza
              </a>
            </span>{" "}
            has successfully transformed their online presence, delivering a
            seamless and interactive platform that elevates the customer
            ordering experience.
          </motion.p>
          <motion.p
            className="mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Through our tailored solutions, we developed a user-friendly website
            that showcases the rich flavors and menu variety of{" "}
            <span className="font-extrabold text-indigo-900">
              <a
                href="https://paulospizza.ca/index.php"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold text-indigo-900 hover:underline"
              >
                Paulo's Pizza
              </a>
            </span>
            , making it easier than ever for customers to browse and place
            orders.
          </motion.p>
          <motion.p
            className="mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            The result is a modern, responsive site that enhances customer
            engagement while streamlining the pizza ordering process, with
            features that align perfectly with{" "}
            <span className="font-extrabold text-indigo-900">
              <a
                href="https://paulospizza.ca/index.php"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold text-indigo-900 hover:underline"
              >
                Paulo's Pizza
              </a>
            </span>
            's brand identity and goals.
          </motion.p>
          <motion.p
            className="mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            At{" "}
            <span className="font-extrabold text-indigo-900">
              <Link
                to="/"
                className="font-extrabold text-indigo-900 hover:underline"
              >
                Odidor
              </Link>
            </span>
            , we are proud to have contributed to the growth of{" "}
            <span className="font-extrabold text-indigo-900">
              <a
                href="https://paulospizza.ca/index.php"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold text-indigo-900 hover:underline"
              >
                Paulo's Pizza
              </a>
            </span>{" "}
            through innovative web development solutions that offer a fresh,
            engaging digital experience for customers.
          </motion.p>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="bg-gradient-to-br from-indigo-900 to-[#9013FE] text-white py-12 text-center">
        <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 leading-tight">
          Let’s Collaborate & Drive Success Together
        </h2>
        <p className="text-lg font-light mb-8">
          Ready to elevate your business with a cutting-edge e-commerce solution
          tailored to your needs?
        </p>
        <Link
          to="/contact"
          title="contact page"
          className="bg-gradient-to-r from-indigo-500 to-[#825bf0] hover:from-indigo-800 hover:to-indigo-700 px-8 py-4 rounded-lg text-xl font-semibold shadow-lg transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center justify-center"
        >
          <span className="mr-4">✉️</span> Contact Us Now
        </Link>
      </section>
      <Footer />
    </>
  );
};

export default Paulospizza;
