import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeroSection from "../components/industries/HeroSection";
import { industries, projects } from "../constants/constants";
import { Link } from "react-router-dom";

// Function to calculate projects per industry
const getIndustryCounts = () => {
  return industries.map((industry) => {
    const industryProjects = projects.filter((project) =>
      project.industries.includes(industry)
    );
    return {
      name: industry,
      count: industryProjects.length,
      projects: industryProjects, 
    };
  });
};

export default function IndustriesPage() {
  const industryData = getIndustryCounts();
  const [selectedIndustry, setSelectedIndustry] = useState(null);

  return (
    <div className="bg-black">
      <Header />
      <HeroSection />
      {/* Industries List */}
      <div className="mt-12 mx-auto w-full max-w-4xl px-4 md:px-8">
        {industryData.map((industry, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.05, duration: 0.5 }}
            className="cursor-pointer flex items-center justify-between py-4 text-lg md:text-xl font-bold text-gray-300 border-b border-gray-700 group transition-all relative overflow-hidden"
            onClick={() => setSelectedIndustry(industry)}
          >
            {/* Industry Name */}
            <motion.span className="transition-all duration-300 group-hover:text-indigo-500">
              {industry.name}
            </motion.span>

            {/* Project Count & Arrow Appearing on Hover */}
            <motion.div className="flex items-center gap-3 opacity-0 translate-x-6 group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-300">
              <span className="text-sm md:text-base text-gray-500 group-hover:text-indigo-500">
                {industry.count} Projects
              </span>
              <span className="text-xl text-indigo-400">→</span>
            </motion.div>
          </motion.div>
        ))}
      </div>

      <AnimatePresence>
        {selectedIndustry && (
          <motion.div
            className="fixed top-0 right-0 h-full w-full md:w-[500px] bg-gray-900 shadow-2xl border-l  pt-20 border-gray-700 p-6 overflow-y-auto z-50"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            {/* Close Button - Fixed Position */}
            <motion.button
              className="absolute top-6 right-6 text-indigo-400 hover:text-white  pt-16 transition-transform transform hover:scale-110 z-50"
              onClick={() => setSelectedIndustry(null)}
              whileTap={{ scale: 0.9 }}
            >
              ✖
            </motion.button>

            {/* Industry Title */}
            <motion.h2
              className="text-3xl font-extrabold text-[#825bf0]"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, ease: "easeOut" }}
            >
              {selectedIndustry.name}
            </motion.h2>
            <p className="text-gray-400 mt-2 text-lg">
              Projects: {selectedIndustry.projects.length}
            </p>

            {/* Industry Description */}
            <motion.p
              className="text-gray-300 mt-4 leading-relaxed"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: 0.1 }}
            >
              {selectedIndustry.description ||
                "Explore how technology and innovation are reshaping this industry. Discover our impactful projects and solutions."}
            </motion.p>

            {/* Related Projects */}
            <div className="mt-6">
              <h3 className="text-xl font-bold text-white">Related Projects</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-4">
                {selectedIndustry.projects.map((project, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <Link
                      to={project.route}
                      className="bg-gray-800 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105"
                    >
                      {/* Project Image */}
                      {project.image && (
                        <img
                          src={project.image}
                          alt={project.projectName}
                          className="w-full h-48 object-cover"
                        />
                      )}

                      {/* Project Details */}
                      <div className="p-4">
                        <h4 className="text-lg font-bold text-[#825bf0]">
                          {project.projectName}
                        </h4>
                      </div>
                    </Link>
                  </motion.div>
                ))}
              </div>
            </div>

            {/* Call to Action */}
            <motion.div
              className="mt-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: 0.2 }}
            >
              <Link
                to="/projects"
                className="block w-full text-center text-white bg-[#825bf0] py-2 rounded-lg shadow hover:bg-indigo-500 transition"
              >
                View All Works
              </Link>
              <p className="text-gray-400 text-sm mt-4">
                To learn more, contact us at{" "}
                <a
                  href="mailto:ryan@odidor.co"
                  className="text-indigo-400"
                >
                  ryan@odidor.co
                </a>{" "}
                or call{" "}
                <a href="tel:+16043633150" className="text-indigo-400">
                 +(604) 363 3150
                </a>
              </p>
            </motion.div>

            {/* Company Information - Odidor */}
            <motion.div
              className="mt-6 text-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, ease: "easeOut", delay: 0.3 }}
            >
              <h3 className="text-xl font-bold text-white">Odidor</h3>
              <p className="text-gray-400 mt-1">Your trusted digital partner</p>
              <p className="text-gray-300 mt-2">
                <strong>Call us:</strong>{" "}
                <a href="tel:+16043633150" className="text-indigo-400">
                +(604) 363 3150
                </a>
              </p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <Footer />
    </div>
  );
}
