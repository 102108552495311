import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Ecblinds = () => {
  return (
    <>
      <Header />
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-screen flex items-center justify-center text-white px-4"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1739811793/slider-3_wnomyf.jpg')",
        }}
      >
        {/* Dark Overlay */}
        <div className="absolute inset-0 bg-black/50"></div>

        {/* Content Container */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="relative text-center max-w-3xl pb-4 px-4"
        >
          <div
            className="bg-white/10 backdrop-blur-lg p-6 sm:p-12 rounded-3xl border border-white/30 shadow-xl 
      transition-all transform hover:scale-105 hover:shadow-[0_0_20px_rgba(255,255,255,0.3)]"
          >
            <h1
              className="text-4xl sm:text-8xl font-extrabold uppercase tracking-wider drop-shadow-md 
       text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-500"
            >
              EC Blinds
            </h1>

            <p className="mt-4 sm:mt-6 text-sm sm:text-2xl font-medium tracking-wide text-gray-200 leading-relaxed max-w-[90%] mx-auto">
              Transform your space with{" "}
              <span className="font-semibold text-blue-400">
                elegant, high-quality, and smart
              </span>{" "}
              window blinds. Experience a seamless shopping journey with{" "}
              <span className="font-semibold text-cyan-400">EC Blinds</span>,
              designed for style, convenience, and performance.
            </p>
          </div>
        </motion.div>

        {/* Expertise Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 1 }}
          className="absolute bottom-6 sm:bottom-10  left-0 right-0 text-center text-white px-4"
        >
          <p className="text-xs sm:text-lg font-medium">
            <span className="uppercase tracking-wide text-gray-300">
              Expertise in
            </span>
          </p>
          <div className="flex flex-wrap justify-center gap-4 sm:gap-6 mt-2">
            <Link
              to="/services/website-development/ecommerce-web-development"
              className="flex items-center gap-2 bg-white/10 px-3 py-2 sm:px-4 sm:py-2 rounded-lg shadow-md hover:bg-white/20 transition"
            >
              <span className="text-yellow-400 text-lg sm:text-xl">
                <i className="fas fa-shopping-cart"></i>
              </span>
              <span className="text-white font-semibold text-sm sm:text-base">
                E-Commerce
              </span>
            </Link>

            <Link
              to="/services/website-development/ui/ux-design"
              className="flex items-center gap-2 bg-white/10 px-3 py-2 sm:px-4 sm:py-2 rounded-lg shadow-md hover:bg-white/20 transition"
            >
              <span className="text-yellow-400 text-lg sm:text-xl">
                <i className="fas fa-paint-brush"></i>
              </span>
              <span className="text-white font-semibold text-sm sm:text-base">
                UI/UX Design
              </span>
            </Link>

            <Link
              to="/services/digital-marketing/performance-optimization"
              className="flex items-center gap-2 bg-white/10 px-3 py-2 sm:px-4 sm:py-2 rounded-lg shadow-md hover:bg-white/20 transition"
            >
              <span className="text-yellow-400 text-lg sm:text-xl">
                <i className="fas fa-tachometer-alt"></i>
              </span>
              <span className="text-white font-semibold text-sm sm:text-base">
                Performance
              </span>
            </Link>
          </div>
        </motion.div>
      </div>
      <div className="bg-blue-50 w-full py-12">
        <div className="container mx-auto px-6 lg:px-16">
          {/* Project Details Section */}
          <div className="flex flex-col md:flex-row gap-12">
            {/* Services Provided Section */}
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="flex-1"
            >
              <h3 className="text-3xl font-bold text-blue-600 mb-6">
                Our Contribution to EC Blinds
              </h3>
              <p className="text-gray-800 text-lg mb-4">
                Odidor, a leading software company, developed a
                high-performance, SEO-optimized, and visually stunning website
                for{" "}
                <span className="font-semibold text-blue-700">
                  <a
                    href="https://www.ecblinds.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-extrabold hover:text-blue-800 transition"
                  >
                    EC Blinds
                  </a>
                </span>
                . This project, featured in Odidor’s portfolio, showcases our
                expertise in creating seamless UI/UX experiences with
                fast-loading static sites.
              </p>

              <h4 className="text-2xl font-semibold text-blue-700 mb-4">
                Services Provided
              </h4>

              <div className="flex flex-wrap gap-4">
                {[
                  {
                    name: "React.js Development",
                    path: "/services/website-development",
                  },
                  {
                    name: "Bootstrap UI/UX Design",
                    path: "/services/website-development/ui/ux-design",
                  },
                  {
                    name: "Motion Animations",
                    path: "/services/website-development/ui/ux-design",
                  },
                  {
                    name: "SEO Optimization",
                    path: "/services/digital-marketing/seo-services",
                  },
                  {
                    name: "Mobile Responsive Design",
                    path: "/services/website-development",
                  },
                  {
                    name: "Static Site Development",
                    path: "/services/website-development",
                  },
                ].map((service, index) => (
                  <motion.div
                    key={index}
                    whileHover={{ scale: 1.1, backgroundColor: "#1E40AF" }}
                    whileTap={{ scale: 0.95 }}
                    className="px-5 py-3 bg-blue-500 border border-blue-600 rounded-full text-sm font-medium cursor-pointer shadow-md hover:shadow-lg transition-all"
                    style={{ transition: "color 0.3s, background-color 0.3s" }}
                  >
                    <Link
                      to={service.path}
                      className="text-white font-semibold"
                    >
                      {service.name}
                    </Link>
                  </motion.div>
                ))}
              </div>
            </motion.div>

            {/* Description Section */}
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="flex-1"
            >
              <h2 className="text-3xl font-extrabold text-blue-700 leading-tight">
                Elevating EC Blinds' Digital Presence
              </h2>
              <p className="mt-6 text-gray-800 text-lg">
                <span className="font-extrabold text-blue-500">
                  <Link to="/">Odidor</Link>
                </span>{" "}
                designed and developed a modern, high-performance static website
                for{" "}
                <span className="font-extrabold text-blue-600">
                  <a
                    href="https://www.ecblinds.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-extrabold hover:text-blue-700 transition"
                  >
                    EC Blinds
                  </a>
                </span>
                , ensuring optimal performance, responsiveness, and engaging UI
                animations.
              </p>

              {/* Details Table */}
              <div className="mt-8 bg-white rounded-lg shadow-lg p-6 border-l-4 border-blue-500">
                <table className="w-full text-left text-gray-900">
                  <tbody>
                    {[
                      {
                        label: "Frontend Technology",
                        value: "React.js, Bootstrap",
                      },
                      { label: "Animations & UI Effects", value: "Motion.js" },
                      {
                        label: "SEO Optimization",
                        value: "Fast static architecture",
                      },
                      { label: "Performance Score", value: "99+ (Lighthouse)" },
                      { label: "Timescale", value: "4 Weeks" },
                      { label: "Launch Date", value: "March 2024" },
                    ].map((item, index) => (
                      <tr key={index} className="border-b last:border-none">
                        <td className="py-3 font-medium">{item.label}</td>
                        <td className="py-3 text-blue-600">{item.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="mt-6 text-center">
                  <a
                    href="https://www.ecblinds.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="bg-blue-500 text-white font-bold px-6 py-3 rounded-lg hover:bg-blue-600 transition duration-300">
                      Visit EC Blinds Website
                    </button>
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <section className="w-full bg-gradient-to-b from-blue-50 to-blue-100 py-20">
        <div className="container mx-auto px-8 lg:px-24 text-center">
          <h2 className="text-5xl font-extrabold text-gray-800 leading-tight">
            Transforming Window Décor with
            <span className="text-blue-700"> EC Blinds</span>
          </h2>
          <p className="text-lg text-gray-700 mt-4 max-w-3xl mx-auto">
            A modern blinds and window treatment company offering premium
            solutions. Developed by{" "}
            <span className="font-bold text-blue-900">Odidor</span>, this
            project blends cutting-edge technology with seamless functionality.
          </p>
        </div>

        {/* Feature Section */}
        <div className="container mx-auto mt-16 grid grid-cols-1 lg:grid-cols-2 gap-12 items-center px-8 lg:px-24">
          <motion.img
            src="https://res.cloudinary.com/doefzwlxm/image/upload/v1739811532/ec_blinds_zbwqpa.jpg"
            alt="EC Blinds Showcase"
            className="rounded-xl shadow-xl w-full transform hover:scale-105 transition duration-500 ease-in-out"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          />

          <div>
            <ul className="space-y-6 text-lg text-gray-700">
              {[
                "Custom Window Blinds & Shades",
                "Seamless Online Ordering & Consultation",
                "Wide Range of Colors & Materials",
                "Energy-Efficient & Eco-Friendly Solutions",
                "Advanced Motorized Blinds for Smart Homes",
                "Optimized for SEO & Fast Performance",
              ].map((item, index) => (
                <motion.li
                  key={index}
                  whileHover={{ scale: 1.05, x: 10 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center text-gray-800"
                >
                  <span className="mr-3 text-blue-600 text-2xl">✔️</span>
                  {item}
                </motion.li>
              ))}
            </ul>

            <div className="mt-8">
              <a
                href="https://www.ecblinds.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-700 text-white py-3 px-8 rounded-lg shadow-md hover:bg-blue-800 transform hover:scale-105 transition duration-300"
              >
                Visit Site
              </a>
            </div>
          </div>
        </div>

        {/* Why Choose EC Blinds? Section */}
        <div className="container mx-auto mt-24 text-center px-8 lg:px-24">
          <h3 className="text-4xl font-bold text-gray-800">
            Why Choose EC Blinds?
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-10">
            {[
              "Premium-Quality Materials",
              "Professional Installation Support",
              "100% Customer Satisfaction Guarantee",
            ].map((item, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-xl p-6 hover:shadow-xl transition duration-300"
              >
                <h4 className="text-xl font-bold text-blue-700">{item}</h4>
                <p className="text-gray-700 mt-2">
                  High-quality service designed for excellence.
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Developed by Odidor */}
        <div className="mt-24 text-center bg-blue-700 text-white py-16 px-8 lg:px-24">
          <h3 className="text-4xl font-bold">Developed by Odidor</h3>
          <p className="text-lg max-w-3xl mx-auto mt-4">
            Odidor, a leading software company, developed EC Blinds' platform to
            enhance user experience and streamline business operations. Our
            expertise in web development, UX/UI design, and SEO optimization
            ensures top-tier performance.
          </p>
        </div>
      </section>
      ;{/* image  */}
      <div
        className="relative h-[60vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-blue-600 to-blue-900 text-white"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738478247/hero_bg_2_1_pbdocv.png')",
        }}
      ></div>
      {/* Odidor Project - Conclusion Section */}
      <section className="bg-gradient-to-r from-blue-50 to-blue-100 py-16 px-6 md:px-16">
        <h3 className="text-3xl md:text-4xl font-bold text-center text-blue-800 mb-8">
          Conclusion: Transforming Digital Presence with{" "}
          <span className="font-extrabold text-blue-900">Odidor</span> &amp;{" "}
          <span className="font-extrabold text-blue-900">
            <a
              href="https://www.ecblinds.com/"
              target="_blank"
              rel="noopener noreferrer"
              title="Visit EC Blinds"
              className="font-extrabold text-blue-600 hover:underline"
            >
              EC Blinds
            </a>
          </span>
        </h3>
        <div className="max-w-5xl mx-auto text-lg text-gray-700 space-y-6">
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className="font-extrabold text-blue-900">
              <Link
                to="/"
                className="font-extrabold text-blue-900 hover:underline"
              >
                Odidor
              </Link>
            </span>
            , a leading software company, developed a high-performance,
            SEO-optimized, and visually appealing website for{" "}
            <span className="font-extrabold text-blue-600">
              <a
                href="https://www.ecblinds.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit EC Blinds"
                className="font-extrabold text-blue-600 hover:underline"
              >
                EC Blinds
              </a>
            </span>
            . This project is proudly featured in the Odidor project portfolio,
            showcasing our expertise in seamless UI/UX experiences and
            fast-loading static architecture.
          </motion.p>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            The collaboration enabled a user-centric platform that allows
            customers to explore and personalize their window treatments
            effortlessly.
          </motion.p>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <span className="font-extrabold text-blue-600">
              <a
                href="https://www.ecblinds.com/"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit EC Blinds"
                className="font-extrabold text-blue-600 hover:underline"
              >
                EC Blinds
              </a>
            </span>{" "}
            seamlessly integrates cutting-edge web technologies, ensuring
            superior performance, responsiveness, and engaging UI animations
            that enhance customer experience.
          </motion.p>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            At Odidor, we are committed to delivering high-quality digital
            solutions that redefine user experience, merging innovation with
            excellence.
          </motion.p>
        </div>
      </section>
      {/* Call-to-Action Section */}
      <section className="bg-gradient-to-br from-indigo-900 to-[#9013FE] text-white py-12 text-center">
        <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 leading-tight">
          Let’s Collaborate & Drive Success Together
        </h2>
        <p className="text-lg font-light mb-8">
          Ready to elevate your business with a cutting-edge e-commerce solution
          tailored to your needs?
        </p>
        <Link
          title="contact page"
          to="/contact"
          className="bg-gradient-to-r from-indigo-500 to-[#825bf0] hover:from-indigo-800 hover:to-indigo-700 px-8 py-4 rounded-lg text-xl font-semibold shadow-lg transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center justify-center"
        >
          <span className="mr-4">✉️</span> Contact Us Now
        </Link>
      </section>
      <Footer />
    </>
  );
};

export default Ecblinds;
