import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Paintbrush, Code, FolderOpen, Image } from "lucide-react";
import { ShieldCheck, Cloud, Server } from "lucide-react";
import { UserPlus, Upload, Globe } from "lucide-react";



const Downloadhere = () => {
  return (
    <>
      <Header />

      <section className="relative w-full h-screen flex items-center justify-center text-white overflow-hidden bg-gradient-to-r from-blue-900 via-purple-800 to-blue-600">
        {/* Floating Light Effects */}
        <motion.div
          className="absolute w-72 h-72 bg-blue-500 opacity-30 rounded-full blur-3xl top-10 left-10"
          animate={{ scale: [1, 1.2, 1], opacity: [0.6, 0.8, 0.6] }}
          transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
        ></motion.div>
        <motion.div
          className="absolute w-80 h-80 bg-yellow-400 opacity-30 rounded-full blur-3xl bottom-10 right-10"
          animate={{ scale: [1, 1.3, 1], opacity: [0.5, 0.9, 0.5] }}
          transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
        ></motion.div>
        <motion.div
          className="absolute w-96 h-96 bg-purple-500 opacity-25 rounded-full blur-3xl top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          animate={{ scale: [1, 1.5, 1], opacity: [0.4, 0.8, 0.4] }}
          transition={{ duration: 6, repeat: Infinity, ease: "easeInOut" }}
        ></motion.div>

        {/* Content Container */}
        <div className="relative z-10 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl px-6 items-center">
          {/* Left Section - Text Content */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="text-left"
          >
            <h1 className="text-5xl md:text-7xl font-extrabold leading-tight text-white drop-shadow-2xl">
              Welcome to <span className="text-yellow-400">DownloadHere</span>
            </h1>
            <p className="text-lg md:text-xl text-gray-300 leading-relaxed mt-4">
              Seamless downloads with enhanced security, performance, and an
              intuitive user experience designed for various industries.
            </p>
            <div className="mt-6 flex flex-wrap gap-4">
              {[
                {
                  name: "Web App Development",
                  link: "/services/website-development/web-app-development",
                },
                {
                  name: "Cloud Integration",
                  link: "/services/enterprise-solutions/cloud-solutions",
                },
                {
                  name: "Cybersecurity",
                  link: "/services/software-development/cybersecurity-software-development",
                },
              ].map((expertise, index) => (
                <motion.a
                  key={index}
                  href={expertise.link}
                  className="bg-white/20 px-6 py-3 rounded-lg text-lg font-semibold text-white shadow-md backdrop-blur-lg border border-white/30 hover:bg-white/30 transition cursor-pointer"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {expertise.name}
                </motion.a>
              ))}
            </div>
          </motion.div>

          {/* Right Section - Image with Animation */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
            className="relative flex justify-center items-center"
          >
            <motion.img
              src="https://res.cloudinary.com/doefzwlxm/image/upload/v1741322423/theme-illustration_hkbbq7.png"
              alt="Theme Illustration"
              className="w-80 h-80 md:w-96 md:h-96 object-contain drop-shadow-lg"
              animate={{ scale: [1, 1.05, 1] }}
              transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
            />
          </motion.div>
        </div>
      </section>

      {/* Project Details Section */}
    <section className="relative bg-gradient-to-br from-blue-50 to-blue-100 py-16 px-6 lg:px-28">
      <div className="container mx-auto flex flex-col md:flex-row gap-12 items-center">
        {/* Left Content */}
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          className="flex-1"
        >
          <h3 className="text-4xl font-bold text-blue-700 mb-6 leading-tight">
            Transforming File Sharing with Download Here
          </h3>
          <p className="text-gray-700 text-lg mb-4">
            <span className="font-extrabold text-blue-500">
              <Link to="/">Odidor</Link>
            </span>{" "}
            designed a cutting-edge, secure, and high-speed file-sharing
            platform for {" "}
            <span className="font-semibold text-blue-700">
              <a
                href="https://downloadhere.app/index.php"
                target="_blank"
                rel="noopener noreferrer"
                className="font-extrabold hover:text-blue-800 transition"
              >
                Download Here
              </a>
            </span>.
          </p>

          {/* Services */}
          <h4 className="text-2xl font-semibold text-blue-700 mb-4">
            Key Technologies & Services
          </h4>
          <div className="flex flex-wrap gap-4">
            {[
              { name: 'PHP & Laravel Backend', path: '/services/website-development' },
              { name: 'React.js Frontend', path: '/services/app-development' },
              { name: 'Secure File Sharing', path: '/services/software-development/cybersecurity-software-development' },
              { name: 'Performance Optimization', path: '/services/digital-marketing/performance-optimization' },
              { name: 'Cloud Integration', path: '/services/enterprise-solutions/cloud-solutions' },
            ].map((service, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.1, backgroundColor: '#1E40AF' }}
                whileTap={{ scale: 0.95 }}
                className="px-5 py-3 bg-blue-500 border border-blue-600 rounded-full text-sm font-medium cursor-pointer shadow-md hover:shadow-lg transition-all text-white"
              >
                <Link to={service.path}>{service.name}</Link>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Right Content */}
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          className="flex-1"
        >
          <div className="bg-white p-6 rounded-lg shadow-xl border-l-4 border-blue-500">
            <h2 className="text-3xl font-extrabold text-blue-700 mb-6">
              Project Highlights
            </h2>
            <table className="w-full text-left text-gray-900">
              <tbody>
                {["Frontend Technology: React.js, Tailwind CSS", "Backend Framework: PHP, Laravel", "Cloud Services: AWS, Firebase", "Security: Encrypted File Storage, SSL, OAuth2", "Performance Score: 99+ (Lighthouse)", "Timeline: 10-14 Weeks", "Launch Date: March 2024"].map((item, index) => {
                  const [label, value] = item.split(": ");
                  return (
                    <tr key={index} className="border-b last:border-none">
                      <td className="py-3 font-medium text-gray-700">{label}</td>
                      <td className="py-3 text-blue-600 font-semibold">{value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="mt-6 text-center">
              <a
                href="https://downloadhere.app/index.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-blue-600 text-white font-bold px-6 py-3 rounded-lg hover:bg-blue-700 transition duration-300"
                >
                  Visit Download Here
                </motion.button>
              </a>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Right Image */}
      <motion.img
        src="https://res.cloudinary.com/doefzwlxm/image/upload/v1741322423/theme-illustration_hkbbq7.png"
        alt="Illustration"
        className="absolute right-0 bottom-0 w-60 opacity-80 hidden lg:block"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
      />
    </section>
    <section className="w-full bg-gradient-to-b from-gray-50 to-gray-100 py-20">
      {/* Header Section */}
      <div className="container mx-auto px-8 lg:px-24 text-center">
        <h2 className="text-5xl font-extrabold text-gray-900 leading-tight">
          The Ultimate Platform for
          <span className="text-blue-600"> Seamless Downloads</span>
        </h2>
        <p className="text-lg text-gray-700 mt-4 max-w-3xl mx-auto">
          DownloadHere simplifies the way you access and manage digital files.
          A cutting-edge platform designed for speed, security, and user-friendly
          downloads, ensuring a hassle-free experience every time.
        </p>
      </div>

      {/* Feature Section */}
      <div className="container mx-auto mt-16 grid grid-cols-1 lg:grid-cols-2 gap-12 items-center px-8 lg:px-24">
        <motion.img
          src="https://res.cloudinary.com/doefzwlxm/image/upload/v1741671279/downloadHere_2_hhnjvn.jpg"
          alt="DownloadHere Platform"
          className="rounded-xl shadow-xl w-full transform hover:scale-105 transition duration-500 ease-in-out"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
        />

        <div>
          <ul className="space-y-6 text-lg text-gray-700">
            {[ 
              "Fast & Secure Downloads Anytime, Anywhere", 
              "Intuitive & User-Friendly Interface", 
              "Supports a Wide Range of File Formats", 
              "Cloud-Based for Seamless Access Across Devices", 
              "No Interruptions – Smooth and Reliable Performance",
              "Integrated Advanced Search for Quick Access", 
              "Optimized for SEO & Lightning-Fast Speed" 
            ].map((item, index) => (
              <motion.li
                key={index}
                whileHover={{ scale: 1.05, x: 10 }}
                transition={{ duration: 0.3 }}
                className="flex items-center text-gray-900"
              >
                <span className="mr-3 text-blue-600 text-2xl">✔️</span>
                {item}
              </motion.li>
            ))}
          </ul>

          <div className="mt-8">
            <a
              href="https://downloadhere.app/index.php"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-600 text-white py-3 px-8 rounded-lg shadow-md hover:bg-blue-700 transform hover:scale-105 transition duration-300"
            >
              Visit DownloadHere
            </a>
          </div>
        </div>
      </div>

      {/* Why Choose DownloadHere? */}
      <div className="container mx-auto mt-24 text-center px-8 lg:px-24">
        <h3 className="text-4xl font-bold text-gray-900">Why Choose DownloadHere?</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-10">
          {[
            "Lightning-Fast Performance", 
            "100% Secure & Private Downloads", 
            "Optimized for All Devices & Platforms"
          ].map((item, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-xl p-6 hover:shadow-xl transition duration-300"
            >
              <h4 className="text-xl font-bold text-blue-600">{item}</h4>
              <p className="text-gray-700 mt-2">Built for efficiency and reliability.</p>
            </div>
          ))}
        </div>
      </div>

      {/* Developed by Odidor */}
      <div className="mt-24 text-center bg-blue-600 text-white py-16 px-8 lg:px-24">
        <h3 className="text-4xl font-bold">Developed by Odidor</h3>
        <p className="text-lg max-w-3xl mx-auto mt-4">
          DownloadHere is a testament to Odidor’s expertise in software
          development, UI/UX design, and cloud solutions. We ensure seamless
          performance, top-tier security, and an intuitive experience for all users.
        </p>
      </div>
    </section>

    <section className="py-16 bg-gradient-to-br from-blue-900 via-purple-800 to-blue-600 text-white">
      <div className="max-w-6xl mx-auto px-6 text-center">
        
        {/* Title Section */}
        <motion.h2 
          className="text-4xl font-bold mb-6 tracking-wide text-blue-300"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          DownloadHere - Secure & Scalable Cloud Storage
        </motion.h2>
        <motion.p 
          className="text-lg max-w-3xl mx-auto text-gray-200"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.2 }}
        >
          Experience high-performance file management with top-tier security and seamless scalability, powered by AWS technologies.
        </motion.p>

        {/* Feature Cards Container */}
        <div className="grid md:grid-cols-2 gap-10 mt-12">
          
          {/* S3 Bucket Encryption */}
          <motion.div 
            className="bg-white/10 backdrop-blur-lg rounded-2xl shadow-lg p-8 text-left border border-white/20 hover:shadow-2xl hover:scale-[1.02] transition-all duration-300"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <div className="flex items-center space-x-4 mb-4">
              <ShieldCheck className="w-10 h-10 text-yellow-400" />
              <h3 className="text-2xl font-semibold text-white">S3 Bucket Encryption</h3>
            </div>
            <p className="text-gray-300">
              We utilize <strong className="text-yellow-400">AES-256 encryption</strong> to safeguard your data, ensuring every file is protected from unauthorized access.
            </p>
            <ul className="mt-4 space-y-2 text-gray-200">
              <li className="flex items-center"><Cloud className="w-5 h-5 mr-2 text-yellow-300"/> Automatic encryption on upload</li>
              <li className="flex items-center"><Cloud className="w-5 h-5 mr-2 text-yellow-300"/> Secure access with role-based policies</li>
              <li className="flex items-center"><Cloud className="w-5 h-5 mr-2 text-yellow-300"/> Versioning support to prevent data loss</li>
            </ul>
          </motion.div>

          {/* EC2 Instance Usage */}
          <motion.div 
            className="bg-white/10 backdrop-blur-lg rounded-2xl shadow-lg p-8 text-left border border-white/20 hover:shadow-2xl hover:scale-[1.02] transition-all duration-300"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <div className="flex items-center space-x-4 mb-4">
              <Server className="w-10 h-10 text-green-400" />
              <h3 className="text-2xl font-semibold text-white">EC2 Instance Performance</h3>
            </div>
            <p className="text-gray-300">
              Our AWS EC2 instances ensure high availability, optimized compute performance, and seamless scalability.
            </p>
            <ul className="mt-4 space-y-2 text-gray-200">
              <li className="flex items-center"><Cloud className="w-5 h-5 mr-2 text-green-300"/> Auto-scaling for high traffic</li>
              <li className="flex items-center"><Cloud className="w-5 h-5 mr-2 text-green-300"/> Optimized for fast file access</li>
              <li className="flex items-center"><Cloud className="w-5 h-5 mr-2 text-green-300"/> Secure firewalls & access control</li>
            </ul>
          </motion.div>

        </div>
      </div>
    </section>

   

    <section className="w-full bg-gradient-to-b from-gray-50 to-gray-200 py-20">
      {/* Hero Section */}
      <div className="container mx-auto px-8 lg:px-24 text-center">
        <h2 className="text-5xl font-extrabold text-gray-800 leading-tight">
          Discover <span className="text-blue-600">DownloadHere</span>
        </h2>
        <p className="text-lg text-gray-700 mt-4 max-w-3xl mx-auto">
          Your ultimate destination for high-quality graphics, powerful dev tools, innovative projects, and premium stock resources. DownloadHere, developed by Odidor, offers seamless access to digital assets that empower creativity and efficiency.
        </p>
      </div>
      
      {/* Feature Sections */}
      <div className="container mx-auto mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 px-8 lg:px-24">
  {[
    { title: "Graphics", desc: "High-quality design assets, illustrations, and templates for your creative needs.", icon: <Paintbrush className="w-16 h-16 text-blue-600" /> },
    { title: "Dev Tools", desc: "Essential tools and resources for developers to streamline coding and software development.", icon: <Code className="w-16 h-16 text-blue-600" /> },
    { title: "Projects", desc: "A curated collection of open-source and premium projects for inspiration and use.", icon: <FolderOpen className="w-16 h-16 text-blue-600" /> },
    { title: "Stock", desc: "Premium stock images, videos, and audio to enhance your content creation.", icon: <Image className="w-16 h-16 text-blue-600" /> },
  ].map((item, index) => (
    <motion.div
      key={index}
      className="bg-white shadow-lg rounded-xl p-6 text-center hover:shadow-xl transition duration-300"
      whileHover={{ scale: 1.05 }}
    >
      <div className="flex justify-center mb-4">{item.icon}</div>
      <h3 className="text-2xl font-bold text-blue-600">{item.title}</h3>
      <p className="text-gray-700 mt-2">{item.desc}</p>
    </motion.div>
  ))}
</div>


      {/* Call to Action */}
      <div className="mt-16 text-center">
        <a
          href="https://downloadhere.app/index.php"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-blue-600 text-white py-3 px-8 rounded-lg shadow-md hover:bg-blue-700 transform hover:scale-105 transition duration-300"
        >
          Visit DownloadHere
        </a>
      </div>

    
    </section>




    <section className="py-16 bg-gradient-to-br from-blue-900 via-purple-800 to-blue-600 text-white">
      <div className="max-w-6xl mx-auto px-6 text-center">
        
        {/* Title Section */}
        <motion.h2 
          className="text-4xl font-bold mb-6 tracking-wide text-blue-300"
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          Contribute & Publish Your Assets
        </motion.h2>
        <motion.p 
          className="text-lg max-w-3xl mx-auto text-gray-200"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.2 }}
        >
          Join our growing community! Sign up as a contributor, upload your assets, and make them available to the world.
        </motion.p>

        {/* Feature Cards */}
        <div className="grid md:grid-cols-3 gap-10 mt-12">
          
          {/* Sign Up & Join */}
          <motion.div 
            className="bg-white/10 backdrop-blur-lg rounded-2xl shadow-lg p-8 text-left border border-white/20 hover:shadow-2xl hover:scale-[1.02] transition-all duration-300"
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <div className="flex items-center space-x-4 mb-4">
              <UserPlus className="w-10 h-10 text-yellow-400" />
              <h3 className="text-2xl font-semibold text-white">Sign Up & Contribute</h3>
            </div>
            <p className="text-gray-300">
              Create an account and unlock the ability to share your digital assets securely.
            </p>
            <ul className="mt-4 space-y-2 text-gray-200">
              <li className="flex items-center"><UserPlus className="w-5 h-5 mr-2 text-yellow-300"/> Easy contributor registration</li>
              <li className="flex items-center"><UserPlus className="w-5 h-5 mr-2 text-yellow-300"/> Secure profile & asset management</li>
              <li className="flex items-center"><UserPlus className="w-5 h-5 mr-2 text-yellow-300"/> Exclusive access to publishing tools</li>
            </ul>
          </motion.div>

          {/* Upload & Manage Assets */}
          <motion.div 
            className="bg-white/10 backdrop-blur-lg rounded-2xl shadow-lg p-8 text-left border border-white/20 hover:shadow-2xl hover:scale-[1.02] transition-all duration-300"
            initial={{ opacity: 0, x: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <div className="flex items-center space-x-4 mb-4">
              <Upload className="w-10 h-10 text-green-400" />
              <h3 className="text-2xl font-semibold text-white">Upload & Manage</h3>
            </div>
            <p className="text-gray-300">
              Easily upload, edit, and organize your assets with our intuitive dashboard.
            </p>
            <ul className="mt-4 space-y-2 text-gray-200">
              <li className="flex items-center"><Upload className="w-5 h-5 mr-2 text-green-300"/> Drag & drop file uploads</li>
              <li className="flex items-center"><Upload className="w-5 h-5 mr-2 text-green-300"/> Asset version control</li>
              <li className="flex items-center"><Upload className="w-5 h-5 mr-2 text-green-300"/> Secure storage & encryption</li>
            </ul>
          </motion.div>

          {/* Publish & Share */}
          <motion.div 
            className="bg-white/10 backdrop-blur-lg rounded-2xl shadow-lg p-8 text-left border border-white/20 hover:shadow-2xl hover:scale-[1.02] transition-all duration-300"
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <div className="flex items-center space-x-4 mb-4">
              <Globe className="w-10 h-10 text-blue-400" />
              <h3 className="text-2xl font-semibold text-white">Publish & Go Live</h3>
            </div>
            <p className="text-gray-300">
              Share your work with the world and reach a global audience instantly.
            </p>
            <ul className="mt-4 space-y-2 text-gray-200">
              <li className="flex items-center"><Globe className="w-5 h-5 mr-2 text-blue-300"/> Instant asset publishing</li>
              <li className="flex items-center"><Globe className="w-5 h-5 mr-2 text-blue-300"/> Share with a wider audience</li>
              <li className="flex items-center"><Globe className="w-5 h-5 mr-2 text-blue-300"/> Analytics & insights</li>
            </ul>
          </motion.div>

        </div>

        {/* CTA Button */}
        <motion.div 
          className="mt-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.3 }}
        >
          <a 
            href="https://downloadhere.app/index.php" 
            className="px-6 py-3 bg-yellow-400 text-black font-semibold rounded-full text-lg hover:bg-yellow-300 transition-all duration-300 shadow-md"
          >
            Visit DownloadHere & Contribute
          </a>
        </motion.div>

      </div>
    </section>
  <section className="py-16 px-6 md:px-16 bg-gradient-to-r from-blue-600 to-blue-900 text-white relative">
      <div className="max-w-6xl mx-auto text-center">
        <h3 className="text-4xl font-bold mb-6">
          Transforming Digital Downloads with
          <span className="text-yellow-300"> DownloadHere</span>
        </h3>
        <p className="text-lg mb-6">
          <span className="font-bold text-yellow-300">
            <Link to="https://downloadhere.app/index.php" className="hover:underline">
              DownloadHere
            </Link>
          </span>{" "}
          is revolutionizing digital downloads by offering a seamless, secure, and high-speed platform to access essential files, software, and media. Designed for efficiency, it ensures a smooth experience for users worldwide.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
            <h4 className="text-xl font-bold mb-2 text-blue-700">Fast & Reliable</h4>
            <p className="text-md text-gray-700">Experience lightning-fast downloads with optimized speed and performance.</p>
          </div>
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
            <h4 className="text-xl font-bold mb-2 text-green-600">Secure & Safe</h4>
            <p className="text-md text-gray-700">Your downloads are protected with top-tier security protocols, ensuring data integrity.</p>
          </div>
          <div className="bg-gray-900 text p-6 rounded-lg shadow-lg">
            <h4 className="text-xl font-bold mb-2 text-red-600">User-Friendly</h4>
            <p className="text-md text-gray-700">A seamless and intuitive interface makes finding and downloading files effortless.</p>
          </div>
        </div>
        <p className="text-lg mt-8 font-semibold">
          Developed by <span className="font-bold text-yellow-300">Odidor</span>, DownloadHere redefines the digital download landscape, bringing innovation and reliability to users globally.
        </p>
      </div>
      {/* Right Section - Image */}
      <div className="absolute right-0 bottom-0 w-72 hidden lg:block">
        <img
          src="https://res.cloudinary.com/doefzwlxm/image/upload/v1741325213/08_po44cw.png"
          alt="DownloadHere Preview"
          className="w-full h-auto opacity-90"
        />
      </div>
    </section>

      <section className="py-16 px-6 md:px-16 bg-gray-100 relative">
      <div className="max-w-4xl mx-auto text-center">
        <h3 className="text-4xl font-bold text-gray-900 mb-6">
          Elevating Digital Downloads with
          <span className="text-blue-600"> DownloadHere</span>
        </h3>
        <p className="text-lg text-gray-700 mb-4">
          <span className="font-bold text-blue-600">
            <Link to="https://downloadhere.app/index.php" className="hover:underline">
              DownloadHere
            </Link>
          </span>{" "}
          revolutionizes the way users access digital content, providing a seamless, fast, and secure platform for all download needs. Whether you're looking for essential software, creative assets, or entertainment files, DownloadHere ensures a hassle-free experience.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          Developed by <span className="font-bold text-blue-600">Odidor</span>, this cutting-edge platform integrates advanced technology to optimize download speeds, enhance security, and improve user convenience. With an intuitive interface and robust infrastructure, DownloadHere is the ultimate destination for hassle-free digital downloads.
        </p>
        <p className="text-lg text-gray-700 mb-4">
          From professionals to everyday users, DownloadHere caters to diverse needs, ensuring that files are delivered quickly, efficiently, and safely. Our commitment to innovation guarantees an ever-evolving platform that keeps up with the latest in digital distribution.
        </p>
        <p className="text-lg text-gray-700">
          Experience the future of digital downloads with DownloadHere – where speed, security, and simplicity come together to redefine the way you access content.
        </p>
      </div>
      {/* Right Section - Image */}
      <div className="absolute right-0 bottom-0 w-60 hidden lg:block">
        <img
          src="https://res.cloudinary.com/doefzwlxm/image/upload/v1741322423/theme-illustration_hkbbq7.png"
          alt="DownloadHere Illustration"
          className="w-full h-auto opacity-80"
        />
      </div>
    </section>
     {/* Call-to-Action Section */}
     <section className="bg-gradient-to-br from-indigo-900 to-[#9013FE] text-white py-12 text-center">
        <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 leading-tight">
          Let’s Collaborate & Drive Success Together
        </h2>
        <p className="text-lg font-light mb-8">
          Ready to elevate your business with a cutting-edge e-commerce solution
          tailored to your needs?
        </p>
        <Link
          title="contact page"
          to="/contact"
          className="bg-gradient-to-r from-indigo-500 to-[#825bf0] hover:from-indigo-800 hover:to-indigo-700 px-8 py-4 rounded-lg text-xl font-semibold shadow-lg transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center justify-center"
        >
          <span className="mr-4">✉️</span> Contact Us Now
        </Link>
      </section>
      <Footer />
    </>
  );
};

export default Downloadhere;
