import React from "react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

// Mobile Hero Section
const HeroSectionMobile = () => {
  return (
    <section className="relative flex flex-col-reverse md:flex-row items-center justify-between px-6 md:px-12 lg:px-24 py-20 md:py-32 bg-black text-white overflow-hidden">
      
    {/* Background Glare Effect */}
    <div className="absolute inset-0 bg-gradient-to-r from-indigo-900/40 to-purple-800/30 opacity-50 blur-3xl pointer-events-none"></div>

    {/* Left Content */}
    <motion.div 
      className="text-center md:text-left max-w-3xl space-y-6 z-10"
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 1 }}
    >
      <h1 className="text-4xl sm:text-5xl md:text-7xl font-extrabold leading-tight text-white drop-shadow-lg">
        Boosting Growth <br className="hidden sm:block" /> Across Industries
      </h1>
      <p className="text-lg sm:text-xl md:text-2xl text-gray-300">
        Serving a variety of industries and making a significant impact in each.
      </p>
      <a 
        href="#contact" 
        className="inline-block px-6 py-3 text-lg sm:text-xl font-semibold rounded-lg bg-indigo-600 hover:bg-indigo-500 transition-all duration-300 shadow-xl"
      >
        Let’s Talk →
      </a>
    </motion.div>

    {/* Right Content - Large Number */}
    <motion.div 
      className="absolute bottom-6 sm:bottom-12 md:bottom-16 right-6 sm:right-12 md:right-20 text-right z-10"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1.2 }}
    >
      <span className="text-6xl sm:text-8xl md:text-[12rem] font-extrabold text-indigo-400 drop-shadow-2xl">
        20+
      </span>
    </motion.div>
  </section>
  );
};

// Desktop Hero Section
const HeroSectionDesktop = () => {
  return (
    <section className="relative flex flex-row items-center justify-between px-12 lg:px-24 py-32 bg-black text-white overflow-hidden">
      
      {/* Background Glow */}
      <div className="absolute inset-0 bg-gradient-to-r from-indigo-900/40 to-purple-800/30 opacity-50 blur-3xl pointer-events-none"></div>

      {/* Left Content */}
      <motion.div 
        className="text-left max-w-3xl space-y-6 relative z-10"
        initial={{ opacity: 0, x: -60 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-5xl md:text-8xl font-extrabold leading-tight bg-gradient-to-r from-indigo-400 to-purple-400 text-transparent bg-clip-text drop-shadow-2xl">
          Empowering <br /> Industries with Innovation
        </h1>
        <p className="text-2xl text-gray-300">
          Delivering excellence across multiple industries with cutting-edge solutions.
        </p>
        <a 
          href="#contact" 
          className="inline-block px-10 py-4 text-xl font-semibold rounded-lg bg-indigo-600 hover:bg-indigo-500 transition-all duration-300 shadow-2xl relative overflow-hidden"
        >
          Get Started →
        </a>
      </motion.div>

      {/* Right Content - Large Number & Effects */}
      <motion.div 
        className="absolute bottom-16 right-16 lg:bottom-20 lg:right-32 text-right z-10"
        initial={{ opacity: 0, scale: 0.7 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1.2 }}
      >
        <span className="text-9xl md:text-[14rem] font-extrabold text-indigo-500 drop-shadow-[0_0_80px_rgba(99,102,241,0.8)] animate-pulse">
          20+
        </span>
      </motion.div>

      {/* Extra Desktop Effects */}
      <div className="absolute top-1/3 left-1/4 w-80 h-80 bg-indigo-400/20 rounded-full blur-3xl"></div>
      <div className="absolute bottom-1/4 right-1/3 w-64 h-64 bg-purple-400/20 rounded-full blur-3xl"></div>
    </section>
  );
};

// Main Hero Component with Conditional Rendering
const HeroSection = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // Mobile = width < 1024px
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile ? <HeroSectionMobile /> : <HeroSectionDesktop />;
};

export default HeroSection;
