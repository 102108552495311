// src/constants/constants.js

export const industries = [
  "Automobile",
  "Beauty",
  "Construction",
  "Corporate",
  "Education",
  "Electronics",
  "Engineering Services",
  "Entertainment",
  "Event Management",
  "Fashion",
  "Financial",
  "Food",
  "Healthcare",
  "Hospitality",
  "Information Technology",
  "Manufacturing",
  "Media",
  "Non-Profit Organisation",
  "Real Estate",
  "Retail",
  "Social",
  "Travel and Tourism",
  " Food & Beverage",
  "Healthcare",
  "Medical"

  ];
  
  
  export const expertise = [
    "Branding",
    "UI/UX Design",
    "Web Development",
    "Corporate Website",
    "E-commerce",
    "Web App Development",
    "Mobile App Development",
    "Digital Marketing",
    "Search Engine Optimisation",
    "Content Management System (CMS)",
    "Data & Analytics",
    "Cloud Services",
    "2D / 3D Visualisation",
    "SEO Optimization",
  ];
  
  export const projects = [
    {
      id: 1,
      image: "https://res.cloudinary.com/dhhsupcai/image/upload/v1737527692/White_and_Black_Modern_Laptop_Unboxing_Youtube_Thumbnail_yt2bit.jpg",
      projectName: "Specialty Window Blinds Website",
      shortDescription:
        "A modern, responsive e-commerce platform designed for premium window blinds and shades, offering an intuitive user experience.",
      industries: ["E-commerce", "Construction", "Retail"],
      expertise: ["UI/UX Design", "Web Development", "SEO Optimization"],
      route: "/projects/specialty-window-blinds", 
    },
    {
      "id": 9,
      "image": "https://res.cloudinary.com/doefzwlxm/image/upload/v1739811532/ec_blinds_zbwqpa.jpg",
      "projectName": "EC Blinds",
      "shortDescription": "EC Blinds is a leading provider of custom window coverings, offering high-quality blinds, shades, and shutters. With a focus on personalized service, they ensure precise measurements, professional installation, and exceptional designs for homes and businesses.",
      "industries": ["Retail", "Construction", "Real Estate"],
      "expertise": ["E-Commerce Development", "Custom UI/UX", "Performance Optimization"],
      "route": "/projects/ec-blinds"
    },    
    {
      id: 2,
      image: "https://res.cloudinary.com/doefzwlxm/image/upload/v1738175348/sky_line_1_iqqv2l.jpg",
      projectName: "JC Truck & Trailer Repair Ltd",
      shortDescription:
        "A modern, responsive static website showcasing premium window blinds and shades, with a focus on aesthetics, user-friendly navigation, and informative content.",
      industries: ["Automobile", "Engineering Services"],
      expertise: ["Web Development", "SEO Optimization", "Digital Marketing"],
      route: "/projects/jcrepair",
    },
   
    {
      id: 3,
      image: "https://res.cloudinary.com/dhhsupcai/image/upload/v1737611066/Masterclass_Online_Course_Laptop_Instagram_Post_mzohzk.jpg",
      projectName: "Big Brands Blinds Website",
      shortDescription:
        "A modern, responsive static website showcasing premium window blinds and shades, with a focus on aesthetics, user-friendly navigation, and informative content.",
      industries: ["Construction", "Retail", "Real Estate"],
      expertise: ["Web Development", "SEO Optimization", "Digital Marketing"],
      route: "/projects/big-brands-blinds",
    },
    {
      "id": 4,
      "image": "https://res.cloudinary.com/doefzwlxm/image/upload/v1738479300/pizza_gi6q3i.jpg",
      "projectName": "Paulo's Pizza",
      "shortDescription": "A full-stack website for Paulo's Pizza, featuring a custom CMS for easy content management, seamless online ordering, and an intuitive user experience.",
      "industries": ["Food & Beverage", "Hospitality", "Retail","Food"],
      "expertise": ["POS System", "CMS Development", "SEO Optimization"],
      "route": "/projects/paulos-pizza"
    },
    {
      "id": 5,
      "image": "https://res.cloudinary.com/doefzwlxm/image/upload/v1738831208/Update_Medical_Dental_ej9uyl.jpg",
      "projectName": "Update Medical Dental Polyclinic",
      "shortDescription": "A modern, user-friendly website developed for Update Medical Dental Polyclinic, offering seamless navigation, comprehensive services for patients, and a robust POS system for smooth operations.",
      "industries": ["Healthcare", "Medical"],
      "expertise": ["Web Development", "Custom CMS", "POS System", "SEO Optimization", "Responsive Design"],
      "route": "/projects/update-medical-dental-polyclinic"
    },
    {
      id: 6,
      image: "https://res.cloudinary.com/dhhsupcai/image/upload/v1737718266/sky_line_mmkcoz.jpg",
      projectName: "Skyline Shades Website",
      shortDescription:
        "A visually stunning, responsive static website for Skyline Shades, highlighting an exclusive range of premium window blinds and shades. Built for seamless navigation, captivating visuals, and detailed product information to enhance user engagement.",
      industries: ["Construction", "Retail", "Real Estate"],
      expertise: ["Web Development", "SEO Optimization", "Digital Marketing"],
      route: "/projects/skyline-shades",
    },
    {
      "id": 7,
      "image": "https://res.cloudinary.com/doefzwlxm/image/upload/v1739205834/tda_qlwpio.jpg",
      "projectName": "The Detailing Artists (TDA)",
      "shortDescription": "A cutting-edge website designed for The Detailing Artists, providing a seamless digital experience for showcasing premium auto detailing services with high-quality visuals and an intuitive booking system.",
      "industries": ["Automobile", "Car Detailing"],
      "expertise": ["Web Development", "SEO Optimization", "Responsive Design"],
      "route": "/projects/the-detailing-artists"
    },
    {
      "id": 8,
      "image": "https://res.cloudinary.com/doefzwlxm/image/upload/v1739213145/_Blinds_pgv8uh.jpg",
      "projectName": "HiTech Blinds",
      "shortDescription": "A state-of-the-art website built for HiTech Blinds, providing an intuitive platform to explore high-quality window blinds, including motorized and manual options, with a seamless browsing and purchasing experience.",
      "industries": ["Construction", "Corporate", "Real Estate", "Retail"],
      "expertise": ["Web Development", "SEO Optimization", "Responsive Design"],
      "route": "/projects/hitech-blind"
    },
    {
      "id": 9,
      "image": "https://res.cloudinary.com/doefzwlxm/image/upload/v1741671279/downloadHere_2_hhnjvn.jpg",
      "projectName": "DownloadHere",
      "shortDescription": "A cutting-edge digital platform offering seamless file downloads with enhanced security, optimized performance, and an intuitive user experience tailored for various industries.",
      "industries": ["Technology", "Software", "E-Commerce", "Digital Services"],
      "expertise": ["Web Application Development", "Cloud Integration", "Cybersecurity Optimization"],
      "route": "/projects/downloadhere"
    },
    {
      "id": 10,
      "image": "https://res.cloudinary.com/doefzwlxm/image/upload/v1742321278/beyond_bloom_odidor_slcrpa.jpg",
      "projectName": "BeyondBloom",
      "shortDescription": "A premium small-batch craft cannabis producer committed to cultivating excellence in every bud, offering unparalleled products to partners.",
      "industries": ["Retail", "Healthcare", "Food & Beverage"],
      "expertise": ["Branding", "UI/UX Design", "E-commerce", "Web App Development", "SEO Optimization", "Digital Marketing"],
       "route": "/projects/beyondbloom"
    }
    
  ];

  
  

// Constants for modal data
export const BRANDS = [
  "https://specialtywindowblinds.com/img/logo.png",
];

export const STATS = [
  { label: "700+", description: "Projects Delivered that Exceeded Expectations" },
  { label: "12+", description: "Years of Expertise Across Various Industries" },
  { label: "450+", description: "Collective Minds Constantly Aiming for Excellence" },
];
