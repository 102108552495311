import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

const expertiseList = [
  { name: "Branding", link: "/services/digital-marketing" },
  { name: "UI/UX Design", link: "/services/website-development/ui/ux-design" },
  {
    name: "E-Commerce",
    link: "/services/website-development/ecommerce-web-development",
  },
  {
    name: "Web App Development",
    link: "/services/website-development/web-app-development",
  },
  {
    name: "SEO Optimization",
    link: "/services/digital-marketing/seo-services",
  },
  { name: "Digital Marketing", link: "/services/digital-marketing" },
];

const Beyondbloom = () => {
  return (
    <>
      <Header />

      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[60vh] sm:h-[70vh] flex items-center justify-center"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1742321829/hero2_ko0pn7.jpg')",
        }}
      >
        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-black/80"></div>

        {/* Content */}
        <div className="relative z-10 text-center px-4">
          <motion.h1
            className="text-5xl sm:text-6xl font-extrabold mb-4 leading-tight text-green-400 drop-shadow-lg"
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            BeyondBloom
          </motion.h1>
          <motion.h2
            className="text-lg sm:text-xl font-medium mb-6 text-gray-300"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9, delay: 0.2 }}
          >
            Elevating the craft of small-batch cannabis through passion,
            precision, and innovation.
          </motion.h2>

          {/* Expertise Inside Hero */}
          <motion.div
            className="mt-4 flex flex-wrap justify-center gap-4"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0, y: 30 },
              visible: {
                opacity: 1,
                y: 0,
                transition: { staggerChildren: 0.2, duration: 0.8 },
              },
            }}
          >
            {expertiseList.map((expertise, index) => (
              <motion.div
                key={index}
                className="bg-gray-800 px-4 py-2 rounded-full shadow-md text-white text-sm sm:text-base font-semibold transition transform hover:scale-105 hover:bg-green-500"
                whileHover={{ scale: 1.08 }}
              >
                <Link
                  to={expertise.link}
                  className="text-green-400 hover:text-white transition"
                >
                  {expertise.name}
                </Link>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
      {/* Project Details Section */}
      <section className="relative bg-black py-16 px-6 lg:px-28">
        <div className="container mx-auto flex flex-col md:flex-row gap-12 items-center">
          {/* Left Content */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="flex-1"
          >
            <h3 className="text-4xl font-bold text-green-400 mb-6 leading-tight">
              Beyond Bloom: A Next-Gen Cannabis Experience
            </h3>
            <p className="text-gray-300 text-lg mb-4">
              <span className="font-extrabold text-green-400">
                <Link to="/">Odidor</Link>
              </span>{" "}
              developed an immersive and modern web experience for{" "}
              <span className="font-semibold text-green-500">
                <a
                  href="https://beyond-bloom.odidor.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-extrabold hover:text-green-300 transition"
                >
                  Beyond Bloom
                </a>
              </span>
              , a premium cannabis brand redefining the industry with a blend of
              art, science, and technology.
            </p>

            {/* Services */}
            <h4 className="text-2xl font-semibold text-green-400 mb-4">
              Key Technologies & Services
            </h4>
            <div className="flex flex-wrap gap-4">
              {[
                {
                  name: "React.js Frontend",
                  path: "/services/website-development",
                },
                {
                  name: "Tailwind CSS for Styling",
                  path: "/services/website-development/ui/ux-design",
                },
                {
                  name: "Framer Motion Animations",
                  path: "/services/website-development/ui/ux-design",
                },
                {
                  name: "Dark-Themed UI/UX",
                  path: "/services/website-development/ui/ux-design",
                },
                {
                  name: "SEO & Performance Optimization",
                  path: "/services/digital-marketing/performance-optimization",
                },
              ].map((service, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.1, backgroundColor: "#15803D" }}
                  whileTap={{ scale: 0.95 }}
                  className="px-5 py-3 bg-green-500 border border-green-600 rounded-full text-sm font-medium cursor-pointer shadow-md hover:shadow-lg transition-all text-black"
                >
                  <Link to={service.path}>{service.name}</Link>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Right Content */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="flex-1"
          >
            <div className="bg-gray-900 p-6 rounded-lg shadow-xl border-l-4 border-green-500">
              <h2 className="text-3xl font-extrabold text-green-400 mb-6">
                Project Highlights
              </h2>
              <table className="w-full text-left text-gray-200">
                <tbody>
                  {[
                    "Frontend Technology: React.js, Tailwind CSS",
                    "UI Framework: Framer Motion, Headless UI",
                    "SEO & Optimization: High-Speed Rendering",
                    "Performance Score: 98+ (Lighthouse)",
                    "Development Time: 6-7 Weeks",
                    "Launch Date: February 2025",
                  ].map((item, index) => {
                    const [label, value] = item.split(": ");
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-700 last:border-none"
                      >
                        <td className="py-3 font-medium text-gray-400">
                          {label}
                        </td>
                        <td className="py-3 text-green-300 font-semibold">
                          {value}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="mt-6 text-center">
                <a
                  href="https://beyond-bloom.odidor.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-green-500 text-black font-bold px-6 py-3 rounded-lg hover:bg-green-400 transition duration-300"
                  >
                    Visit Beyond Bloom
                  </motion.button>
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
      <section className="w-full bg-black text-white py-20">
        {/* Header Section */}
        <div className="container mx-auto px-8 lg:px-24 text-center">
          <h2 className="text-5xl font-extrabold leading-tight text-green-400">
            Beyond Bloom: Elevate Your Wellness
          </h2>
          <p className="text-lg text-gray-300 mt-4 max-w-3xl mx-auto">
            Experience the future of holistic wellness with Beyond Bloom. A
            revolutionary platform designed to empower your journey towards
            better health, sustainability, and mindful living.
          </p>
        </div>

        {/* Feature Section */}
        <div className="container mx-auto mt-16 grid grid-cols-1 lg:grid-cols-2 gap-12 items-center px-8 lg:px-24">
          <motion.img
            src="https://res.cloudinary.com/doefzwlxm/image/upload/v1742321322/catlog_1_1_wo5djz.jpg"
            alt="Beyond Bloom Platform"
            className="rounded-xl shadow-xl w-full transform hover:scale-105 transition duration-500 ease-in-out"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          />

          <div>
            <ul className="space-y-6 text-lg text-gray-300">
              {[
                "Personalized Wellness Insights",
                "Sustainable and Eco-Friendly Approach",
                "Seamless User Experience Across Devices",
                "Advanced AI-driven Health Recommendations",
                "Privacy-First, Secure, and Reliable",
                "Effortless Community Engagement & Support",
                "Optimized Performance for Fast & Intuitive Navigation",
              ].map((item, index) => (
                <motion.li
                  key={index}
                  whileHover={{ scale: 1.05, x: 10 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center text-white"
                >
                  <span className="mr-3 text-green-400 text-2xl">✔️</span>
                  {item}
                </motion.li>
              ))}
            </ul>

            <div className="mt-8">
              <a
                href="https://beyond-bloom.odidor.co/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-green-500 text-black py-3 px-8 rounded-lg shadow-md hover:bg-green-400 transform hover:scale-105 transition duration-300"
              >
                Explore Beyond Bloom
              </a>
            </div>
          </div>
        </div>

        {/* Why Choose Beyond Bloom? */}
        <div className="container mx-auto mt-24 text-center px-8 lg:px-24">
          <h3 className="text-4xl font-bold text-green-400">
            Why Choose Beyond Bloom?
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-10">
            {[
              "Science-Backed Wellness Solutions",
              "Sustainable & Ethical Innovation",
              "AI-Powered Personalization",
            ].map((item, index) => (
              <div
                key={index}
                className="bg-gray-900 shadow-lg rounded-xl p-6 hover:shadow-xl transition duration-300"
              >
                <h4 className="text-xl font-bold text-green-400">{item}</h4>
                <p className="text-gray-300 mt-2">
                  Designed to enhance your wellbeing.
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Developed by Odidor */}
        <div className="mt-24 text-center bg-green-500 text-black py-16 px-8 lg:px-24">
          <h3 className="text-4xl font-bold">Developed by Odidor</h3>
          <p className="text-lg max-w-3xl mx-auto mt-4">
            Beyond Bloom is a product of Odidor’s expertise in software
            development, UX/UI design, and AI-driven health solutions. We are
            committed to delivering top-tier innovation for holistic wellness.
          </p>
        </div>
      </section>
      <section className="py-16 px-6 md:px-16 bg-black text-white relative">
        <div className="max-w-4xl mx-auto text-center">
          <h3 className="text-4xl font-bold text-green-500 mb-6 animate-fade-in">
            Transforming Digital Experiences with{" "}
            <span className="text-white">Beyond Bloom</span>
          </h3>
          <p className="text-lg text-gray-300 mb-4 leading-relaxed">
            <span className="font-bold text-green-500 bg-green-900 px-2 py-1 rounded-lg">
              <Link
                to="https://beyond-bloom.odidor.co/"
                className="hover:underline"
              >
                Beyond Bloom
              </Link>
            </span>{" "}
            is an innovative platform designed to enhance digital engagement,
            offering seamless, intuitive, and high-performance solutions for
            users worldwide. With a focus on efficiency and accessibility,
            Beyond Bloom redefines how users interact with digital content.
          </p>
          <p className="text-lg text-gray-300 mb-4 leading-relaxed">
            Developed by{" "}
            <span className="font-bold text-green-500 bg-green-900 px-2 py-1 rounded-lg">
              Odidor
            </span>
            , Beyond Bloom is built with cutting-edge technology, ensuring{" "}
            <span className="text-green-400 font-semibold">
              top-tier security
            </span>
            , smooth navigation, and a dynamic user experience. Our mission is
            to create a{" "}
            <span className="text-green-400 font-semibold">
              future-ready digital ecosystem
            </span>{" "}
            that simplifies processes and maximizes user satisfaction.
          </p>
          <p className="text-lg text-gray-300 mb-4 leading-relaxed">
            From{" "}
            <span className="text-green-400 font-semibold">
              creative professionals
            </span>{" "}
            to{" "}
            <span className="text-green-400 font-semibold">
              business owners
            </span>
            , Beyond Bloom provides the tools and infrastructure needed to stay
            ahead in the digital landscape. Whether you're managing digital
            assets, collaborating on projects, or streamlining workflows, our
            platform is designed to deliver{" "}
            <span className="text-green-400 font-semibold">
              unparalleled performance
            </span>
            .
          </p>
          <p className="text-lg text-gray-300 leading-relaxed">
            Step into the future with{" "}
            <span className="text-green-500 font-bold">Beyond Bloom</span> –
            where{" "}
            <span className="text-green-400 font-semibold">
              innovation meets seamless execution
            </span>
            .
          </p>
        </div>

        {/* Conclusion Section */}
        <div className="mt-16 max-w-4xl mx-auto text-center border-t border-gray-700 pt-10">
          <h4 className="text-3xl font-semibold text-green-500">Conclusion</h4>
          <p className="text-lg text-gray-300 mt-4 leading-relaxed">
            <span className="text-green-400 font-semibold">Beyond Bloom</span>{" "}
            represents{" "}
            <span className="font-bold text-green-500">Odidor's</span>{" "}
            commitment to delivering state-of-the-art digital solutions. By
            prioritizing{" "}
            <span className="text-green-400 font-semibold">
              user experience
            </span>
            , <span className="text-green-400 font-semibold">security</span>,
            and{" "}
            <span className="text-green-400 font-semibold">performance</span>,
            we ensure that our platform stands out in the competitive digital
            landscape. As technology evolves, Beyond Bloom will continue to
            innovate, adapt, and provide seamless solutions for users worldwide.
          </p>
          <p className="text-lg mt-4 leading-relaxed font-bold text-green-400 animate-pulse">
            Ready to experience the next level of digital transformation?{" "}
            <span className="underline">Explore Beyond Bloom today!</span>
          </p>
        </div>
      </section>
      {/* Call-to-Action Section */}
      <section className="bg-gradient-to-br from-indigo-900 to-[#9013FE] text-white py-12 text-center">
        <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 leading-tight">
          Let’s Collaborate & Drive Success Together
        </h2>
        <p className="text-lg font-light mb-8">
          Ready to elevate your business with a cutting-edge e-commerce solution
          tailored to your needs?
        </p>
        <Link
          title="contact page"
          to="/contact"
          className="bg-gradient-to-r from-indigo-500 to-[#825bf0] hover:from-indigo-800 hover:to-indigo-700 px-8 py-4 rounded-lg text-xl font-semibold shadow-lg transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center justify-center"
        >
          <span className="mr-4">✉️</span> Contact Us Now
        </Link>
      </section>

      
      <Footer />
    </>
  );
};

export default Beyondbloom;
