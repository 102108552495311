"use client";
import React from "react";
import { Carousel, Card } from "../../src/components/ui/apple-cards-carousel";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";


export function AppleCardsCarouselServices() {
  return (
    <div className="w-full h-full py-14 bg-gray-950 text-center">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.8, ease: "easeInOut" }}
          className="text-4xl sm:text-7xl font-extrabold text-white drop-shadow-[0_0_15px_rgba(128,0,128,0.9)] tracking-wide animate-pulse"
        >
         Explore Our Services 
      </motion.h2>
      <div className="mt-10 flex justify-center">
        <Carousel
          items={data.map((card, index) => (
            <Link key={card.src} to={card.link} className="block">
              <Card card={card} index={index} />
            </Link>
          ))}
        />
      </div>
      <Link
        to="/services"
        className="inline-flex h-12 animate-shimmer mt-12 items-center justify-center rounded-md border border-slate-800 bg-[linear-gradient(110deg,#000103,45%,#1e2631,55%,#000103)] 
        bg-[length:200%_100%] px-6 font-medium text-slate-400 transition-all duration-300 focus:outline-none 
        focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 hover:border-slate-600 
        hover:text-white hover:shadow-[0_0_15px_rgba(30,38,49,0.8)]"
      >
        View All Services →
      </Link>
    </div>
  );
}

const data = [
  {
    category: "App Development",
    title: "Scalable and high-performing mobile applications.",
    src: "/assets/img/icon/category/app.png",
    link: "/services/app-development",
  },
  {
    category: "Web Development",
    title: "Responsive, dynamic, and visually appealing websites.",
    src: "/assets/img/icon/category/website.png",
    link: "/services/website-development",
  },
  {
    category: "Enterprise Solutions",
    title: "Innovative solutions to improve efficiency.",
    src: "/assets/img/icon/category/team.jpg",
    link: "/services/enterprise-solutions",
  },
  {
    category: "Software Development",
    title: "High-performance and scalable software solutions.",
    src: "/assets/img/icon/category/web-application.jpg",
    link: "/services/software-development",
  },
  {
    category: "Digital Marketing",
    title: "Boosting your online presence with smart strategies.",
    src: "/assets/img/icon/category/digital.jpg",
    link: "/services/digital-marketing",
  },
];

