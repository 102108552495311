import React from "react";
import { motion } from "framer-motion";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";

const Bigbrandsblinds = () => {
  const images = [
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1738478373/2-1_jxdmjk.png",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1738478374/2-2_dy1sdh.png",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1738478373/2-3_tgj3qc.png",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1738478372/2-4_ptgzjh.png",
    "https://res.cloudinary.com/doefzwlxm/image/upload/v1738478371/2-5_zitono.png",
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <div>
      <Header />
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-[60vh] sm:h-[70vh] flex items-center justify-center"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738478247/hero_bg_2_1_pbdocv.png')",
        }}
      >
        {/* Gradient Overlay */}

        <div className="text-center px-4">
          <h1 className="text-5xl sm:text-6xl font-extrabold mb-4 leading-tight text-orange-500">
            Big Brands Blinds
          </h1>
          <h2 className="text-lg sm:text-xl font-medium mb-6 text-blue-900">
            Premium Blinds for Every Style and Home.
          </h2>
        </div>

        {/* Expertise Section */}
        <div className="absolute bottom-10 left-0 right-0 text-center text-white px-4">
          <p className="text-sm sm:text-base font-medium">
            Expertise:{" "}
            <Link
              to="/services/website-development"
              className="font-semibold text-indigo-600 underline hover:text-indigo-400 transition"
            >
              <span>Web Development</span>
            </Link>
            {" , "}
            <Link
              to="/services/digital-marketing"
              className="font-semibold text-indigo-600 underline hover:text-indigo-400 transition"
            >
              <span>Digital Marketing</span>
            </Link>
            {" & "}
            <Link
              to="/services/digital-marketing/seo-services"
              className="font-semibold text-indigo-600 underline hover:text-indigo-400 transition"
            >
              <span>SEO Optimization</span>
            </Link>
          </p>
        </div>
      </div>

      {/* Project Overview */}
      <div className="flex flex-col md:flex-row gap-12 p-8 bg-gray-50 lg:px-28">
        {/* Services Provided Section */}

        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h3 className="text-2xl font-bold text-gray-800 mb-6">
            Services Provided
          </h3>
          <div className="flex flex-wrap gap-4">
            {[
              { name: "UI/UX Design", link: "/services/website-development" },
              {
                name: "Frontend Development",
                link: "/services/website-development/web-app-development",
              },
              {
                name: "E-commerce",
                link: "/services/website-development/ecommerce-web-development",
              },
              {
                name: "Web App Development",
                link: "/services/website-development/web-app-development",
              },
              {
                name: "SEO Optimization",
                link: "/services/digital-marketing/seo-services",
              },
              {
                name: "Social Media Marketing",
                link: "/services/digital-marketing/social-media-marketing",
              },
              {
                name: "Content Creation",
                link: "/services/digital-marketing/content-marketing",
              },
              { name: "Email Marketing", link: "/services/digital-marketing" },
              {
                name: "Analytics & Insights",
                link: "/services/digital-marketing",
              },
            ].map((service, index) => (
              <motion.a
                key={index}
                href={service.link}
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "#0274BD",
                }}
                whileTap={{ scale: 0.95 }}
                className="px-5 py-3 bg-blue-500 border border-gray-300 rounded-full text-sm font-medium cursor-pointer shadow-md hover:shadow-lg transition-all text-white"
                style={{
                  transition: "color 0.3s, background-color 0.3s",
                }}
              >
                {service.name}
              </motion.a>
            ))}
          </div>
        </motion.div>

        {/* Description Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1"
        >
          <h2 className="text-3xl font-extrabold text-gray-800 leading-tight">
            Building the Frontend for the E-commerce Platform - Odidor for Big
            Brands Blinds
          </h2>
          <p className="mt-6 text-gray-600 text-lg">
            <span className="font-extrabold  text-blue-500">
              {" "}
              <Link
                to="/"
                className="font-extrabold text-blue-500 hover:underline"
              >
                Odidor
              </Link>
            </span>{" "}
            is a dedicated frontend solution designed exclusively for
            <span className="font-extrabold text-orange-500">
              {" "}
              <a
                href="https://bigbrandsblinds.ca/"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit Big Brands Blinds"
                className="font-extrabold text-orange-500 hover:underline"
              >
                Big Brands Blinds
              </a>
            </span>
            , a leading provider of window coverings and blinds in Canada. The
            goal is to create an intuitive and responsive frontend that enhances
            the online shopping experience for customers. The platform focuses
            on product showcase, seamless navigation, product customization, and
            smooth checkout processes.
          </p>

          {/* Details Table */}
          <div className="mt-8 bg-white rounded-lg shadow-lg p-6 border-l-4 border-blue-500">
            <table className="w-full text-left text-gray-700">
              <tbody>
                {[
                  { label: "Frontend Technology", value: "React / Bootstrap" },
                  { label: "Timescale", value: "20 Weeks" },
                  { label: "Launch Date", value: "July 2024" },
                ].map((item, index) => (
                  <tr key={index} className="border-b last:border-none">
                    <td className="py-3 font-medium">{item.label}</td>
                    <td className="py-3 text-gray-500">{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-6">
              <a
                href="https://bigbrandsblinds.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-blue-500 text-white px-5 py-3 rounded hover:bg-blue-600 transition duration-300">
                  Visit Site
                </button>
              </a>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Gallery Section */}
      <div className="bg-gray-50 py-10 px-5">
        <div className="max-w-7xl mx-auto">
          <motion.div
            className="grid gap-4"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            {/* Top Row: 2 Images */}
            <div className="grid grid-cols-2 gap-4">
              {images.slice(0, 2).map((image, index) => (
                <motion.div
                  key={index}
                  className="overflow-hidden rounded-lg shadow-lg"
                  variants={itemVariants}
                >
                  <img
                    src={image}
                    alt={`Blind ${index + 1}`}
                    className="w-full h-auto object-cover"
                  />
                </motion.div>
              ))}
            </div>

            {/* Second Row: 3 Images */}
            <div className="grid grid-cols-3 gap-2 sm:gap-4">
              {images.slice(2).map((image, index) => (
                <motion.div
                  key={index + 2}
                  className="overflow-hidden rounded-lg shadow-lg"
                  variants={itemVariants}
                >
                  <img
                    src={image}
                    alt={`Blind ${index + 3}`}
                    className="w-full h-auto object-cover"
                  />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
      {/* bigbrand featers */}
      <section className="container mx-auto py-16 px-8 lg:px-24 bg-gradient-to-b from-gray-50 to-gray-100">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
        >
          <motion.img
            src="https://res.cloudinary.com/dhhsupcai/image/upload/v1737611066/Masterclass_Online_Course_Laptop_Instagram_Post_mzohzk.jpg"
            alt="Big Brands Blinds"
            className="rounded-xl shadow-lg transform hover:scale-105 transition duration-500 ease-in-out"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 1 }}
          />

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 text-gray-800 leading-tight">
              Crafting Bespoke Window Solutions for{" "}
              <span className="font-bold">Big Brands</span>
            </h2>
            <p className="text-gray-700 text-lg mb-8 leading-relaxed">
              At
              <span className="font-extrabold text-orange-500">
                {" "}
                <a
                  href="https://bigbrandsblinds.ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Visit Big Brands Blinds"
                  className="font-extrabold text-orange-500 hover:underline"
                >
                  Big Brands Blinds
                </a>
              </span>
              , we specialize in creating tailored window treatment solutions
              that meet the unique needs of brands looking to elevate their
              spaces. Our expert craftsmanship and innovative approach ensure
              each window is transformed into a masterpiece, blending
              functionality with style.
            </p>

            <ul className="space-y-4 text-lg text-gray-700 mb-10">
              <motion.li
                whileHover={{ scale: 1.05, x: 10 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-blue-500">✔️</span>
                Custom Window Solutions to Reflect Your Brand's Identity
              </motion.li>
              <motion.li
                whileHover={{ scale: 1.05, x: 10 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-blue-500">✔️</span>
                High-Quality Materials for Long-Lasting Performance
              </motion.li>
              <motion.li
                whileHover={{ scale: 1.05, x: 10 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-blue-500">✔️</span>
                Expertise in SEO and Online Branding for Visibility
              </motion.li>
              <motion.li
                whileHover={{ scale: 1.05, x: 10 }}
                transition={{ duration: 0.3 }}
                className="flex items-center"
              >
                <span className="mr-3 text-blue-500">✔️</span>
                Tailored Solutions to Fit Every Commercial or Residential Space
              </motion.li>
            </ul>

            <a
              href="https://bigbrandsblinds.ca/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gradient-to-r from-blue-600 to-blue-500 text-white py-3 px-8 rounded-lg shadow-md hover:bg-blue-700 transform hover:scale-105 transition duration-300"
            >
              Visit Site
            </a>
          </motion.div>
        </motion.div>

        <div className="mt-20 grid grid-cols-1 lg:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="bg-gradient-to-r from-indigo-50 to-indigo-100 p-8 rounded-xl shadow-lg"
          >
            <h3 className="text-3xl font-bold mb-4 text-indigo-700">
              Our Vision: Innovation Meets Quality
            </h3>
            <p className="text-gray-700 text-lg">
              At{" "}
              <span className="font-extrabold text-orange-500">
              <a
                  href="https://bigbrandsblinds.ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Visit Big Brands Blinds"
                  className="font-extrabold text-orange-500 hover:underline"
                >
                  Big Brands Blinds
                </a>{" "}
              </span>
              , we aim to set new standards for window treatments by combining
              timeless design with cutting-edge technology. We envision a future
              where every window space tells a story—one of style,
              sophistication, and high-performance solutions.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            className="bg-gradient-to-r from-teal-50 to-teal-100 p-8 rounded-xl shadow-lg"
          >
            <h3 className="text-3xl font-bold mb-4 text-teal-700">
              Why Choose Us? Expertise You Can Trust
            </h3>
            <p className="text-gray-700 text-lg">
              With decades of experience and a deep understanding of the
              industry, we bring unparalleled expertise to every project. Our
              commitment to excellence ensures your space receives the highest
              level of craftsmanship, backed by exceptional customer service.
            </p>
          </motion.div>
        </div>

        <div className="mt-20">
          <h3 className="text-3xl font-bold mb-6 text-gray-800">
            Advanced Technology Stack & Key Highlights
          </h3>
          <ul className="space-y-6 text-lg text-gray-700">
            <motion.li
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="flex items-center"
            >
              <span className="mr-3 text-green-500">✔️</span>
              Utilizing the latest React libraries for dynamic and responsive
              interfaces
            </motion.li>

            <motion.li
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="flex items-center"
            >
              <span className="mr-3 text-green-500">✔️</span>
              Developed using modern web technologies ensuring top-notch
              performance and stability
            </motion.li>

            <motion.li
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="flex items-center"
            >
              <span className="mr-3 text-green-500">✔️</span>
              Fully optimized for SEO, speed, and scalability
            </motion.li>
            <motion.li
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="flex items-center"
            >
              <span className="mr-3 text-green-500">✔️</span>
              Comprehensive development cycle with a timeline of 12 weeks
            </motion.li>
            <motion.li
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="flex items-center"
            >
              <span className="mr-3 text-green-500">✔️</span>
              Official Launch Date: June 2024
            </motion.li>
          </ul>
        </div>
      </section>

      {/* GOAL SECTION - Big Brands Blinds & Odidor */}
      <section className="bg-gradient-to-r from-green-100 to-green-200 py-16 px-6 md:px-16">
        <h3 className="text-3xl md:text-4xl font-bold text-center text-green-800 mb-8">
          Our Goal: Revolutionizing the Blinds Industry
        </h3>
        <div className="max-w-5xl mx-auto text-lg text-gray-700 space-y-6">
          <p>
            The goal of{" "}
            <span className="font-extrabold text-green-900">
              <a
                href="https://bigbrandsblinds.ca/"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit Big Brands Blinds"
                className="font-extrabold text-orange-500 hover:underline"
              >
                Big Brands Blinds
              </a>
            </span>{" "}
            is to transform the way customers experience window coverings—moving
            beyond traditional showrooms and offering a seamless online shopping
            journey.
          </p>
          <p>
            Partnering with{" "}
            <span className="font-extrabold text-green-900">
              {" "}
              <Link
                to="/"
                className="font-extrabold text-green-900 hover:underline"
              >
                Odidor
              </Link>
            </span>
            , our mission is to create a cutting-edge digital platform that not
            only showcases our high-quality products but also empowers customers
            to easily find, compare, and select blinds that perfectly suit their
            needs.
          </p>
          <p>
            Through a combination of innovative technology and expert
            craftsmanship, we aim to provide an unparalleled shopping
            experience—one that simplifies the decision-making process and
            ensures complete customer satisfaction.
          </p>
          <p>
            Together,{" "}
            <span className="font-extrabold text-green-900">
              <a
                href="https://bigbrandsblinds.ca/"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit Big Brands Blinds"
                className="font-extrabold text-orange-500 hover:underline"
              >
                Big Brands Blinds
              </a>
            </span>{" "}
            and <span className="font-extrabold text-green-900">Odidor</span>{" "}
            are setting a new standard in the blinds industry, making it easier
            than ever for people to access premium window treatments from the
            comfort of their homes.
          </p>
        </div>
      </section>

      {/* image  */}
      <div
        className="relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-blue-600 to-blue-900 text-white"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738476780/installation_vm6omu.jpg')",
        }}
      ></div>

      {/* SEAMLESS EXPERIENCE SECTION */}
      <section className="bg-gradient-to-r from-indigo-100 to-indigo-200 py-16 px-6 md:px-16">
        <h3 className="text-3xl md:text-4xl font-bold text-center text-indigo-800 mb-8">
          Seamless Experience with{" "}
          <span className="font-extrabold text-indigo-900">
            {" "}
            <Link
              to="/"
              className="font-extrabold text-indigo-900 hover:underline"
            >
              Odidor
            </Link>
          </span>{" "}
          &{" "}
          <span className="font-extrabold text-indigo-900">
            <a
              href="https://bigbrandsblinds.ca/"
              target="_blank"
              rel="noopener noreferrer"
              title="Visit Big Brands Blinds"
              className="font-extrabold text-orange-500 hover:underline"
            >
              Big Brands Blinds
            </a>
          </span>
        </h3>
        <div className="max-w-5xl mx-auto text-lg text-gray-700 space-y-6">
          <p>
            At{" "}
            <span className="font-extrabold text-indigo-900">
              {" "}
              <Link
                to="/"
                className="font-extrabold text-indigo-900 hover:underline"
              >
                Odidor
              </Link>
            </span>
            , we focus on delivering a seamless digital experience by
            integrating cutting-edge technology with intuitive design. Working
            closely with{" "}
            <span className="font-extrabold text-indigo-900">
              <a
                href="https://bigbrandsblinds.ca/"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit Big Brands Blinds"
                className="font-extrabold text-orange-500 hover:underline"
              >
                Big Brands Blinds
              </a>
            </span>
            , we have created a platform that not only showcases their extensive
            product range but also streamlines the user journey from selection
            to purchase.
          </p>
          <p>
            This collaborative effort ensures that every customer enjoys a
            smooth and hassle-free experience, thanks to user-friendly
            navigation, detailed product information, and interactive features
            that help guide them through their shopping journey.
          </p>
          <p>
            With{" "}
            <span className="font-extrabold text-indigo-900">
              {" "}
              <Link
                to="/"
                className="font-extrabold text-indigo-900 hover:underline"
              >
                Odidor
              </Link>
            </span>
            's expertise in software development and{" "}
            <span className="font-extrabold text-indigo-900">
              <a
                href="https://bigbrandsblinds.ca/"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit Big Brands Blinds"
                className="font-extrabold text-orange-500 hover:underline"
              >
                Big Brands Blinds
              </a>
            </span>
            's commitment to quality, we deliver an experience that is both
            engaging and convenient, empowering customers to make confident
            decisions with ease.
          </p>
        </div>
      </section>

      {/* image  */}
      <div
        className="relative h-[70vh] flex items-center justify-center bg-cover bg-center bg-gradient-to-r from-blue-600 to-blue-900 text-white"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/doefzwlxm/image/upload/v1738478246/about_shape1-1_ocvzrl.jpg')",
        }}
      ></div>
      {/* Odidor Project - Conclusion Section */}
      <section className="bg-gradient-to-r from-indigo-100 to-indigo-200 py-16 px-6 md:px-16">
        <h3 className="text-3xl md:text-4xl font-bold text-center text-indigo-800 mb-8">
          Conclusion: Elevating Your Experience with{" "}
          <span className="font-extrabold text-indigo-900">Odidor</span> &amp;{" "}
          <span className="font-extrabold text-indigo-900">
            <a
              href="https://bigbrandsblinds.ca/"
              target="_blank"
              rel="noopener noreferrer"
              title="Visit Big Brands Blinds"
              className="font-extrabold text-orange-500 hover:underline"
            >
              Big Brands Blinds
            </a>
          </span>
        </h3>
        <div className="max-w-5xl mx-auto text-lg text-gray-700 space-y-6">
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            At{" "}
            <span className="font-extrabold text-indigo-900">
              {" "}
              <Link
                to="/"
                className="font-extrabold text-indigo-900 hover:underline"
              >
                Odidor
              </Link>
            </span>
            , we specialize in crafting seamless digital experiences, and
            through our collaboration with{" "}
            <span className="font-extrabold text-indigo-900">
            <a
                href="https://bigbrandsblinds.ca/"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit Big Brands Blinds"
                className="font-extrabold text-orange-500 hover:underline"
              >
                Big Brands Blinds
              </a>
            </span>
            , we’ve built a platform that elevates their craftsmanship and
            simplifies the customer journey.
          </motion.p>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            This partnership has enabled a user-friendly platform that makes it
            effortless for customers to select personalized window treatments
            that reflect their unique style and needs.
          </motion.p>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <span className="font-extrabold text-indigo-900">
              <a
                href="https://bigbrandsblinds.ca/"
                target="_blank"
                rel="noopener noreferrer"
                title="Visit Big Brands Blinds"
                className="font-extrabold text-orange-500 hover:underline"
              >
                Big Brands Blinds
              </a>
            </span>{" "}
            has seamlessly integrated our cutting-edge software solutions to
            create a site that perfectly captures their brand vision,
            highlighting their commitment to quality, innovation, and
            excellence.
          </motion.p>
          <motion.p
            className="mb-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            Together, we strive to deliver solutions that exceed customer
            expectations, blending state-of-the-art technology with unparalleled
            craftsmanship.
          </motion.p>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="bg-gradient-to-br from-indigo-900 to-[#9013FE] text-white py-12 text-center">
        <h2 className="text-4xl lg:text-5xl font-extrabold mb-6 leading-tight">
          Let’s Collaborate & Drive Success Together
        </h2>
        <p className="text-lg font-light mb-8">
          Ready to elevate your business with a cutting-edge e-commerce solution
          tailored to your needs?
        </p>
        <Link
          to="/contact"
          title="contact page"
          className="bg-gradient-to-r from-indigo-500 to-[#825bf0] hover:from-indigo-800 hover:to-indigo-700 px-8 py-4 rounded-lg text-xl font-semibold shadow-lg transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center justify-center"
        >
          <span className="mr-4">✉️</span> Contact Us Now
        </Link>
      </section>

      <Footer />
    </div>
  );
};

export default Bigbrandsblinds;
