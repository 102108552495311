import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Helmet } from "react-helmet";
const Uiuxdesigning = () => {
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://odidor.co/services/digital-marketing/content-marketing"
        />

        <title>Content Marketing Services | Odidor</title>
        <meta
          name="description"
          content="Boost your brand’s online presence with Odidor's expert Content Marketing services. We create high-quality, engaging content that attracts, converts, and retains your audience, driving organic growth and brand trust."
        />
        <meta
          name="keywords"
          content="Content Marketing, Content Strategy, Brand Engagement, Organic Traffic, SEO Content, Digital Marketing Surrey, Odidor Content Services, Social Media Content, Online Branding"
        />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Content Marketing Services | Odidor"
        />
        <meta
          property="og:description"
          content="Boost your brand’s online presence with Odidor's expert Content Marketing services. We create high-quality, engaging content that attracts, converts, and retains your audience, driving organic growth and brand trust."
        />
        <meta
          property="og:url"
          content="https://odidor.co/services/digital-marketing/content-marketing"
        />
        <meta
          property="og:image"
          content="https://odidor.co/assets/img/services/digitalmarketing/content.gif"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />
        <meta property="og:site_name" content="Odidor" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Content Marketing Services | Odidor"
        />
        <meta
          name="twitter:description"
          content="Boost your brand’s online presence with Odidor's expert Content Marketing services. We create high-quality, engaging content that attracts, converts, and retains your audience, driving organic growth and brand trust."
        />
        <meta
          name="twitter:image"
          content="https://odidor.co/assets/img/services/digitalmarketing/content.gif"
        />
      </Helmet>

      <main>
        <Header />
        <div className="bg-shape">
          <img
            src="/../assets/img/shape/shape-1.png"
            alt="UI/UX Design Services"
          />
        </div>

        <section className="page__title-area pt-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__title-content mb-50">
                  <h1 className="page__title">UI/UX Design Services</h1>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services">Services</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/services/ui-ux-design">UI/UX Solutions</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        UI/UX Design Services
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="design__area pb-50">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12">
                <div className="design__wrapper">
                  <div className="postbox__details mb-30">
                    <p className="drop-cap">
                      Exceptional UI/UX design is at the core of digital
                      success. At Odidor, we craft visually appealing and highly
                      interactive digital experiences that drive user engagement
                      and business growth. Our design philosophy revolves around
                      user-centric methodologies, ensuring seamless usability,
                      accessibility, and innovation.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 design__details-thumb w-img mb-30">
                <img
                  src="/../assets/img/services/devWeb/uiux-design.gif"
                  alt="UI/UX Design"
                  className="img-fluid"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 postbox__details">
                <h4 className="mb-3">
                  Transforming Digital Experiences with UI/UX
                </h4>
                <p>
                  Our UI/UX design process ensures seamless digital
                  interactions, prioritizing user satisfaction and business
                  goals. By leveraging the latest design methodologies, we
                  create visually stunning and highly functional interfaces.
                </p>
                <p>
                  <b>Our Approach Includes:</b>
                </p>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    ✅ <b>User Research & Persona Development:</b> Understanding
                    user behavior to tailor experiences.
                  </li>
                  <li className="mb-2">
                    ✅ <b>Wireframing & Interactive Prototyping:</b> Structuring
                    intuitive workflows for clarity.
                  </li>
                  <li className="mb-2">
                    ✅ <b>Visual Design & Branding:</b> Crafting compelling
                    designs that align with your brand.
                  </li>
                  <li className="mb-2">
                    ✅ <b>Usability Testing & Iterative Improvements:</b>{" "}
                    Refining experiences based on real feedback.
                  </li>
                  <li className="mb-2">
                    ✅ <b>Responsive & Accessible Design:</b> Ensuring
                    consistency across devices and inclusivity for all users.
                  </li>
                  <li className="mb-2">
                    ✅ <b>Microinteractions & Animations:</b> Enhancing user
                    engagement with smooth interactions.
                  </li>
                </ul>
                <p>
                  Whether you're launching a new product or improving an
                  existing one, our UI/UX design services help you build
                  intuitive, user-friendly, and aesthetically pleasing digital
                  experiences.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="services__area pb-110">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="section__title-wrapper mb-50 text-center">
                  <h2 className="section__title">Our UI/UX Expertise</h2>
                  <p>
                    We design seamless digital experiences tailored to user
                    needs, ensuring aesthetic appeal and usability for maximum
                    engagement.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay=".3s"
                >
                  <div className="services__icon mb-45">
                    <i className="fas fa-paint-brush fa-4x"></i>
                  </div>
                  <div className="services__content">
                    <h3 className="services__title">Web & App UI Design</h3>
                    <p>
                      Creating pixel-perfect, responsive, and engaging UI
                      designs.
                    </p>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay=".5s"
                >
                  <div className="services__icon mb-45">
                    <i className="fas fa-mobile-alt fa-4x"></i>
                  </div>
                  <div className="services__content">
                    <h3 className="services__title">Mobile UX Optimization</h3>
                    <p>
                      Ensuring seamless experiences across all devices and
                      platforms.
                    </p>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 d-flex">
                <div
                  className="services__item white-bg mb-30 wow fadeinup animated"
                  data-wow-delay=".7s"
                >
                  <div className="services__icon mb-45">
                    <i className="fas fa-user-check fa-4x"></i>
                  </div>
                  <div className="services__content">
                    <h3 className="services__title">Usability Testing</h3>
                    <p>
                      Refining designs based on data-driven insights for an
                      optimal UX.
                    </p>
                    <a href="/contact" className="link-btn">
                      <i className="arrow_right"></i>Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="section__title-wrapper mt-50 mb-50 text-center">
                  <h4 className="h3">
                    Key Factors for UI/UX Success in Custom CMS Development:
                  </h4>
                  <p className="mt-3">
                    A well-designed CMS should focus on usability, scalability,
                    and security to enhance user engagement and efficiency. Here
                    are the key UI/UX principles to consider:
                  </p>
                  <div className="d-flex flex-column align-items-center mt-4">
                    <ul className="list-unstyled text-start">
                      <li className="mb-3">
                        <span className="fw-bold text-primary">
                          🔹 Content Flexibility:
                        </span>
                        Easy management of diverse content types.
                      </li>
                      <li className="mb-3">
                        <span className="fw-bold text-primary">
                          🔹 SEO Optimization:
                        </span>
                        Built-in tools to enhance search visibility.
                      </li>
                      <li className="mb-3">
                        <span className="fw-bold text-primary">
                          🔹 Enhanced Security:
                        </span>
                        Protection against data breaches and cyber threats.
                      </li>
                      <li className="mb-3">
                        <span className="fw-bold text-primary">
                          🔹 Customizable UI:
                        </span>
                        Adaptability to match your brand identity.
                      </li>
                      <li className="mb-3">
                        <span className="fw-bold text-primary">
                          🔹 Scalable Architecture:
                        </span>
                        Future-proof design for business growth.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default Uiuxdesigning;
