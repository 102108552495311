import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

const Header = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <header>
                <div class="header__area white-bg" id="header-sticky">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-4 col-6">
                                <div class="logo">
                                    <Link to="/">
                                        <img src="/../assets/img/logo/logo.png" alt="logo" />
                                    </Link>
                                </div>
                            </div>
                            <div class="col-xxl-8 col-xl-8 col-lg-8 d-none d-lg-block">
                                <div class="main-menu">
                                    <nav id="mobile-menu">
                                        <ul>
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li class="has-dropdown">
                                                <Link to="/services">Services</Link>
                                                <ul class="submenu">
                                                    <li> <Link to="/services/app-development">App Development</Link></li>
                                                    <li> <Link to="/services/website-development">Web Development</Link></li>
                                                    <li> <Link to="/services/software-development">Software Dev</Link></li>
                                                    <li> <Link to="/services/digital-marketing">Digital Marketing</Link></li>
                                                    <li> <Link to="/services/enterprise-solutions">Enterprise Solutions</Link></li>
                                                </ul>
                                            </li>
                                            <li> <Link to="/apps">Apps</Link></li>
                                            <li> <Link to="/industries">Industries</Link></li>
                                            <li> <Link to="/projects">Projects</Link></li>
                                            <li> <Link to="/news-and-updates">News & Updates</Link></li>
                                            <li> <Link to="/contact">Contact</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-8 col-6">
                                <div class="header__action d-flex align-items-center justify-content-end">
                                    <div class="header__login d-none d-sm-block">
                                        <Link to="/Login"><i class="far fa-unlock"></i> Log In</Link>
                                    </div>
                                    <button onClick={() => setShow(!show)}>

                                        <div class="sidebar__menu d-lg-none" >
                                            <div class="sidebar-toggle-btn" >
                                                <span class="line"></span>
                                                <span class="line"></span>
                                                <span class="line"></span>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {
                show ? (
                    <>
                        <div class="sidebar__area sidebar-opened">
                            <div class="sidebar__wrapper">
                                <div class="sidebar__close">
                                    <button class="sidebar__close-btn" id="sidebar__close-btn" onClick={() => setShow(false)}>
                                        <span><i class="fal fa-times"></i></span>
                                        <span>close</span>
                                    </button>
                                </div>
                                <div class="sidebar__content">
                                    <div class="logo mb-40">
                                        <Link to="/">
                                            <img src="../assets/img/logo/logo-white.png" alt="logo" />
                                        </Link>
                                    </div>
                                    <div class="mobile-menu mean-container">
                                        <div class="mean-bar"><a href="#nav" class="meanmenu-reveal"
                                            style={{ Right: " 0px", left: "auto", display: "inline", }}><span><span><span></span></span></span></a>
                                            <nav class="mean-nav">
                                                <ul style={{ display: "none" }}>
                                                    <li class="has-dropdown active">
                                                        <Link to="/">Home</Link>
                                                    </li>
                                                    <li class="has-dropdown">
                                                        <Link to="/services">Services</Link>
                                                        <Link to="/services/app-development" style={{ borderTop: 0, paddingTop: '0px' }}>&emsp;- App Development</Link>
                                                        <Link to="/services/website-development" style={{ borderTop: 0, paddingTop: '0px' }}>&emsp;- Web Development</Link>
                                                        <Link to="/services/software-development" style={{ borderTop: 0, paddingTop: '0px' }}>&emsp;- Software Development</Link>
                                                        <Link to="/services/digital-marketing" style={{ borderTop: 0, paddingTop: '0px' }}>&emsp;- Digital Marketing</Link>
                                                        <Link to="/services/enterprise-solutions" style={{ borderTop: 0, paddingTop: '0px' }}>&emsp;- Enterprise Solutions</Link>
                                                    </li>
                                                    <li><Link to="/apps">Apps</Link></li>
                                                    <li> <Link to="/industries">Industries</Link></li>
                                                    <li> <Link to="/projects">Projects</Link></li>
                                                    <li><Link to="/news-and-updates">New & Updates</Link></li>
                                                    <li><Link to="/contact">Contact</Link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="sidebar__action mt-330">
                                        <div class="sidebar__login mt-15">
                                            <Link to="/Login"><i class="fa-solid fa-lock" /> Log In</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null
            }


        </>
    )
}

export default Header